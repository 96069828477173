import {reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {Vehicle, VehicleCatalogue} from '../api/digitalCertificateApi';
import {getYearOfManufacture} from './getYearOfManufacture';

type CertificateHeaderParams = {
  vehicle: Vehicle | Nullish;
  vehicleCatalogue: VehicleCatalogue | Nullish;
};

export function getCertificateHeader(props: CertificateHeaderParams) {
  if (!props.vehicle || !props.vehicleCatalogue?.length) {
    return null;
  }

  const vehicleCatalogue = props.vehicleCatalogue[0];

  const make = vehicleCatalogue.default_label;
  const model = vehicleCatalogue.models?.find(
    (m) => m.model === props.vehicle?.modelFamily
  )?.default_label;
  const yearOfManufacture = getYearOfManufacture(props.vehicle?.manufacturedOn);

  return {
    makeModel: reject(isNilOrEmpty)([make, model, yearOfManufacture]).join(' '),
    specification: props.vehicle?.variant,
  };
}
