import {
  Box,
  Center,
  getValueByDevice,
  HStack,
  Icon,
  Integer,
  Show,
  Text,
  ThemeColorPath,
  ThemeColorTextPath,
  ThemeIconKey,
  useDevice,
  ValueByDevice,
} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

type SectionStateFlagSeverity = 'damage' | 'good';

export interface SectionStateFlagProps extends TestIdProps {
  severity: SectionStateFlagSeverity;
  text?: string | Nullish;
}

export function SectionStateFlag(props: SectionStateFlagProps) {
  const device = useDevice();
  const hasText = isNotNilOrEmpty(props.text) && device !== 'mobile';

  const backgroundColor = match<SectionStateFlagSeverity, ThemeColorPath>(props.severity)
    .with('damage', always('severity.warningBase'))
    .with('good', always('severity.successBase'))
    .exhaustive();

  const iconColor = match<SectionStateFlagSeverity, ThemeColorPath>(props.severity)
    .with('damage', always('accent.darkest'))
    .with('good', always('fill.white'))
    .exhaustive();

  const textColor = match<SectionStateFlagSeverity, ThemeColorTextPath>(props.severity)
    .with('damage', always('primary'))
    .with('good', always('inverted'))
    .exhaustive();

  const {icon, iconSize} = match<
    SectionStateFlagSeverity,
    {icon: ThemeIconKey; iconSize: Integer | ValueByDevice<Integer>}
  >(props.severity)
    .with('damage', always({icon: 'damageSection', iconSize: [5, 6, 6, 6]}))
    .with('good', always({icon: 'good', iconSize: 6}))
    .exhaustive();

  return (
    <Box
      paddingHorizontal={getValueByDevice(device, 0, 4)}
      width={hasText ? 'auto' : getValueByDevice(device, 6, 10)}
      borderRadius={getValueByDevice(device, 'border_radius_full', 'large')}
      backgroundColor={backgroundColor}
      data-testid={suffixTestId('sectionStateFlag', props)}
    >
      <Center height={getValueByDevice(device, 6, 10)}>
        <HStack align="center" spacing={2}>
          <Icon value={icon} size={iconSize} color={iconColor} />
          <Show when={hasText}>
            <Text size="small" color={textColor}>
              {props.text}
            </Text>
          </Show>
        </HStack>
      </Center>
    </Box>
  );
}
