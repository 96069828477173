import {Nullish} from 'shared';

import {AlphaCatalogue, VehicleAudit} from '../../../api/digitalCertificateApi';
import {getAdditionalRelatedActionsByUniqueKeys} from '../../../utils/getAdditionalRelatedActionsByUniqueKeys';
import {getAlphaCatalogueLabel} from '../../../utils/getAlphaCatalogueLabel';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';

export function getSpecification(
  vehicleAudit: VehicleAudit | Nullish,
  alphaCatalogue: AlphaCatalogue | Nullish
) {
  if (!vehicleAudit?.vehicle || !alphaCatalogue) {
    return null;
  }

  const emmisionClass = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'emission_class',
    key: vehicleAudit.vehicle.emissionClass,
    alphaCatalogue,
  });

  const bodywork = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'car_style',
    key: vehicleAudit.vehicle.bodyStyle,
    alphaCatalogue,
  });

  const fuel = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'fuel_type',
    key: vehicleAudit.vehicle.fuelType,
    alphaCatalogue,
  });

  const transmission = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'transmission',
    key: vehicleAudit.vehicle.transmission,
    alphaCatalogue,
  });

  const differential = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'drive',
    key: vehicleAudit.vehicle.drive,
    alphaCatalogue,
  });

  const additionalRelatedActions = getAdditionalRelatedActionsByUniqueKeys({
    parentUniqueKey: 'OVERALL_COMMENT',
    categories: vehicleAudit.auditStructure.categories,
  });

  return {
    vin: vehicleAudit.vehicle.vin,
    yearOfManufacture: vehicleAudit.vehicle.manufacturedOn,
    mileage: vehicleAudit.vehicle.mileage,
    numberOfSeat: vehicleAudit.vehicle.seatCount,
    power: vehicleAudit.vehicle.power,
    co2Emissions: vehicleAudit.vehicle.carbonDioxideEmission,
    differential,
    euroClass: emmisionClass,
    fuel,
    transmission,
    bodywork,
    comment: getAuditFieldValue({
      key: 'specificationComment',
      relatedActions: additionalRelatedActions,
      auditCategories: vehicleAudit.audit.categories,
    }),
  };
}
