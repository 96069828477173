import {flatten} from 'ramda';

import {Nullish} from 'shared';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {composeAssetsByRelatedActions} from './composeAssetsByRelatedActions';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';
import {mapAssetsToSlideImages} from './mapAssetsToSlideImages';

type PhotosByKeysParams = {
  parentUniqueKey: string;
  vehicleAudit: VehicleAudit | Nullish;
  uniqueKey?: string | string[] | Nullish;
};

export function getPhotosByKeys(params: PhotosByKeysParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.auditStructure.categories) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: params.parentUniqueKey,
    uniqueKey: params.uniqueKey,
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length) {
    return null;
  }

  const assets = composeAssetsByRelatedActions(
    relatedActions,
    params.vehicleAudit.audit.categories
  );

  if (!assets?.length) {
    return null;
  }

  return mapAssetsToSlideImages(flatten(assets.map((asset) => asset.files)));
}
