import {Nullish} from 'shared';

import {AuditCategory} from '../api/digitalCertificateApi';

export function getAuditFieldById(id: string | Nullish, auditCategories: AuditCategory[]) {
  const category = auditCategories.find((c) => c.type === 'field' && c.paramDefinitionId === id);
  if (category?.type !== 'field') {
    return null;
  }

  return category;
}
