import {HStack, Text, ThemeIconKey, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {Avatar} from '../../../components/Avatar/Avatar';

interface SpecificationItemProps extends TestIdProps {
  value: string | number;
  category: string;
  icon: ThemeIconKey;
}

export function SpecificationItem(props: SpecificationItemProps) {
  const valueIsNotEmptyString = props.value !== '';
  return (
    <HStack spacing={4} align="center">
      <Avatar data-testid={suffixTestId('icon', props)} icon={props.icon} />
      <VStack spacing={props.category && valueIsNotEmptyString ? 1 : 0}>
        <Text
          data-testid={suffixTestId('value', props)}
          size="large"
          color="primary"
          noWrap={false}
        >
          {props.value}
        </Text>
        <Text data-testid={suffixTestId('category', props)} size="xSmall" color="secondary">
          {props.category}
        </Text>
      </VStack>
    </HStack>
  );
}
