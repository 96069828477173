import {RefObject} from 'react';

export const useChosenDamage = (refToScrollElement: RefObject<HTMLDivElement>) => {
  const handleClickToPoint = () => {
    const elementRect = refToScrollElement?.current?.getBoundingClientRect();

    if (elementRect) {
      const elementTopPlusMenuBarHeight = window.scrollY + elementRect.top - 100;
      window.scrollTo({
        top: elementTopPlusMenuBarHeight,
        behavior: 'smooth',
      });
    }
  };
  return {handleScrollAfterClickToPoint: handleClickToPoint};
};
