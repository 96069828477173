import {isEmpty, isNil, isNotNil, reject} from 'ramda';
import {isFalse, isTrue} from 'ramda-adjunct';

import {Damage, DamageValueTypes} from '../../../types/Damage';

export function getDamageCarouselDataFromMap(
  data: (Partial<Record<DamageValueTypes, Damage[]> & {point?: string}> | null)[]
) {
  if (!data || isEmpty(data)) {
    return null;
  }
  const objectValues = reject(isNil)(Object.values(data));

  const values = objectValues.flatMap((item) =>
    (item.values || [])?.map((damage) => ({...damage, point: item.point}))
  );

  const comments = objectValues.flatMap((item) => item.comments);
  const photos = objectValues.flatMap((item) => item.photos);

  const location = photos.filter((photo) => isNotNil(photo) && isTrue(photo.isLocation));
  const slides = photos.filter((photo) => isNotNil(photo) && isFalse(photo.isLocation));

  return values.map((item) => ({
    title: item?.title ?? null,
    value: values.find((damage) => damage?.damageId === item?.damageId)?.value ?? null,
    comment: comments.find((damage) => damage?.damageId === item?.damageId)?.value ?? null,
    location: location.find((damage) => damage?.damageId === item?.damageId)?.slides?.[0] ?? null,
    slides: reject(isNil)(
      slides.filter((damage) => damage?.damageId === item?.damageId)?.flatMap((i) => i?.slides)
    ),
    point: String(item.point),
    id: item.damageId,
  }));
}
