import {Box, Clickable, Icon, useDevice} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import {useTheme} from 'styled-components';

import {useBoolean} from 'shared';

import {AppLanguageDialogItems} from '../../../consts/languageDialogItems';
import {queryParams} from '../../../consts/queryParams';
import i18n from '../../../i18n/i18n';
import {useQueryState} from '../../../utils/useQueryState';
import {LanguageDialog} from '../../LanguageDialog/LanguageDialog';
import {LanguagesDialogModal} from './LanguagesDialogModal';

type LanguageDialogComponentProps = {
  languages: AppLanguageDialogItems;
};
export const LanguageComponent = ({languages}: LanguageDialogComponentProps) => {
  const theme = useTheme();
  const [lang, setLanguage] = useQueryState(queryParams.LNG);
  const device = useDevice();
  const [isLanguageDialogOpen, onLanguageDialogOpen, onLanguageDialogClose] = useBoolean(false);
  const onChangeLanguage = (lang: AppLanguage) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        setLanguage(lang);
        window.location.reload();
      })
      .catch(() => {
        throw new Error('fatal error - failed to set the application language');
      });
  };

  return (
    <>
      <Clickable onClick={onLanguageDialogOpen}>
        <Icon size={5} value={languages[i18n?.resolvedLanguage as AppLanguage].icon} />
      </Clickable>

      {device === 'mobile' ? (
        <LanguageDialog
          onClose={onLanguageDialogClose}
          isVisible={isLanguageDialogOpen}
          title={i18n.t('chooseLanguage')}
        />
      ) : isLanguageDialogOpen ? (
        <Box top={0} left={0} position="fixed" zIndex="LANGUAGE_DIALOG">
          <div
            style={{
              width: '100vw',
              height: '100vh',
              position: 'absolute',
              backgroundColor: theme.colors.fill.overlayMedium,
            }}
            onClick={onLanguageDialogClose}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <LanguagesDialogModal
                title={i18n.t('chooseLanguage')}
                languages={languages}
                isVisible={true}
                onChoseLanguage={onChangeLanguage}
                onClose={onLanguageDialogClose}
              />
            </div>
          </div>
        </Box>
      ) : null}
    </>
  );
};
