import {Box, Heading, HStack, Icon, Text, ThemeIconKey, VStack} from 'platform/foundation';

import {times} from 'ramda';

import {isDefined} from 'shared';

import {i18n} from '../../../i18n/i18n';

export interface RatingCardProps {
  rating?: number | null;
  icon: ThemeIconKey;
  title: string;
}
const mapRatingToStar: Record<number, number> = {
  1: 5,
  2: 4,
  3: 3,
  4: 2,
  5: 1,
};
export function RatingCard(props: RatingCardProps) {
  const rating = props.rating;

  const stars = isDefined(rating) ? (
    times(
      (index) => (
        <Icon
          key={index}
          value="iconStar"
          color={index < mapRatingToStar[rating] ? 'severity.warningBase' : 'severity.neutralBase'}
        />
      ),
      5
    )
  ) : (
    <Text color="tertiary">{i18n.t('unrated')}</Text>
  );

  return (
    <Box
      width="100%"
      backgroundColor="fill.greyLight"
      padding={[3, 6, 8, 8]}
      borderRadius={['small', 'small', 'large', 'large']}
    >
      <HStack spacing={[4, 4, 6, 6]} align="center">
        <Icon value={props.icon} size={[6, 8, 10, 10]} color="accent.base" />
        <VStack spacing={[2, 2, 4, 4]}>
          <Heading size={3}>{props.title}</Heading>
          <HStack spacing={2}>{stars}</HStack>
        </VStack>
      </HStack>
    </Box>
  );
}
