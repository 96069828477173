import {Image, Integer, ValueByDevice} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {advantageCarTheme} from '../theme/advantageCarTheme';
import {bmwTheme} from '../theme/bmwTheme';
import {emilFreyTheme} from '../theme/emilFreyTheme';
import {fordTheme} from '../theme/fordTheme';
import {digitalCertificateTheme} from '../theme/theme';
import {getWorkspaceFromUri} from './getWorkspaceFromUri';

export type Logo = {
  src: string;
  height: ValueByDevice<Integer>;
};
export const emilFreyLogo: Logo = {
  src: '../../assets/images/emil-frey-logo.svg',
  height: [13, 15, 20, 20],
};
export const advantageCarLogo: Logo = {
  src: '../../assets/images/advantage-cars-logo.svg',
  height: [13, 15, 20, 20],
};
export const carvagoLogo: Logo = {
  src: '../../assets/images/carvago-logo.svg',
  height: [5, 6, 7, 7],
};
export const schneiderLogo: Logo = {
  src: '../../assets/images/Schneider_logo.svg',
  height: [13, 15, 20, 20],
};
export const digitalCertificate: Logo = {
  src: '../../assets/images/logo.svg',
  height: [5, 6, 7, 7],
};
export const bmwLogo: Logo = {
  src: '../../assets/images/bmw-logo.svg',
  height: [13, 15, 20, 20],
};
export const fordLogo: Logo = {
  src: '../../assets/images/ford-logo.png',
  height: [13, 15, 20, 20],
};

const {workspace} = getWorkspaceFromUri();

export function getWorkspaceCustomization() {
  const resultCustomization = match<string | undefined>(workspace)
    .with(
      Pattern.union('advantagecars', 'advantage-cars-caraudit'),
      always({
        logo: advantageCarLogo,
        isDigitalCertificateLogoShown: true,
        theme: advantageCarTheme,
      })
    )
    .with(
      'emil-frey',
      always({logo: emilFreyLogo, isDigitalCertificateLogoShown: true, theme: emilFreyTheme})
    )

    .with(
      'carvago',
      always({
        logo: carvagoLogo,
        isDigitalCertificateLogoShown: true,
        theme: digitalCertificateTheme,
      })
    )
    .with(
      'demo-de',
      always({
        logo: schneiderLogo,
        isDigitalCertificateLogoShown: true,
        theme: digitalCertificateTheme,
      })
    )
    .with(
      Pattern.union('bmw-bonkowscy', 'demo-bmw-bonkowscy'),
      always({
        logo: bmwLogo,
        isDigitalCertificateLogoShown: true,
        theme: bmwTheme,
      })
    )

    .with(
      Pattern.union(
        'autoboss',
        'automotive',
        'carrara',
        'chwalinski',
        'ciesiolka',
        'citycar',
        'demo-autoboss',
        'demo-automotive',
        'demo-carrara',
        'demo-chwalinski',
        'demo-ciesiolka',
        'demo-citycar',
        'demo-ford-pl',
        'demo-frankcars',
        'demo-germaz',
        'demo-globcarsservice',
        'demo-mhmotors',
        'demo-mirai',
        'demo-rencar',
        'demo-rewo',
        'demo-szumilas',
        'demo-wikar',
        'frankcars',
        'germaz',
        'globcarsservice',
        'mhmotors',
        'mirai',
        'rencar',
        'rewo',
        'szumilas',
        'wikar'
      ),
      always({
        logo: fordLogo,
        isDigitalCertificateLogoShown: true,
        theme: fordTheme,
      })
    )
    .otherwise(
      always({
        logo: digitalCertificate,
        isDigitalCertificateLogoShown: false,
        theme: digitalCertificateTheme,
      })
    );

  const logoImage = (
    <Image
      isLazy
      width="auto"
      height={resultCustomization.logo.height}
      src={resultCustomization.logo.src}
      alt={workspace}
      data-testid="logoImage"
    />
  );

  return {...resultCustomization, logo: logoImage};
}
