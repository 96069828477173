import {concat, isNil, reject} from 'ramda';

import {Nullish} from 'shared';

import {VehicleAudit} from '../../../api/digitalCertificateApi';
import {getPhotosByKeys} from '../../../utils/getPhotosByKeys';
import {getFilteredPhotosByKeys} from './getFilteredPhotosByKeys';

export function getExteriorPhotos(vehicleAudit: VehicleAudit | Nullish) {
  if (!vehicleAudit || !vehicleAudit.auditStructure.categories) {
    return null;
  }

  const exteriorDamagePhotos = getPhotosByKeys({
    vehicleAudit,
    parentUniqueKey: 'EXTERIOR',
  });

  const exteriorPhotos = getFilteredPhotosByKeys(vehicleAudit, exteriorKeys);

  return reject(isNil)(concat(exteriorPhotos ?? [], exteriorDamagePhotos ?? []));
}

const exteriorKeys = [
  'leftFrontCornerPhoto',
  'frontPhoto',
  'windshieldPhoto',
  'frontLightPhoto',
  'rightFrontCornerPhoto',
  'rightSidePhoto',
  'rightBackCornerPhoto',
  'backPhoto',
  'backLightPhoto',
  'leftBackCornerPhoto',
  'leftSidePhoto',
  'roofPhoto',
  'wheelPhoto',
  'enginePhoto',
  'vinTicketPhoto',
];
