import {Box, Integer, useResponsivePropValue, ValueByDevice} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

import {ListFeaturesItem, ListFeaturesItemProps} from './components/ListFeaturesItem';
import {getIfIsIndexWithPadding} from './utils/getIfIsIndexWithPadding';

export interface ListFeaturesProps extends TestIdProps {
  features: ListFeaturesItemProps[];
  spacing?: Integer | ValueByDevice<Integer>;
  columns?: number | ValueByDevice<number>;
}

export function ListFeatures(props: ListFeaturesProps) {
  const theme = useTheme();
  const columns = useResponsivePropValue(props.columns);
  const spacing = useResponsivePropValue(props.spacing);

  const features = props.features.filter((feature) => !feature.hidden);

  return (
    <div
      css={css`
        columns: ${columns ?? 1};
        column-gap: ${theme.getSize(spacing ?? 0)};
      `}
    >
      {features.map((feature, index) => (
        <Box
          key={`${feature.title}-${feature.variant}`}
          paddingBottom={getIfIsIndexWithPadding(features, index, columns)}
        >
          <ListFeaturesItem
            data-testid={suffixTestId(`listFeatures-[${index}]`, props)}
            {...feature}
          />
        </Box>
      ))}
    </div>
  );
}
