import {Grid, Show, VStack} from 'platform/foundation';
import {formatNumber, useLocale} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isFalsy, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {Comment} from '../../../components/Comment/CommentExpandable';
import {CommentExpandable} from '../../../components/CommentExpandable/CommentExpandable';
import {Separator} from '../../../components/Separator/Separator';
import i18n from '../../../i18n/i18n';
import {getYearOfManufacture} from '../../../utils/getYearOfManufacture';
import {SpecificationItem} from './SpecificationItem';

interface SpecificationContentProps extends TestIdProps {
  vin?: string | Nullish;
  yearOfManufacture?: string | Nullish;
  mileage?: number | Nullish;
  bodywork?: string | Nullish;
  differential?: string | Nullish;
  transmission?: string | Nullish;
  fuel?: string | Nullish;
  numberOfSeat?: number | Nullish;
  euroClass?: string | Nullish;
  co2Emissions?: string | Nullish;
  power?: number | Nullish;
  comment?: string | Nullish;
  isPrintView?: boolean;
}

export function SpecificationContentPrint(props: SpecificationContentProps) {
  const locale = useLocale();

  const mileage = isNotNil(props?.mileage)
    ? `${formatNumber(locale.localeConfig, props.mileage, 0)} ${i18n.t('metricKm')}`
    : null;
  const power = isNotNil(props?.power) ? `${props.power} ${i18n.t('metricKw')}` : null;
  const co2Emissions = isNotNil(props.co2Emissions)
    ? `${props.co2Emissions} ${i18n.t('metricGkm')}`
    : null;

  return (
    <VStack spacing={[4, 6, 6, 8]}>
      <Grid columns={3} spacing={10}>
        <SpecificationItem
          data-testid={suffixTestId('vin', props)}
          icon="vin"
          value={props.vin ?? EMPTY_PLACEHOLDER}
          category={i18n.t('vin')}
        />
        <SpecificationItem
          data-testid={suffixTestId('yearOfManufacture', props)}
          icon="action/date_range"
          value={getYearOfManufacture(props.yearOfManufacture) ?? EMPTY_PLACEHOLDER}
          category={i18n.t('yearOfManufacture')}
        />
        <SpecificationItem
          data-testid={suffixTestId('mileage', props)}
          icon="mileage"
          value={mileage ?? EMPTY_PLACEHOLDER}
          category={i18n.t('mileage')}
        />
        <SpecificationItem
          data-testid={suffixTestId('bodywork', props)}
          icon="car"
          value={props.bodywork ?? EMPTY_PLACEHOLDER}
          category={i18n.t('bodywork')}
        />
        <SpecificationItem
          data-testid={suffixTestId('differential', props)}
          icon="differential"
          value={props.differential ?? EMPTY_PLACEHOLDER}
          category={i18n.t('differential')}
        />
        <SpecificationItem
          data-testid={suffixTestId('transmission', props)}
          icon="transmission"
          value={props.transmission ?? EMPTY_PLACEHOLDER}
          category={i18n.t('transmission')}
        />
        <SpecificationItem
          data-testid={suffixTestId('fuel', props)}
          icon="fuelType"
          value={props.fuel ?? EMPTY_PLACEHOLDER}
          category={i18n.t('fuel')}
        />
        <SpecificationItem
          data-testid={suffixTestId('numberOfSeat', props)}
          icon="seat"
          value={props.numberOfSeat ?? EMPTY_PLACEHOLDER}
          category={i18n.t('numberOfSeat')}
        />
        <SpecificationItem
          data-testid={suffixTestId('euroClass', props)}
          icon="euroClass"
          value={props.euroClass ?? EMPTY_PLACEHOLDER}
          category={i18n.t('euroClass')}
        />
        <SpecificationItem
          data-testid={suffixTestId('co2Emissions', props)}
          icon="co2"
          value={co2Emissions ?? EMPTY_PLACEHOLDER}
          category={i18n.t('co2Emissions')}
        />
        <SpecificationItem
          data-testid={suffixTestId('power', props)}
          icon="engine"
          value={power ?? EMPTY_PLACEHOLDER}
          category={i18n.t('power')}
        />
      </Grid>

      <Show when={isFalsy(props.isPrintView) && isNotNilOrEmpty(props.comment)}>
        <Separator />
        <CommentExpandable comment={props.comment} />
      </Show>
      <Show when={isTrue(props.isPrintView) && isNotNilOrEmpty(props.comment)}>
        <Comment comment={props.comment} />
      </Show>
    </VStack>
  );
}
