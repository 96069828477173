import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {isNotNilOrZero, Nullish} from 'shared';

import {Integer} from '../types/Integer';
import {getPixelsFromRem} from './getPixelsFromRem';

export function makeImageUrl(src: string | Nullish, height?: Integer, width?: Integer) {
  return match([isNotNilOrEmpty(src), isNotNilOrZero(height), isNotNilOrZero(width)])
    .with([true, false, false], always(src))
    .with([true, true, false], always(`${src}&height=${getPixelsFromRem(height)}`))
    .with([true, false, true], always(`${src}&width=${getPixelsFromRem(width)}`))
    .with(
      [true, true, true],
      always(
        `${src}&height=${getPixelsFromRem(height)?.toString()}&width=${getPixelsFromRem(width)}`
      )
    )
    .otherwise(always(null));
}
