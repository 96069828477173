import {LocaleContextValue} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {
  ListFeaturesItemProps,
  ListFeaturesItemVariant,
} from '../components/ListFeatures/components/ListFeaturesItem';
import {SectionIdsType} from '../consts/Sections';
import {getFeatures} from '../utils/getFeatures';

export type FeaturesDataBundle = {
  features: ListFeaturesItemProps[] | null | undefined;
  featuresSlideshow: ReturnType<typeof getFeatures> | null | undefined;
  featuresData: ReturnType<typeof getFeatures> | null;
  sectionConfig: {
    name: SectionIdsType;
    isHidden: boolean;
  };
};
const OVERENI_VYBAVY = 'featuresCheckV2';
const DALSI_VYBAVA = 'nextFeatures';
const VYBAVA = 'featuresCheckSectionV2';
const KOMFORT_A_FUNKCNOSST = '["FEATURE_AC"]';

const HIDDEN_FEATURE_IDS = [OVERENI_VYBAVY, DALSI_VYBAVA, VYBAVA];

export const useGetDataForFeatures = (
  vehicleAudit: VehicleAudit | null | undefined,
  locale: LocaleContextValue
): FeaturesDataBundle => {
  const featuresData = getFeatures({vehicleAudit, language: locale.language});
  const features = featuresData?.reduce((filteredFeatures: ListFeaturesItemProps[], feature) => {
    const hiddenTittle = feature.id && HIDDEN_FEATURE_IDS.includes(feature.id);
    const hiddenTittleByValue = feature.field?.value === KOMFORT_A_FUNKCNOSST;

    if (isNotNil(feature.title) && !hiddenTittle && !hiddenTittleByValue) {
      filteredFeatures.push({
        title: feature.title,
        description: feature.comment,
        variant: match<[string | Nullish, string | Nullish], ListFeaturesItemVariant>([
          feature.field?.value,
          feature.field?.statusValue,
        ])
          .with(['AVAILABLE', 'FUNCTIONAL'], always('ready'))
          .with(['AVAILABLE', 'NON_FUNCTIONAL'], always('damaged'))
          .with(['AVAILABLE', 'NON_TESTED'], always('neutral'))
          .otherwise(always('neutral')),
        flagText: match<[string | Nullish, string | Nullish], string | Nullish>([
          feature.field?.value,
          feature.field?.statusValue,
        ])
          .with(['AVAILABLE', 'NON_FUNCTIONAL'], always(feature.field?.statusLabel))
          .with(['AVAILABLE', 'NON_TESTED'], always(feature.field?.statusLabel))
          .otherwise(always(null)),
      });
    }
    return filteredFeatures;
  }, []);

  const featuresSlideshow = featuresData?.filter((feature) => isNotNilOrEmpty(feature.slides));

  const shouldBeFeaturesHidden = !features?.some((item) => item.variant !== 'neutral');

  return {
    features,
    featuresSlideshow,
    featuresData,
    sectionConfig: {
      isHidden: shouldBeFeaturesHidden,
      name: 'EQUIPMENT',
    },
  };
};
