import {Clickable, Heading, HStack, Icon, Show, ThemeIconKey} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

export interface LanguageItemProps extends TestIdProps {
  onClick: VoidFunction;
  title: string;
  icon?: ThemeIconKey;
  isItemSelected?: boolean;
}

export function LanguageItem(props: LanguageItemProps) {
  const theme = useTheme();
  return (
    <Clickable onClick={props.onClick}>
      <div
        data-testid={suffixTestId('navigationItem', props)}
        css={css`
          border: 1px solid transparent;

          &:hover {
            border-color: ${theme.colors.severity.neutralLight};
          }

          background-color: ${props.isItemSelected ? theme.colors.fill.greyLight : 'transparent'};
          border-radius: 8px;
          padding: 16px;
        `}
      >
        <HStack spacing={3} align="center">
          <Show when={props.icon}>
            <Icon size={5} value={props.icon} />
          </Show>
          <Heading size={3}>{props.title}</Heading>
        </HStack>
      </div>
    </Clickable>
  );
}
