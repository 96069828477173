import {Box, Heading, HStack, Icon, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, parseDate, TestIdProps} from 'shared';

import {i18n} from '../../i18n/i18n';
import {Avatar} from '../Avatar/Avatar';
import {Separator} from '../Separator/Separator';

export type Mechanic = {
  name: string | Nullish;
  dateOfInspection?: string | Nullish;
  description?: string | Nullish;
};

interface CardMechanicProps extends Mechanic, TestIdProps {}

export function CardMechanic(props: CardMechanicProps) {
  const formatDateTime = useDateTimeFormatter();

  return (
    <Box
      backgroundColor="fill.greyLight"
      borderRadius={['small', 'medium', 'large', 'large']}
      padding={[3, 6, 10, 10]}
    >
      <Show when={isNotNilOrEmpty(props.name)}>
        <HStack spacing={4} align="center" justify="flex-start">
          <Box flex={0}>
            <Avatar name={props.name!} />
          </Box>
          <VStack spacing={1}>
            <Heading size={3} color="primary">
              {props.name}
            </Heading>
            <Text size="small" color="secondary">
              {props.description ?? i18n.t('mechanic')}
            </Text>
          </VStack>
        </HStack>
        <Separator spacing={4} />
      </Show>
      <Show when={isNotNilOrEmpty(props.dateOfInspection) && props.dateOfInspection !== 'null'}>
        <HStack spacing={4} align="center" justify="flex-start">
          <Box flex={0} paddingHorizontal={2}>
            <Icon value="action/date_timer" size={6} color="accent.base" />
          </Box>
          <VStack spacing={1}>
            <Text color="primary" size="small">
              {formatDateTime('dateTimeMedium', parseDate(props.dateOfInspection!))}
            </Text>
            <Text size="xSmall" color="secondary">
              {i18n.t('dateOfInspection')}
            </Text>
          </VStack>
        </HStack>
      </Show>
    </Box>
  );
}
