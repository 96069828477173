import {VehicleAudit} from '../api/digitalCertificateApi';
import {SectionIdsType} from '../consts/Sections';
import {SlideImage} from '../types/SlideImage';
import {getPhotosByKeys} from '../utils/getPhotosByKeys';

export type PhotoDocumentationDataBundle = {
  photoDocumentationPhotos: SlideImage[] | null;
  sectionConfig: {
    name: SectionIdsType;
    isHidden: boolean;
  };
};
export const useGetDataForPhotoDocumentation = (
  vehicleAudit: VehicleAudit | null | undefined
): PhotoDocumentationDataBundle => {
  const photoDocumentationPhotos = getPhotosByKeys({
    vehicleAudit,
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });

  const shouldBeFeaturesHidden = !photoDocumentationPhotos;

  return {
    photoDocumentationPhotos,
    sectionConfig: {
      isHidden: shouldBeFeaturesHidden,
      name: 'PHOTODOCUMENTATION',
    },
  };
};
