import {AppLanguage} from 'platform/locale';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {ComparisonKeyOf} from '../consts/comparisonKeys';
import {DataUtilParams} from '../types/DataUtilParams';
import {getAdditionalRelatedActionsByUniqueKeys} from './getAdditionalRelatedActionsByUniqueKeys';
import {getAuditFieldLabel} from './getAuditFieldLabel';
import {getAuditFieldValue} from './getAuditFieldValue';
import {getAuditFieldValueWithUnit} from './getAuditFieldValueWithUnit';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';

export function getServiceHistory(params: DataUtilParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.audit || !params.vehicleAudit.auditStructure) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: 'SERVICE_HISTORY',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  const additionalActions = getAdditionalRelatedActionsByUniqueKeys({
    parentUniqueKey: 'SERVICE_HISTORY',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length) {
    return null;
  }

  return {
    inspections: {
      comment: getAuditFieldValue({
        key: 'serviceHistoryDateOfLastServiceComment',
        relatedActions: additionalActions,
        auditCategories: params.vehicleAudit.audit.categories,
      }),
      dateOfLastService: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryDateOfLastService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValue({
          key: 'serviceHistoryDateOfLastService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      mileageOfLastService: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryMileageOfLastService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValueWithUnit({
          key: 'serviceHistoryMileageOfLastService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      dateOfNextService: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryDateOfNextService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValue({
          key: 'serviceHistoryDateOfNextService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      mileageOfNextService: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryMileageOfNextService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValueWithUnit({
          key: 'serviceHistoryMileageOfNextService',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      regularServiceIntervalPerformed: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryRegularServiceIntervalPerformed',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldLabel({
          key: 'serviceHistoryRegularServiceIntervalPerformed',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
      },
    },
    lastOilChange: {
      comment: getAuditFieldValue({
        key: 'serviceHistoryDateOfLastOilChangeComment',
        relatedActions: additionalActions,
        auditCategories: params.vehicleAudit.audit.categories,
      }),
      dateOfLastOilChange: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryDateOfLastOilChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValue({
          key: 'serviceHistoryDateOfLastOilChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      amountOfKmDrivenFromOilChange: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryAmountOfKmDrivenFromOilChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValueWithUnit({
          key: 'serviceHistoryAmountOfKmDrivenFromOilChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    lastBrakeFluidChange: {
      comment: getAuditFieldValue({
        key: 'serviceHistoryDateOfLastBrakeFluidChangeComment',
        relatedActions: additionalActions,
        auditCategories: params.vehicleAudit.audit.categories,
      }),
      dateOfLastBrakeFluidChange: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryDateOfLastBrakeFluidChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValue({
          key: 'serviceHistoryDateOfLastBrakeFluidChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      amountOfKmDrivenFromBrakeFluidChange: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryAmountOfKmDrivenFromBrakeFluidChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValueWithUnit({
          key: 'serviceHistoryAmountOfKmDrivenFromBrakeFluidChange',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    lastOilChangeInGearbox: {
      comment: getAuditFieldValue({
        key: 'serviceHistoryDateOfLastOilChangeInGearboxComment',
        relatedActions: additionalActions,
        auditCategories: params.vehicleAudit.audit.categories,
      }),
      dateOfLastOilChangeInGearbox: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryDateOfLastOilChangeInGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValue({
          key: 'serviceHistoryDateOfLastOilChangeInGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      amountOfKmDrivenFromOilChangeInGearbox: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryAmountOfKmDrivenFromOilChangeInGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValueWithUnit({
          key: 'serviceHistoryAmountOfKmDrivenFromOilChangeInGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    lastOilChangeInDifferential: {
      comment: getAuditFieldValue({
        key: 'serviceHistoryDateOfLastOilChangeInDifferentialComment',
        relatedActions: additionalActions,
        auditCategories: params.vehicleAudit.audit.categories,
      }),
      dateOfLastOilChangeInDifferential: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryDateOfLastOilChangeInDifferential',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValue({
          key: 'serviceHistoryDateOfLastOilChangeInDifferential',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      amountOfKmDrivenFromOilChangeInDifferential: {
        label: getServiceHistoryAuditFieldLabel({
          key: 'serviceHistoryAmountOfKmDrivenFromOilChangeInDifferential',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        value: getAuditFieldValueWithUnit({
          key: 'serviceHistoryAmountOfKmDrivenFromOilChangeInDifferential',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
  };
}

interface AuditFieldNameParams {
  key: ComparisonKeyOf;
  language: AppLanguage;
  relatedActions: RelatedAction[];
  auditCategories: AuditCategory[];
}

function getServiceHistoryAuditFieldLabel(params: AuditFieldNameParams): string | null {
  const action = getRelatedActionByComparisonKey(params.key, params.relatedActions);
  return action?.label?.[params.language] ?? null;
}
