import {
  Box,
  getValueByDevice,
  Heading,
  Hide,
  Image,
  Show,
  Stack,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import {css, DefaultTheme, useTheme} from 'styled-components';

import {useEffect, useMemo, useRef} from 'react';

import {__, all, filter, gt, gte, keys, length, pipe, toPairs, values} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isPositive, isTrue, toNumber} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

import ExteriorBackOverlay from '../../assets/images/exterior-back-overlay.svg';
import ExteriorFrontOverlay from '../../assets/images/exterior-front-overlay.svg';
import PaintJob from '../../assets/images/paintjob.svg';
import {Attributes} from '../../components/Attributes/Attributes';
import {BannerSuccess} from '../../components/BannerSuccess/BannerSuccess';
import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {DamageCarousel} from '../../components/DamageCarousel/DamageCarousel';
import {getDamageCarouselDataFromMap} from '../../components/DamageCarousel/utils/getDamageCarouselDataFromMap';
import {Section} from '../../components/Section/Section';
import {Separator} from '../../components/Separator/Separator';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {ValueWithUnit} from '../../types/ValueWithUnit';
import {getAuditAssignee} from '../../utils/getAuditAssignee';
import {getDamageValues} from '../../utils/getDamageValues';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';
import {getValueWithUnit} from '../../utils/getValueWithUnit';
import {DamageModalWrapperComponent} from './components/DamageModalWrapperComponent';
import {useGetDamageModalConfiguration} from './hooks/useGetDamageModalConfiguration';
import {useGetDamageStatesAndRefs} from './hooks/useGetDamageStatesAndRefs';
import {DamageDataExterior} from './types';
import {addClickListenerToDamagePoints} from './utils/addClickListenerToDamagePoints';
import {findAndSetModalPosition} from './utils/findAndSetModalPosition';
import {getExterior} from './utils/getExterior';
import {getSeverityAndTextForPaintThickness} from './utils/getSeverityAndTextForPaintThickness';
import {removeDamagePointListeners} from './utils/removeDamagePointListeners';

export function Exterior() {
  const device = useDevice();
  const theme = useTheme();
  const imagesRef = useRef<HTMLDivElement>(null);
  const backDamagesRef = useRef<HTMLDivElement>(null);
  const frontDamagesRef = useRef<HTMLDivElement>(null);
  const {
    isDamageModalShown,
    setIsDamageModalShown,
    damagesCarouselRef,
    paintRef,
    setChosenDamage,
    setModalPosition,
    modalPosition,
    chosenDamage,
  } = useGetDamageStatesAndRefs();
  const locale = useLocale();

  const {vehicleAudit} = useGetDigitalCertificateData();
  const assignee = getAuditAssignee(vehicleAudit);

  const damageValues = useMemo(
    () => ({
      leftFrontLight: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LF_LIGHT',
        point: 'leftFrontLight',
      }),

      rightFrontLight: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RF_LIGHT',
        point: 'rightFrontLight',
      }),

      frontBumper: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'FRONT_BUMPER',
        point: 'frontBumper',
      }),

      leftFrontFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LF_FENDER',
        point: 'leftFrontFender',
      }),

      rightFrontFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RF_FENDER',
        point: 'rightFrontFender',
      }),

      leftRearFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LR_FENDER',
        point: 'leftRearFender',
      }),

      rightRearFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RR_FENDER',
        point: 'rightRearFender',
      }),

      frontHood: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'FRONT_HOOD',
        point: 'frontHood',
      }),

      leftFrontDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LF_DOOR',
        point: 'leftFrontDoor',
      }),

      rightFrontDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RF_DOOR',
        point: 'rightFrontDoor',
      }),

      leftRearDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LR_DOOR',
        point: 'leftBackDoor',
      }),

      rightRearDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RR_DOOR',
        point: 'rightRearDoor',
      }),

      leftMirror: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'L_MIRROR',
        point: 'leftMirror',
      }),

      rightMirror: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'R_MIRROR',
        point: 'rightMirror',
      }),

      frontWindow: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'WINDSHIELD',
        point: 'frontWindow',
      }),

      roof: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'ROOF',
        point: 'roof',
      }),
      roofWindow: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'PANORAMATIC_ROOF/WINDOW',
        point: 'roofWindow',
      }),

      backDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'THE_SUITCASE_LID',
        point: 'backDoor',
      }),

      backBumper: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'REAR_BUMPER',
        point: 'backBumper',
      }),

      other: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'OTHER',
        point: 'other',
      }),
    }),
    [locale.language, vehicleAudit]
  );
  const carouselData = getDamageCarouselDataFromMap(Object.values(damageValues));

  const damageModalConfiguration = useGetDamageModalConfiguration(
    carouselData,
    damagesCarouselRef,
    'exterior',
    chosenDamage
  );

  const damagesData: DamageDataExterior = useMemo(
    () => ({
      leftFrontFender: damageValues.leftFrontFender?.values?.length ?? 0,
      backBumper: damageValues.backBumper?.values?.length ?? 0,
      rightFrontDoor: damageValues.rightFrontDoor?.values?.length ?? 0,
      rightFrontFender: damageValues.rightFrontFender?.values?.length ?? 0,
      frontWindow: damageValues.frontWindow?.values?.length ?? 0,
      frontHood: damageValues.frontHood?.values?.length ?? 0,
      frontBumper: damageValues.frontBumper?.values?.length ?? 0,
      rightFrontLight: damageValues.rightFrontLight?.values?.length ?? 0,
      rightMirror: damageValues.rightMirror?.values?.length ?? 0,
      leftMirror: damageValues.leftMirror?.values?.length ?? 0,
      leftFrontLight: damageValues.leftFrontLight?.values?.length ?? 0,
      roof:
        (damageValues.roof?.values?.length ?? 0) + (damageValues.roofWindow?.values?.length ?? 0) ??
        0,
      rightRearDoor: damageValues.rightRearDoor?.values?.length ?? 0,
      rightRearFender: damageValues.rightRearFender?.values?.length ?? 0,
      leftRearFender: damageValues.leftRearFender?.values?.length ?? 0,
      leftFrontDoor: damageValues.leftFrontDoor?.values?.length ?? 0,
      leftBackDoor: damageValues.leftRearDoor?.values?.length ?? 0,
      backDoor: damageValues.backDoor?.values?.length ?? 0,
      other: damageValues.other?.values?.length ?? 0,
    }),
    [damageValues]
  );

  const exterior = getExterior({vehicleAudit, language: locale.language});

  /**
   * TODO: Create useScrolledIntoView hook
   */
  useEffect(() => {
    function callback(entries: IntersectionObserverEntry[]) {
      if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0) {
        showDamagesOverlay(damagesData);
      }
    }

    const handleMouseOverPoint = (
      key: string,
      index: number,
      area: 'exterior-front' | 'exterior-back' | 'interior-front' | 'interior-back'
    ) => {
      setTimeout(() => setIsDamageModalShown(true), 10);

      setChosenDamage({key, index, area});
    };
    const handleClickPoint = () => {
      setTimeout(() => setIsDamageModalShown(true), 10);
      if (device !== 'mobile') {
        damageModalConfiguration?.selectedDamageData?.[0].onClick();
      }
    };
    const frontPointEvents = addClickListenerToDamagePoints(
      damagesData,
      handleMouseOverPoint,
      handleClickPoint,
      'exterior-front'
    );
    const backPointEvents = addClickListenerToDamagePoints(
      damagesData,
      handleMouseOverPoint,
      handleClickPoint,
      'exterior-back'
    );

    if (chosenDamage !== undefined) {
      findAndSetModalPosition(
        chosenDamage,
        setModalPosition,
        frontPointEvents,
        backPointEvents,
        'EXTERIOR'
      );
    }

    const observer = new IntersectionObserver(callback, {threshold: DAMAGES_IN_VIEW_TRESHOLD});
    imagesRef.current && observer.observe(imagesRef.current);

    const handleLeaveDamagesContent = () => {
      setIsDamageModalShown(false);
    };
    const frontDamagesElement = frontDamagesRef.current?.addEventListener(
      'mouseleave',
      handleLeaveDamagesContent
    );
    const backDamagesElement = backDamagesRef.current?.addEventListener(
      'mouseleave',
      handleLeaveDamagesContent
    );

    return () => {
      observer.disconnect();
      removeDamagePointListeners(frontPointEvents);
      removeDamagePointListeners(backPointEvents);
      frontDamagesElement &&
        frontDamagesRef.current?.removeEventListener('mouseleave', handleLeaveDamagesContent);
      backDamagesElement &&
        backDamagesRef.current?.removeEventListener('mouseleave', handleLeaveDamagesContent);
    };
  }, [damagesData, chosenDamage]);

  useEffect(() => {
    function callback(entries: IntersectionObserverEntry[]) {
      if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0 && exterior?.paintData) {
        showPaintOverlay(exterior.paintData, theme);
      }
    }

    const observer = new IntersectionObserver(callback, {threshold: PAINT_IN_VIEW_TRESHOLD});
    paintRef.current && observer.observe(paintRef.current);

    return () => observer.disconnect();
  }, [exterior?.paintData, theme]);

  const isStatusOkay = all(isTrue, [
    isNilOrEmpty(damageValues.backBumper?.values),
    isNilOrEmpty(damageValues.backDoor?.values),
    isNilOrEmpty(damageValues.frontBumper?.values),
    isNilOrEmpty(damageValues.frontHood?.values),
    isNilOrEmpty(damageValues.frontWindow?.values),
    isNilOrEmpty(damageValues.leftFrontDoor?.values),
    isNilOrEmpty(damageValues.leftFrontFender?.values),
    isNilOrEmpty(damageValues.leftFrontLight?.values),
    isNilOrEmpty(damageValues.leftMirror?.values),
    isNilOrEmpty(damageValues.leftRearDoor?.values),
    isNilOrEmpty(damageValues.leftRearFender?.values),
    isNilOrEmpty(damageValues.rightFrontDoor?.values),
    isNilOrEmpty(damageValues.rightFrontFender?.values),
    isNilOrEmpty(damageValues.rightFrontLight?.values),
    isNilOrEmpty(damageValues.rightMirror?.values),
    isNilOrEmpty(damageValues.rightRearDoor?.values),
    isNilOrEmpty(damageValues.rightRearFender?.values),
    isNilOrEmpty(damageValues.roof?.values),
    isNilOrEmpty(damageValues.other?.values),
  ]);

  const countDamages: (object: object) => number = pipe(values, filter(gt(__, 0)), length);

  if (!exterior) {
    return null;
  }

  return (
    <section id="EXTERIOR">
      <div style={{position: 'relative'}} id="EXTERIOR">
        {isDamageModalShown && damageModalConfiguration && (
          <DamageModalWrapperComponent
            listFeatures={damageModalConfiguration.selectedDamageData || []}
            title={damageModalConfiguration.selectedDamageData?.[0]?.sectionTitle || ''}
            onMouseOver={() => {
              setIsDamageModalShown(true);
            }}
            top={modalPosition.top + damageModalConfiguration.damageModalPosition.x}
            left={modalPosition.left + damageModalConfiguration.damageModalPosition.y}
            onMouseLeave={() => {
              setIsDamageModalShown(false);
            }}
          />
        )}
        <Section
          id="EXTERIOR"
          icon="exterior"
          heading={i18n.t('exteriorHeader')}
          header={
            assignee?.name && isNotNilOrEmpty(exterior?.comment) ? (
              <CommentMechanic name={assignee.name} comment={exterior?.comment} />
            ) : null
          }
          content={
            <VStack spacing={[3, 6, 6, 10]}>
              <Hide when={isTrue(isStatusOkay)}>
                <Stack direction={['column', 'row', 'row', 'row']} spacing={[3, 6, 6, 10]}>
                  <Box position="relative" flex={1} ref={imagesRef}>
                    <Image
                      borderRadius="small"
                      src="../../assets/images/exterior-front.jpg"
                      width="100%"
                      height="auto"
                      isLazy
                      hasSpinner
                      data-testid="exterior-front-image"
                    />

                    <Box
                      ref={frontDamagesRef}
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                    >
                      <ExteriorFrontOverlay />
                    </Box>
                  </Box>

                  <Box position="relative" flex={1}>
                    <Image
                      borderRadius="small"
                      src="../../assets/images/exterior-back.jpg"
                      width="100%"
                      height="auto"
                      isLazy
                      hasSpinner
                      data-testid="exterior-back-image"
                    />
                    <Box
                      ref={backDamagesRef}
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                    >
                      <ExteriorBackOverlay />
                    </Box>
                  </Box>
                </Stack>
                <div ref={damagesCarouselRef}>
                  <Show when={isNotNilOrEmpty(carouselData)}>
                    <Separator />

                    <DamageCarousel
                      data={carouselData}
                      chosenDamageIndex={
                        damageModalConfiguration?.chosenImageDamageIndex !== undefined
                          ? damageModalConfiguration?.chosenImageDamageIndex
                          : 0
                      }
                    />
                  </Show>
                </div>
              </Hide>
              <Show when={isTrue(isStatusOkay)}>
                <BannerSuccess />
              </Show>
              <Separator />
              <Stack direction={['column', 'column', 'column', 'row']} spacing={[3, 6, 6, 10]}>
                <Box flex={1} maxWidth={['100%', '100%', '100%', 100]} width="100%">
                  <VStack spacing={[3, 6, 6, 10]}>
                    <Heading size={2}>{i18n.t('paintThickness')}</Heading>
                    <Text color="tertiary">{i18n.t('paintThicknessDescription')}</Text>
                  </VStack>
                </Box>
                <div
                  css={css`
                    flex: 1;
                    display: ${getValueByDevice(device, 'none', 'block', 'block', 'block')};
                  `}
                >
                  <Box flex={1} ref={paintRef}>
                    <PaintJob />
                  </Box>
                </div>
                <Show onMobile>
                  <Attributes
                    data-testid="paint-thickness-attributes"
                    rows={toPairs(exterior.paintData).map((row) => {
                      const {text, severity} = getSeverityAndTextForPaintThickness(
                        row[1],
                        WARNING_TRESHOLD
                      );

                      return {
                        label: i18n.t(`paintThicknessData.${row[0]}`),
                        flag: {
                          text,
                          severity,
                        },
                      };
                    })}
                  />
                </Show>
              </Stack>
            </VStack>
          }
          flag={
            isStatusOkay
              ? {severity: 'good', text: i18n.t('sectionState.good')}
              : {
                  severity: 'damage',
                  text: getRecordsTranslate(countDamages(damagesData)),
                }
          }
        />
      </div>
    </section>
  );
}

const showDamagesOverlay = (points: Record<string, number>) => {
  keys(points).forEach((key, index) => {
    const count = points[key];
    if (isPositive(count)) {
      setTimeout(
        () => {
          const partTextElement = document.getElementById(`exterior-front-${key}-text`);
          const partPointElement = document.getElementById(`exterior-front-${key}-point`);
          const partOverlayElement = document.getElementById(`exterior-front-${key}-overlay`);
          if (partTextElement) {
            partTextElement.textContent = count.toString();
          }
          if (partOverlayElement) {
            partOverlayElement.style.opacity = '0.3';
          }
          if (partPointElement) {
            partPointElement.style.opacity = '1';
            partPointElement.style.transform = 'scale(1)';
          }
        },
        BASE_DELAY + index * POINT_DELAY
      );

      setTimeout(
        () => {
          const partTextElement = document.getElementById(`exterior-back-${key}-text`);
          const partPointElement = document.getElementById(`exterior-back-${key}-point`);
          const partOverlayElement = document.getElementById(`exterior-back-${key}-overlay`);
          if (partTextElement) {
            partTextElement.textContent = count.toString();
          }
          if (partOverlayElement) {
            partOverlayElement.style.opacity = '0.3';
          }
          if (partPointElement) {
            partPointElement.style.opacity = '1';
            partPointElement.style.transform = 'scale(1)';
          }
        },
        BASE_DELAY - Math.round(POINT_DELAY / 2) + index * POINT_DELAY
      );
    }
  });
};

const showPaintOverlay = (points: Record<string, ValueWithUnit | Nullish>, theme: DefaultTheme) => {
  keys(points).forEach((key, index) => {
    const thickness = points[key];
    if (isPositive(toNumber(thickness?.value))) {
      setTimeout(
        () => {
          const partTextElements = document.getElementsByClassName(`svg-paint-${key}-text`);
          const partPointElements = document.getElementsByClassName(`svg-paint-${key}-point`);
          const partPointBackgroundElements = document.getElementsByClassName(
            `svg-paint-${key}-point-bg`
          );
          const partPointFillElements = document.getElementsByClassName(
            `svg-paint-${key}-point-fill`
          );
          const partOverlayElements = document.getElementsByClassName(`svg-paint-${key}-overlay`);
          const partOverlayFillElements = document.getElementsByClassName(
            `svg-paint-${key}-overlay`
          );
          const hasWarning = gt(thickness?.value ?? 0, WARNING_TRESHOLD);

          Object.values(partTextElements).forEach((partTextElement) => {
            partTextElement.textContent =
              getValueWithUnit({
                auditValue: thickness,
                defaultUnitTranslate: i18n.t('metricMicroMetre'),
              }) ?? EMPTY_PLACEHOLDER;
            if (isHTMLElement(partTextElement)) {
              partTextElement.style.fill = hasWarning
                ? theme.colors.text.primary
                : theme.colors.text.inverted;
            }
          });
          Object.values(partOverlayElements).forEach((partOverlayElement) => {
            if (isHTMLElement(partOverlayElement)) {
              partOverlayElement.style.opacity = '1';
            }
          });
          Object.values(partOverlayFillElements).forEach((partOverlayFillElement) => {
            if (isHTMLElement(partOverlayFillElement)) {
              partOverlayFillElement.style.fill = hasWarning
                ? theme.colors.severity.warningLight
                : theme.colors.severity.successLight;
            }
          });
          Object.values(partOverlayFillElements).forEach((partOverlayFillElement) => {
            if (isHTMLElement(partOverlayFillElement)) {
              partOverlayFillElement.style.fill = hasWarning
                ? theme.colors.severity.warningLight
                : theme.colors.severity.successLight;
            }
          });
          Object.values(partPointFillElements).forEach((partPointFillElement) => {
            if (isHTMLElement(partPointFillElement)) {
              partPointFillElement.style.fill = hasWarning
                ? theme.colors.severity.warningBase
                : theme.colors.severity.successBase;
            }
          });
          Object.values(partPointElements).forEach((partPointElement) => {
            if (isHTMLElement(partPointElement)) {
              partPointElement.style.opacity = '1';
              partPointElement.style.transform = 'scale(1)';
            }
          });
          if (gte(toNumber(thickness?.value), 1000)) {
            Object.values(partPointBackgroundElements).forEach((partPointBackgroundElement) => {
              if (isHTMLElement(partPointBackgroundElement)) {
                partPointBackgroundElement.style.transform = 'scaleX(1.2)';
              }
            });
          }
        },
        BASE_DELAY + index * POINT_DELAY
      );
    }
  });
};

const isHTMLElement = (element: Element): element is HTMLElement => 'style' in element;

const DAMAGES_IN_VIEW_TRESHOLD = 0.5;
const PAINT_IN_VIEW_TRESHOLD = 0.3;
const WARNING_TRESHOLD = 300;
const BASE_DELAY = 70;
const POINT_DELAY = 25;
