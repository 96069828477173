import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {ComparisonKeyOf} from '../consts/comparisonKeys';
import {getAuditFieldById} from './getAuditFieldById';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';

interface AuditFieldValueParams {
  key: ComparisonKeyOf;
  relatedActions: RelatedAction[] | Nullish;
  auditCategories: AuditCategory[];
}

export function getAuditFieldValue(params: AuditFieldValueParams) {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);
  const value = getAuditFieldById(relatedAction?.id, params.auditCategories)?.value;
  return isNotNilOrEmpty(value) && value !== 'null' ? value : null;
}
