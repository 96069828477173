import {mergeRight} from 'ramda';

import {Nullish} from 'shared';

import {Category, RelatedAction} from '../api/digitalCertificateApi';
import {getCategoriesByUniqueKeys} from './getCategoriesByUniqueKeys';

type getAdditionalRelatedActionsByUniqueKeyParams = {
  categories: Category[];
  parentUniqueKey: string;
  uniqueKey?: string | string[] | Nullish;
};

export const getAdditionalRelatedActionsByUniqueKeys = (
  params: getAdditionalRelatedActionsByUniqueKeyParams
) => {
  const categories = getCategoriesByUniqueKeys({
    categories: params.categories,
    parentUniqueKey: params.parentUniqueKey,
    uniqueKey: params.uniqueKey,
  });

  const additionalRelatedActions = categories?.reduce(
    (filteredItems: RelatedAction[], category) => {
      if (category.paramDefinitions?.additionalInformation?.length) {
        category.paramDefinitions.additionalInformation.forEach((a) => {
          filteredItems.push(mergeRight(a, {name: category.name}));
        });
      }
      return filteredItems;
    },
    []
  );

  return additionalRelatedActions && additionalRelatedActions.length > 0
    ? additionalRelatedActions
    : null;
};
