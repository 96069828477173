import {SectionConfig} from '../types/SectionConfig';

export const getHiddenSections = (
  sectionsConfigs: SectionConfig[] = [],
  showEmptyFields: boolean
) => {
  if (showEmptyFields) {
    return [];
  }
  const hiddenSections = sectionsConfigs
    .filter((section) => section.isHidden)
    .map((section) => section.name);
  return hiddenSections;
};
