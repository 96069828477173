import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {AuditCategory} from '../api/digitalCertificateApi';

export function getAuditAssetsById(id: string | Nullish, auditCategories: AuditCategory[]) {
  if (isNil(auditCategories) || isNil(id)) {
    return null;
  }
  const category = auditCategories.find((c) => c.type === 'assets' && c.paramDefinitionId === id);
  if (category?.type !== 'assets') {
    return null;
  }
  return category;
}
