import {isNil} from 'ramda';

import {NestedValuesByKeyParams} from '../../../types/NestedValuesByKeyParams';
import {getAuditFieldById} from '../../../utils/getAuditFieldById';
import {getAuditFieldLabel} from '../../../utils/getAuditFieldLabel';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';

export const getLanguageOfInstructions = (params: NestedValuesByKeyParams) => {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);

  if (isNil(relatedAction)) {
    return null;
  }

  const content = relatedAction.values?.find(
    (v) => v.value === getAuditFieldById(relatedAction?.id, params.auditCategories)?.value
  )?.content;

  return getAuditFieldLabel({
    key: 'documentationLanguageOfInstructions',
    relatedActions: content?.relatedActions,
    auditCategories: params.auditCategories,
    language: params.language,
  });
};
