import {Nullish} from 'shared';

import {FeatureStateFlagSeverity} from '../components/FeatureStateFlag/FeatureStateFlag';
import {SlideImage} from '../types/SlideImage';
import {getWheelsAndTires} from './getWheelsAndTires';

type WheelsAndTiresType = ReturnType<typeof getWheelsAndTires>;
export type SetsKeys =
  | 'firstSetLeftFront'
  | 'firstSetRightFront'
  | 'firstSetLeftRear'
  | 'firstSetRightRear'
  | 'secondSetLeftFront'
  | 'secondSetRightFront'
  | 'secondSetLeftRear'
  | 'secondSetRightRear'
  | 'rimsOnlySecondSetLeftFront'
  | 'rimsOnlySecondSetRightFront'
  | 'rimsOnlySecondSetLeftRear'
  | 'rimsOnlySecondSetRightRear'
  | 'tiresOnlySecondSetLeftFront'
  | 'tiresOnlySecondSetRightFront'
  | 'tiresOnlySecondSetLeftRear';

type SetsData = {
  state: {severity: FeatureStateFlagSeverity; text: string | null} | undefined;
  comment: string | Nullish;
  photos: SlideImage | null | undefined;
  tire: string | null;
  depth: {value: string | null; unit: string} | null;
  width: {value: string | null; unit: string} | null;
  height: {value: string | null; unit: string} | null;
  discType: string | null | undefined;
  diameter: string | Nullish;
};
export type GetWheelsReturnType = Record<SetsKeys, Partial<SetsData>> | null;

export const getWheels = (wheels: WheelsAndTiresType): GetWheelsReturnType => {
  if (!wheels) {
    return null;
  }
  const firstSetLeftFront = {
    state: wheels.firstSet.LEFT_FRONT.state,
    comment: wheels.firstSet.LEFT_FRONT.comment,
    photos: wheels.firstSet.LEFT_FRONT.slideImage,
    discType: wheels.firstSet.LEFT_FRONT.discType,
    diameter: wheels.firstSet.LEFT_FRONT.diameter,
    tire: wheels.firstSet.LEFT_FRONT.tire,
    depth: wheels.firstSet.LEFT_FRONT.depth,
    width: wheels.firstSet.LEFT_FRONT.width,
    height: wheels.firstSet.LEFT_FRONT.height,
  };
  const firstSetRightFront = {
    state: wheels.firstSet.RIGHT_FRONT.state,
    comment: wheels.firstSet.RIGHT_FRONT.comment,
    photos: wheels.firstSet.RIGHT_FRONT.slideImage,
    discType: wheels.firstSet.RIGHT_FRONT.discType,
    diameter: wheels.firstSet.RIGHT_FRONT.diameter,
    tire: wheels.firstSet.RIGHT_FRONT.tire,
    depth: wheels.firstSet.RIGHT_FRONT.depth,
    width: wheels.firstSet.RIGHT_FRONT.width,
    height: wheels.firstSet.RIGHT_FRONT.height,
  };
  const firstSetLeftRear = {
    state: wheels.firstSet.LEFT_REAR.state,
    comment: wheels.firstSet.LEFT_REAR.comment,
    photos: wheels.firstSet.LEFT_REAR.slideImage,
    discType: wheels.firstSet.LEFT_REAR.discType,
    diameter: wheels.firstSet.LEFT_REAR.diameter,
    tire: wheels.firstSet.LEFT_REAR.tire,
    depth: wheels.firstSet.LEFT_REAR.depth,
    width: wheels.firstSet.LEFT_REAR.width,
    height: wheels.firstSet.LEFT_REAR.height,
  };
  const firstSetRightRear = {
    state: wheels.firstSet.RIGHT_REAR.state,
    comment: wheels.firstSet.RIGHT_REAR.comment,
    photos: wheels.firstSet.RIGHT_REAR.slideImage,
    discType: wheels.firstSet.RIGHT_REAR.discType,
    diameter: wheels.firstSet.RIGHT_REAR.diameter,
    tire: wheels.firstSet.RIGHT_REAR.tire,
    depth: wheels.firstSet.RIGHT_REAR.depth,
    width: wheels.firstSet.RIGHT_REAR.width,
    height: wheels.firstSet.RIGHT_REAR.height,
  };
  const secondSetLeftFront = {
    state: wheels.completeSecondSet?.LEFT_FRONT?.state,
    comment: wheels.completeSecondSet?.LEFT_FRONT?.comment,
    photos: wheels.completeSecondSet?.LEFT_FRONT?.slideImage,
    discType: wheels.completeSecondSet?.LEFT_FRONT?.discType,
    diameter: wheels.completeSecondSet?.LEFT_FRONT?.diameter,
    tire: wheels.completeSecondSet?.LEFT_FRONT?.tire,
    depth: wheels.completeSecondSet?.LEFT_FRONT?.depth,
    width: wheels.completeSecondSet?.LEFT_FRONT?.width,
    height: wheels.completeSecondSet?.LEFT_FRONT?.height,
  };
  const secondSetRightFront = {
    state: wheels.completeSecondSet?.RIGHT_FRONT.state,
    comment: wheels.completeSecondSet?.RIGHT_FRONT.comment,
    photos: wheels.completeSecondSet?.RIGHT_FRONT?.slideImage,
    discType: wheels.completeSecondSet?.RIGHT_FRONT?.discType,
    diameter: wheels.completeSecondSet?.RIGHT_FRONT?.diameter,
    tire: wheels.completeSecondSet?.RIGHT_FRONT?.tire,
    depth: wheels.completeSecondSet?.RIGHT_FRONT?.depth,
    width: wheels.completeSecondSet?.RIGHT_FRONT?.width,
    height: wheels.completeSecondSet?.RIGHT_FRONT?.height,
  };
  const secondSetLeftRear = {
    state: wheels.completeSecondSet?.LEFT_REAR.state,
    comment: wheels.completeSecondSet?.LEFT_REAR.comment,
    photos: wheels.completeSecondSet?.LEFT_REAR?.slideImage,
    discType: wheels.completeSecondSet?.LEFT_REAR?.discType,
    diameter: wheels.completeSecondSet?.LEFT_REAR?.diameter,
    tire: wheels.completeSecondSet?.LEFT_REAR?.tire,
    depth: wheels.completeSecondSet?.LEFT_REAR?.depth,
    width: wheels.completeSecondSet?.LEFT_REAR?.width,
    height: wheels.completeSecondSet?.LEFT_REAR?.height,
  };
  const secondSetRightRear = {
    state: wheels.completeSecondSet?.RIGHT_REAR.state,
    comment: wheels.completeSecondSet?.RIGHT_REAR.comment,
    photos: wheels.completeSecondSet?.RIGHT_REAR?.slideImage,
    discType: wheels.completeSecondSet?.RIGHT_REAR?.discType,
    diameter: wheels.completeSecondSet?.RIGHT_REAR?.diameter,
    tire: wheels.completeSecondSet?.RIGHT_REAR?.tire,
    depth: wheels.completeSecondSet?.RIGHT_REAR?.depth,
    width: wheels.completeSecondSet?.RIGHT_REAR?.width,
    height: wheels.completeSecondSet?.RIGHT_REAR?.height,
  };
  const rimsOnlySecondSetLeftFront = {
    state: wheels.rimsOnlySecondSet?.LEFT_FRONT?.state,
    comment: wheels.rimsOnlySecondSet?.LEFT_FRONT?.comment,
    photos: wheels.rimsOnlySecondSet?.LEFT_FRONT?.slideImage,
    discType: wheels.rimsOnlySecondSet?.LEFT_FRONT?.discType,
    diameter: wheels.rimsOnlySecondSet?.LEFT_FRONT?.diameter,
  };
  const rimsOnlySecondSetRightFront = {
    state: wheels.rimsOnlySecondSet?.RIGHT_FRONT?.state,
    comment: wheels.rimsOnlySecondSet?.RIGHT_FRONT?.comment,
    photos: wheels.rimsOnlySecondSet?.RIGHT_FRONT?.slideImage,
    discType: wheels.rimsOnlySecondSet?.RIGHT_FRONT?.discType,
    diameter: wheels.rimsOnlySecondSet?.RIGHT_FRONT?.diameter,
  };
  const rimsOnlySecondSetLeftRear = {
    state: wheels.rimsOnlySecondSet?.LEFT_REAR?.state,
    comment: wheels.rimsOnlySecondSet?.LEFT_REAR?.comment,
    photos: wheels.rimsOnlySecondSet?.LEFT_REAR?.slideImage,
    discType: wheels.rimsOnlySecondSet?.LEFT_REAR?.discType,
    diameter: wheels.rimsOnlySecondSet?.LEFT_REAR?.diameter,
  };
  const rimsOnlySecondSetRightRear = {
    state: wheels.rimsOnlySecondSet?.RIGHT_REAR?.state,
    comment: wheels.rimsOnlySecondSet?.RIGHT_REAR?.comment,
    photos: wheels.rimsOnlySecondSet?.RIGHT_REAR?.slideImage,
    discType: wheels.rimsOnlySecondSet?.RIGHT_REAR?.discType,
    diameter: wheels.rimsOnlySecondSet?.RIGHT_REAR?.diameter,
  };
  const tiresOnlySecondSetLeftFront = {
    comment: wheels.tiresOnlySecondSet?.LEFT_FRONT?.comment,
    photos: wheels.tiresOnlySecondSet?.LEFT_FRONT?.slideImage,
    tire: wheels.tiresOnlySecondSet?.LEFT_FRONT?.tire,
    depth: wheels.tiresOnlySecondSet?.LEFT_FRONT?.depth,
    width: wheels.tiresOnlySecondSet?.LEFT_FRONT?.width,
    height: wheels.tiresOnlySecondSet?.LEFT_FRONT?.height,
  };
  const tiresOnlySecondSetRightFront = {
    comment: wheels.tiresOnlySecondSet?.RIGHT_FRONT?.comment,
    photos: wheels.tiresOnlySecondSet?.RIGHT_FRONT?.slideImage,
    tire: wheels.tiresOnlySecondSet?.RIGHT_FRONT?.tire,
    depth: wheels.tiresOnlySecondSet?.RIGHT_FRONT?.depth,
    width: wheels.tiresOnlySecondSet?.RIGHT_FRONT?.width,
    height: wheels.tiresOnlySecondSet?.RIGHT_FRONT?.height,
  };
  const tiresOnlySecondSetLeftRear = {
    comment: wheels.tiresOnlySecondSet?.LEFT_REAR?.comment,
    photos: wheels.tiresOnlySecondSet?.LEFT_REAR?.slideImage,
    tire: wheels.tiresOnlySecondSet?.LEFT_REAR?.tire,
    depth: wheels.tiresOnlySecondSet?.LEFT_REAR?.depth,
    width: wheels.tiresOnlySecondSet?.LEFT_REAR?.width,
    height: wheels.tiresOnlySecondSet?.LEFT_REAR?.height,
  };
  return {
    tiresOnlySecondSetLeftRear,
    firstSetLeftRear,
    firstSetLeftFront,
    firstSetRightFront,
    firstSetRightRear,
    secondSetLeftFront,
    secondSetRightFront,
    secondSetLeftRear,
    secondSetRightRear,
    rimsOnlySecondSetLeftFront,
    rimsOnlySecondSetRightFront,
    rimsOnlySecondSetLeftRear,
    rimsOnlySecondSetRightRear,
    tiresOnlySecondSetLeftFront,
    tiresOnlySecondSetRightFront,
  };
};
