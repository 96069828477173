import {Show, VStack} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Carousel} from '../../components/Carousel/Carousel';
import {DocumentationItem} from '../../components/DocumentationItem/DocumentationItem';
import {Section} from '../../components/Section/Section';
import {Separator} from '../../components/Separator/Separator';
import {ServiceHistoryDataBundle} from '../../hooks/useGetDataServiceHistory';
import i18n from '../../i18n/i18n';

type ServiceHistoryPrintProps = ServiceHistoryDataBundle;
export function ServiceHistory({
  serviceHistory,
  serviceHistorySlideshow,
  features,
}: ServiceHistoryPrintProps) {
  if (!features?.features || !serviceHistory) {
    return null;
  }

  const {
    serviceHistoryAttributes,
    lastOilChangeAttributes,
    lastOilChangeInGearboxAttributes,
    lastOilChangeInDifferentialAttributes,
    lastBrakeFluidChangeAttributes,
  } = features.features;

  return (
    <Section
      id="SERVICE_HISTORY"
      icon="repaired"
      heading={i18n.t('serviceHistory')}
      content={
        <VStack spacing={[3, 6, 6, 10]}>
          <Show when={isNotNilOrEmpty(serviceHistoryAttributes)}>
            <DocumentationItem
              attributes={serviceHistoryAttributes}
              heading={i18n.t('serviceInspections')}
              comment={serviceHistory?.inspections?.comment}
            />
            <Separator />
          </Show>
          <Show when={isNotNilOrEmpty(lastOilChangeAttributes)}>
            <DocumentationItem
              heading={i18n.t('lastOilChange')}
              attributes={lastOilChangeAttributes}
              comment={serviceHistory?.lastOilChange?.comment}
            />
            <Separator />
          </Show>
          <Show when={isNotNilOrEmpty(lastBrakeFluidChangeAttributes)}>
            <DocumentationItem
              heading={i18n.t('lastBrakeFluidChange')}
              attributes={lastBrakeFluidChangeAttributes}
              comment={serviceHistory?.lastBrakeFluidChange?.comment}
            />
            <Separator />
          </Show>
          <Show when={isNotNilOrEmpty(lastOilChangeInGearboxAttributes)}>
            <DocumentationItem
              heading={i18n.t('lastOilChangeInGearbox')}
              attributes={lastOilChangeInGearboxAttributes}
              comment={serviceHistory?.lastOilChangeInGearbox?.comment}
            />
            <Separator />
          </Show>
          <Show when={isNotNilOrEmpty(lastOilChangeInDifferentialAttributes)}>
            <DocumentationItem
              heading={i18n.t('lastOilChangeInDifferential')}
              attributes={lastOilChangeInDifferentialAttributes}
              comment={serviceHistory?.lastOilChangeInDifferential?.comment}
            />
          </Show>
          <Show when={isNotNilOrEmpty(serviceHistorySlideshow)}>
            <Separator />
            <Carousel isFilterOfDamageDisabled data={serviceHistorySlideshow} />
          </Show>
        </VStack>
      }
    />
  );
}
