import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {RelatedAction} from '../api/digitalCertificateApi';
import {ComparisonKeyOf, comparisonKeys} from '../consts/comparisonKeys';

export const getRelatedActionByComparisonKey = (
  key: ComparisonKeyOf,
  relatedActions: RelatedAction[] | Nullish
) => {
  const comparisonKey = isNilOrEmpty(comparisonKeys[key as keyof typeof comparisonKeys])
    ? key
    : comparisonKeys[key as keyof typeof comparisonKeys];
  return relatedActions?.find((a) => a.comparisonKey === comparisonKey) ?? null;
};
