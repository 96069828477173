import {motion} from 'framer-motion';
import {Hide, HStack, Icon, Show, Space, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useLayoutEffect, useRef, useState} from 'react';

import {Nullish, suffixTestId, TestIdProps, useToggle} from 'shared';

import i18n from '../../i18n/i18n';
import {Button} from '../Button/Button';

interface CommentExpandableProps extends TestIdProps {
  comment?: string | Nullish;
}

export function CommentExpandable(props: CommentExpandableProps) {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isCommentExpanded, toggleComment] = useToggle();
  const [isExpandable, setIsExpandable] = useState(false);

  const currentHeight = isCommentExpanded ? 'auto' : HEIGHT;

  useLayoutEffect(() => {
    const textHeight = textRef?.current?.offsetHeight;
    setIsExpandable((textHeight ?? 0) > TRESHOLD);
  }, [props.comment]);

  const text = (
    <>
      <div ref={textRef}>
        <Text
          size="base"
          color="secondary"
          overflowWrap="anywhere"
          data-testid={suffixTestId('comment', props)}
        >
          {props.comment}
        </Text>
      </div>
      <Show when={isExpandable}>
        <motion.div
          initial={false}
          animate={{
            opacity: isCommentExpanded ? 0 : 1,
          }}
        >
          <Overlay />
        </motion.div>
      </Show>
    </>
  );

  return (
    <VStack>
      <HStack spacing={4} align="center">
        <Icon value="comment" size={6} color="accent.base" />
        <Text size="base">{i18n.t('comment')}</Text>
      </HStack>
      <Space vertical={4} />
      <Show when={isExpandable}>
        <motion.div
          initial={false}
          style={{
            overflow: 'hidden',
            position: 'relative',
          }}
          animate={{
            height: isExpandable ? currentHeight : 'auto',
          }}
        >
          {text}
        </motion.div>
      </Show>
      <Hide when={isExpandable}>{text}</Hide>
      <Show when={isExpandable}>
        <Space vertical={isCommentExpanded ? 3 : 0} />
        <Button
          variant="outline"
          onClick={toggleComment}
          leftIcon={isCommentExpanded ? 'arrowUp' : 'arrowDown'}
          title={isCommentExpanded ? i18n.t('hideText') : i18n.t('showText')}
          data-testid={suffixTestId('button-expandable', props)}
        />
      </Show>
    </VStack>
  );
}

const Overlay = styled.div`
  position: absolute;
  inset: 50% 0 0;
  background: rgb(2 0 36);
  background: linear-gradient(180deg, rgb(2 0 36 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
`;

const HEIGHT = 170;
const TRESHOLD = 250;
