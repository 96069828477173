import {Grid, GridItem, Image, makeImageUrl} from 'platform/foundation';

import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getPhotosByKeys} from '../../utils/getPhotosByKeys';

export function PhotoDocumentationPrint() {
  const {vehicleAudit} = useGetDigitalCertificateData();

  const photoDocumentationPhotos = getPhotosByKeys({
    vehicleAudit,
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });

  return (
    <SectionPrint
      data-testid="photoDocumentation"
      icon="photoDocumentation"
      heading={i18n.t('photosHeader')}
    >
      <Grid columns={2} spacing={6}>
        {photoDocumentationPhotos?.map((slide) => (
          <GridItem key={slide.id} span={1}>
            <Image
              fit="cover"
              width="100%"
              height={80}
              borderRadius="small"
              src={makeImageUrl(slide.resizeUrl, 88)}
            />
          </GridItem>
        ))}
      </Grid>
    </SectionPrint>
  );
}
