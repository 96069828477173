import {Heading, Show, Text, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

interface TestDriveContentItemProps extends TestIdProps {
  value: string;
  label: string;
  isAlternative?: boolean;
}

export function TestDriveContentItem(props: TestDriveContentItemProps) {
  return (
    <VStack spacing={2}>
      <Show when={props.isAlternative}>
        <Heading size={1} data-testid={suffixTestId('value', props)} alternative>
          {props.value}
        </Heading>
      </Show>
      <Show when={!props.isAlternative}>
        <Heading size={1} data-testid={suffixTestId('value', props)}>
          {props.value}
        </Heading>
      </Show>
      <Text size="small" color="tertiary" data-testid={suffixTestId('value', props)}>
        {props.label}
      </Text>
    </VStack>
  );
}
