import {Lightbox, useLightbox} from 'platform/lightbox';

import {append, isNil, reject} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useSlideshow} from '../../../components/Slideshow/hooks/useSlideshow';
import {Slideshow} from '../../../components/Slideshow/Slideshow';
import {SlideImage} from '../../../types/SlideImage';

interface CarouselSlideshowProps extends TestIdProps {
  lightboxId: string;
  slides: SlideImage[] | Nullish;
  location?: SlideImage | Nullish;
  isFilterOfDamageDisabled?: true;
}

export function CarouselSlideshow(props: CarouselSlideshowProps) {
  const [lightboxControls, {onOpenBySlide}] = useLightbox(props.lightboxId);

  const slides = props.slides ?? [];
  const data = reject(isNil)(append(props.location, slides));

  const {slideshowApi} = useSlideshow(props.slides);

  return (
    <>
      <Lightbox
        data-testid={suffixTestId('carouselSlideshow', props)}
        controls={lightboxControls}
        images={data}
      />
      <Slideshow
        data-testid={suffixTestId('carouselSlideshow', props)}
        api={slideshowApi}
        onInspectImage={(slide) => onOpenBySlide(slide, data)}
      />
    </>
  );
}
