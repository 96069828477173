import {isNotNil} from 'ramda';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {getAuditAssetsById} from './getAuditAssetsById';

export const composeAssetsByRelatedActions = (
  relatedActions: RelatedAction[],
  auditCategories: AuditCategory[]
) => {
  const auditAssets = relatedActions.map((relatedAction) =>
    getAuditAssetsById(relatedAction.id, auditCategories)
  );
  const assets = auditAssets.filter(isNotNil);
  return assets?.length > 0 ? assets : null;
};
