import {Heading, HStack, Show, Text} from 'platform/foundation';

import {inc, isNotNil} from 'ramda';
import {round} from 'ramda-adjunct';

import {Nullish, TestIdProps} from 'shared';

import {SectionStateFlag, SectionStateFlagProps} from '../SectionStateFlag/SectionStateFlag';

interface CarouselHeaderPrintProps extends TestIdProps {
  title: string;
  numberOfSlides: number;
  slideIndex: number;
  flag?: SectionStateFlagProps | Nullish;
}

export function CarouselHeaderPrint(props: CarouselHeaderPrintProps) {
  const roundedTotalSlides = props.numberOfSlides;
  const totalSlides =
    roundedTotalSlides % 1 === 0 ? round(roundedTotalSlides) : round(inc(roundedTotalSlides));

  return (
    <HStack spacing={6} align="center" justify="flex-start">
      <Show when={isNotNil(props.flag)}>
        <SectionStateFlag {...props.flag!} />
      </Show>
      <Text color="tertiary">
        {inc(props.slideIndex)}/{totalSlides}
      </Text>
      <Heading size={2}>{props.title}</Heading>
    </HStack>
  );
}
