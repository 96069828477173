import {LocaleContextValue, useDateTimeFormatter} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {getSlideshowByUniqueKey} from '../components/Slideshow/utils/getSlideshowByUniqueKey';
import {SectionIdsType} from '../consts/Sections';
import {getServiceHistory} from '../utils/getServiceHistory';
import {getServiceHistoryFeatures} from '../utils/getServiceHistoryFeatures';

export type ServiceHistoryDataBundle = {
  serviceHistory: ReturnType<typeof getServiceHistory>;
  serviceHistorySlideshow: ReturnType<typeof getSlideshowByUniqueKey>;
  features: ReturnType<typeof getServiceHistoryFeatures> | null;
  sectionConfig: {
    name: SectionIdsType;
    isHidden: boolean;
  };
};
export const useGetDataServiceHistory = (
  vehicleAudit: VehicleAudit | null | undefined,
  locale: LocaleContextValue
): ServiceHistoryDataBundle => {
  const formatDateTime = useDateTimeFormatter();

  const serviceHistory = getServiceHistory({vehicleAudit, language: locale.language});
  const serviceHistorySlideshow = getSlideshowByUniqueKey({
    vehicleAudit,
    language: locale.language,
    parentUniqueKey: 'SERVICE_HISTORY',
  });
  const features = getServiceHistoryFeatures(serviceHistory, formatDateTime, locale);

  const shouldBeDataHidden = !features?.allFeatures
    ?.map((feature) => {
      if (feature.value === '—') {
        return;
      }
      return feature.value;
    })
    .some(isNotNilOrEmpty);

  return {
    serviceHistory,
    serviceHistorySlideshow,
    features,
    sectionConfig: {
      isHidden: shouldBeDataHidden,
      name: 'SERVICE_HISTORY',
    },
  };
};
