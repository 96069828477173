import {useLocale} from 'platform/locale';

import {isFeatureEnabled} from 'shared';

import {getActiveSections} from '../components/Section/utils/getActiveSections';
import {getHiddenSections} from '../utils/getHiddenSections';
import {useGetDataForDocumentation} from './useGetDataForDocumentation';
import {useGetDataForFeatures} from './useGetDataForFeatures';
import {useGetDataForMechanics} from './useGetDataForMechanics';
import {useGetDataForPhotoDocumentation} from './useGetDataForPhotoDocumentation';
import {useGetDataForTestDrive} from './useGetDataForTestDrive';
import {useGetDataForWheelsAndTires} from './useGetDataForWheelsAndTires';
import {useGetDataServiceHistory} from './useGetDataServiceHistory';
import {useGetDigitalCertificateData} from './useGetDigitalCertificateData';

export const useGetSectionsData = () => {
  const showEmptyFieldsFeatureFlag = !isFeatureEnabled('dc_hide_empty_section');
  const {vehicleAudit} = useGetDigitalCertificateData();
  const locale = useLocale();

  const wheelsAndTiresData = useGetDataForWheelsAndTires(vehicleAudit, locale);
  const documentationData = useGetDataForDocumentation(vehicleAudit, locale);
  const testDriveData = useGetDataForTestDrive(vehicleAudit);
  const serviceHistoryData = useGetDataServiceHistory(vehicleAudit, locale);
  const mechanicsData = useGetDataForMechanics(vehicleAudit, locale);
  const featuresData = useGetDataForFeatures(vehicleAudit, locale);
  const photoDocumentationData = useGetDataForPhotoDocumentation(vehicleAudit);

  const hiddenSections = getHiddenSections(
    [
      wheelsAndTiresData.sectionConfig,
      documentationData.sectionConfig,
      testDriveData.sectionConfig,
      serviceHistoryData.sectionConfig,
      mechanicsData.sectionConfig,
    ],
    !!vehicleAudit?.showEmptyFields || showEmptyFieldsFeatureFlag
  );

  const shouldBeHiddenOnAllModes = [
    ...(photoDocumentationData.sectionConfig.isHidden
      ? [photoDocumentationData.sectionConfig.name]
      : []),
    ...(featuresData.sectionConfig.isHidden ? [featuresData.sectionConfig.name] : []),
  ];

  const canBeSectionHide = !vehicleAudit?.showEmptyFields && !showEmptyFieldsFeatureFlag;

  const sections = getActiveSections(vehicleAudit?.auditStructure?.categories);

  return {
    wheelsAndTiresData,
    documentationData,
    testDriveData,
    serviceHistoryData,
    mechanicsData,
    featuresData,
    hiddenSections: [...hiddenSections, ...shouldBeHiddenOnAllModes],
    photoDocumentationData,
    sections,
    canBeSectionHide,
  };
};
