import {
  Box,
  getValueByDevice,
  Grid,
  Heading,
  Image,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';

import {i18n} from '../../i18n/i18n';

export function BannerSuccess() {
  const device = useDevice();
  return (
    <Box
      backgroundColor="severity.successLight"
      borderRadius="large"
      height="100%"
      overflow="hidden"
    >
      <Grid columns={[1, 2, 2, 2]} spacing={0} align="center">
        <Box flex={1} height="100%">
          <Image
            isLazy
            src={getValueByDevice(
              device,
              '../../assets/images/banner-success-mobile.svg',
              '../../assets/images/banner-success-tablet.svg',
              '../../assets/images/banner-success-desktop.svg',
              '../../assets/images/banner-success-desktop.svg'
            )}
            data-testid="bannerSuccessImage"
            fit="cover"
            height="100%"
          />
        </Box>
        <Box flex={1} padding={[3, 6, 6, 10]}>
          <VStack spacing={[3, 4, 4, 6]}>
            <Heading size={2}>{i18n.t('bannerSuccessHeader')}</Heading>
            <Text color="tertiary">{i18n.t('bannerSuccessDescription')}</Text>
          </VStack>
        </Box>
      </Grid>
    </Box>
  );
}
