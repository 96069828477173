import {useRef, useState} from 'react';

export const useGetDamageStatesAndRefs = () => {
  const damagesCarouselRef = useRef<HTMLDivElement>(null);
  const paintRef = useRef<HTMLDivElement>(null);
  const [chosenDamage, setChosenDamage] = useState<{index: number; key: string; area: string}>();
  const [modalPosition, setModalPosition] = useState({top: 0, left: 0});
  const [isDamageModalShown, setIsDamageModalShown] = useState(false);

  return {
    damagesCarouselRef,
    paintRef,
    chosenDamage,
    setChosenDamage,
    modalPosition,
    setModalPosition,
    isDamageModalShown,
    setIsDamageModalShown,
  };
};
