import {pipe, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

type PersonNameData = {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  titleBefore?: string | null;
  titleAfter?: string | null;
};

const getPersonAttributes = (personNameData: PersonNameData | Nullish) => [
  personNameData?.titleBefore,
  personNameData?.firstName,
  personNameData?.middleName,
  personNameData?.lastName,
  personNameData?.titleAfter,
];

export const getNaturalPersonFullName = pipe(getPersonAttributes, reject(isNilOrEmpty), (items) =>
  items?.length ? items.join(' ') : null
);
