import {match, Pattern} from 'ts-pattern';

import {always, isNil, isNotNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {Category} from '../api/digitalCertificateApi';

type CategoriesByUniqueKeysParams = {
  categories: Category[];
  parentUniqueKey: string;
  uniqueKey?: string | string[] | Nullish;
};

export const getCategoriesByUniqueKeys = (params: CategoriesByUniqueKeysParams) => {
  const isEqual = (value: string | Nullish, compareWith: string | string[] | Nullish) => {
    if (isNil(value) || isNil(compareWith)) {
      return false;
    }
    if (isArray(compareWith)) {
      return compareWith.includes(value);
    }
    return value === compareWith;
  };

  const filteredData = params.categories.filter((category) =>
    match<[boolean, boolean, boolean, boolean, boolean], boolean>([
      category.visible,
      isNotNil(params.parentUniqueKey),
      isNotNil(params.uniqueKey),
      isEqual(category.parentUniqueKey, params.parentUniqueKey),
      isEqual(category.uniqueKey, params.uniqueKey),
    ])
      .with(
        [false, Pattern.boolean, Pattern.boolean, Pattern.boolean, Pattern.boolean],
        always(false)
      )
      .with([Pattern.any, false, false, Pattern.boolean, Pattern.boolean], always(false))
      .with([Pattern.any, true, true, true, false], always(false))
      .with([Pattern.any, true, true, false, true], always(false))
      .with([Pattern.any, true, true, true, true], always(true))
      .with([Pattern.any, true, false, false, Pattern.boolean], always(false))
      .with([Pattern.any, true, false, true, Pattern.boolean], always(true))
      .with([Pattern.any, false, true, Pattern.boolean, true], always(true))
      .with([Pattern.any, false, true, Pattern.boolean, false], always(false))
      .otherwise(always(false))
  );

  return filteredData?.length ? filteredData : null;
};
