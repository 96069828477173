import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';

/**
 * @about Hook to manage state via query string in url
 * @param key key for query params
 * @returns [value, setValue, clearValue]
 * @example
 * const [value, setValue, clearValue] = useQueryState("key")
 */
export function useQueryState(key: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const setValue = useCallback(
    (updatedValue: string) => {
      searchParams.set(key, updatedValue);
      setSearchParams(searchParams);
    },
    [key, searchParams, setSearchParams]
  );

  const clearValue = useCallback(() => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  }, [key, searchParams, setSearchParams]);

  return [searchParams.get(key), setValue, clearValue] as const;
}
