import {SetStateAction} from 'react';

export const findAndSetModalPosition = (
  chosenDamage: {index: number; key: string; area: string},
  setModalPosition: (value: SetStateAction<{top: number; left: number}>) => void,
  frontPointEvents: {
    element: HTMLElement | null;
    handleHover: () => void;
    offsetLeft: number | undefined;
  }[],
  backPointEvents: {
    element: HTMLElement | null;
    handleHover: () => void;
    offsetLeft: number | undefined;
  }[],
  parentId: string
) => {
  const divElement = document.getElementById(parentId);
  const divRect = divElement?.getBoundingClientRect();

  const pointRect = chosenDamage.area.includes('front')
    ? frontPointEvents[chosenDamage.index].element?.getBoundingClientRect()
    : backPointEvents[chosenDamage.index].element?.getBoundingClientRect();

  if (divRect && pointRect) {
    const relativeTop = pointRect.top - divRect.top + pointRect.height; // Přidejte výšku prvku k relativnímu top
    const relativeLeft = pointRect.left - divRect.left;
    setModalPosition({top: relativeTop, left: relativeLeft});
  }
};
