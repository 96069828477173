import {mergeRight} from 'ramda';

import {Nullish} from 'shared';

import {Category, RelatedAction} from '../api/digitalCertificateApi';
import {getCategoriesByUniqueKeys} from './getCategoriesByUniqueKeys';

type RelatedActionsByUniqueKeyParams = {
  categories: Category[];
  parentUniqueKey: string;
  uniqueKey?: string | string[] | Nullish;
};

export const getRelatedActionsByUniqueKeys = (params: RelatedActionsByUniqueKeyParams) => {
  const categories = getCategoriesByUniqueKeys({
    categories: params.categories,
    parentUniqueKey: params.parentUniqueKey,
    uniqueKey: params.uniqueKey,
  });

  const relatedActions = categories?.reduce((filteredItems: RelatedAction[], category) => {
    if (
      category.paramDefinitions?.relatedActions &&
      category.paramDefinitions.relatedActions.length > 0
    ) {
      category.paramDefinitions.relatedActions.forEach((a) => {
        filteredItems.push(mergeRight(a, {name: category.name}));
      });
    }
    return filteredItems;
  }, []);

  return relatedActions && relatedActions.length > 0 ? relatedActions : null;
};
