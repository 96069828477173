import {concat, isNil, reject} from 'ramda';

import {Nullish} from 'shared';

import {VehicleAudit} from '../../../api/digitalCertificateApi';
import {getPhotosByKeys} from '../../../utils/getPhotosByKeys';
import {getFilteredPhotosByKeys} from './getFilteredPhotosByKeys';

export function getInteriorPhotos(vehicleAudit: VehicleAudit | Nullish) {
  if (!vehicleAudit || !vehicleAudit.auditStructure.categories) {
    return null;
  }

  const interiorDamagePhotos = getPhotosByKeys({
    vehicleAudit,
    parentUniqueKey: 'INTERIOR',
  });

  const interiorPhotos = getFilteredPhotosByKeys(vehicleAudit, interiorKeys);

  return reject(isNil)(concat(interiorPhotos ?? [], interiorDamagePhotos ?? []));
}

const interiorKeys = [
  'seatRightFrontPhoto',
  'seatRightBackPhoto',
  'carTrunkPhoto',
  'seatLeftBackPhoto',
  'seatLeftFrontPhoto',
  'cockpitPhoto',
  'tachometerPhoto',
  'steeringWheelPhoto',
  'centralInstrumentPanelPhoto',
  'gearShiftPhoto',
  'lightAssistantControlPanelPhoto',
  'driverDoorPhoto',
  'driverDoorMirrorPhoto',
  'electricWindowPhoto',
  'driverDoorHandlePhoto',
];
