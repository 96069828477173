import {Center, getSize, Hide, Icon, Show, Spinner, ThemeIconKey} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

type ButtonIconSize = 'default' | 'small';
type ButtonIconVariant = 'primary' | 'secondary' | 'inverted' | 'outline' | 'transparent';

export interface ButtonIconProps extends TestIdProps {
  icon?: ThemeIconKey;
  size?: ButtonIconSize;
  variant: ButtonIconVariant;
  isDisabled?: boolean;
  onClick?: VoidFunction;
  isLoading?: boolean;
}

export function ButtonIcon(props: ButtonIconProps) {
  const theme = useTheme();

  const size = props.size === 'small' ? 7 : 11;

  return (
    <button
      css={css`
        outline: none;
        padding: 0;
        width: ${getSize(size)};
        height: ${getSize(size)};
        cursor: ${props.isDisabled ? 'auto' : 'pointer'};
        pointer-events: ${props.isDisabled ? 'none' : 'all'};
        opacity: ${props.isDisabled ? '0.5' : '1'};
        color: ${match<ButtonIconVariant>(props.variant)
          .with('primary', always(theme.colors.fill.white))
          .with('secondary', always(theme.colors.accent.base))
          .with('inverted', always(theme.colors.accent.darkest))
          .with('outline', always(theme.colors.accent.darkest))
          .with('transparent', always(theme.colors.fill.white))
          .exhaustive()};
        background-color: ${match<ButtonIconVariant>(props.variant)
          .with('primary', always(theme.colors.accent.base))
          .with('secondary', always(theme.colors.accent.lightest))
          .with('inverted', always(theme.colors.fill.white))
          .with('outline', always(theme.colors.general.transparent))
          .with('transparent', always(theme.colors.fill.overlayMedium))
          .exhaustive()};
        border-radius: 100%;
        border: ${props.variant === 'outline'
          ? `1px solid ${theme.colors.border.emphasisDefault}`
          : 'none'};
        &:hover {
          color: ${match<ButtonIconVariant>(props.variant)
            .with('primary', always(theme.colors.fill.white))
            .with('secondary', always(theme.colors.accent.base))
            .with('inverted', always(theme.colors.accent.base))
            .with('outline', always(theme.colors.accent.darkest))
            .with('transparent', always(theme.colors.fill.white))
            .exhaustive()};
          background-color: ${match<ButtonIconVariant>(props.variant)
            .with('primary', always(theme.colors.accent.dark))
            .with('secondary', always(theme.colors.accent.lighter))
            .with('inverted', always(theme.colors.fill.white))
            .with('outline', always(theme.colors.border.emphasisDefault))
            .with('transparent', always(theme.colors.fill.overlayStrong))
            .exhaustive()};
        }
      `}
      data-testid={suffixTestId('buttonIcon', props)}
      onClick={props.onClick}
    >
      <Center width="100%" height="100%">
        <Show when={props.isLoading}>
          <Spinner size="small" />
        </Show>
        <Hide when={props.isLoading}>
          <Icon value={props.icon} size={4} />
        </Hide>
      </Center>
    </button>
  );
}
