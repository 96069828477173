import i18n from 'i18next';
import {Grid, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useLocale} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish, parseDate, suffixTestId, TestIdProps} from 'shared';

import {Alert} from '../../../components/Alert/Alert';
import {ValueWithUnit} from '../../../types/ValueWithUnit';
import {getValueWithUnit} from '../../../utils/getValueWithUnit';
import {TestDriveContentItem} from './TestDriveContentItem';

export interface TestDriveContentProps extends TestIdProps {
  duration: string | Nullish;
  driveStart: string | Nullish;
  driveEnd: string | Nullish;
  distance: ValueWithUnit | Nullish;
  maxSpeed: ValueWithUnit | Nullish;
  avgSpeed: ValueWithUnit | Nullish;
  weakGpsSignal: string | Nullish;
}

export function TestDriveContent(props: TestDriveContentProps) {
  const locale = useLocale();
  const formatDateTime = useDateTimeFormatter();

  const duration =
    isNotNilOrEmpty(props.duration) && props.duration !== 'null' ? `${props.duration}` : null;

  return (
    <VStack spacing={[3, 6, 10, 10]}>
      <Grid columns={[1, 2, 3, 3]} spacing={[3, 6, 10, 10]} align="center">
        <TestDriveContentItem
          data-testid={suffixTestId('duration', props)}
          value={duration ?? EMPTY_PLACEHOLDER}
          label={i18n.t('testDrive.duration')}
          isAlternative={false}
        />
        <TestDriveContentItem
          data-testid={suffixTestId('driveStart', props)}
          value={
            props.driveStart
              ? formatDateTime('timeShort', parseDate(props.driveStart))
              : EMPTY_PLACEHOLDER
          }
          label={i18n.t('testDrive.start')}
          isAlternative
        />
        <TestDriveContentItem
          data-testid={suffixTestId('driveEnd', props)}
          value={
            props.driveEnd
              ? formatDateTime('timeShort', parseDate(props.driveEnd))
              : EMPTY_PLACEHOLDER
          }
          label={i18n.t('testDrive.end')}
          isAlternative
        />
        <TestDriveContentItem
          data-testid={suffixTestId('distance', props)}
          value={
            getValueWithUnit({
              auditValue: props.distance,
              localeConfig: locale.localeConfig,
              decimals: 1,
              defaultUnitTranslate: i18n.t('unitKilometers'),
            }) ?? EMPTY_PLACEHOLDER
          }
          label={i18n.t('testDrive.distance')}
          isAlternative
        />
        <TestDriveContentItem
          data-testid={suffixTestId('maxSpeed', props)}
          value={
            getValueWithUnit({
              auditValue: props.maxSpeed,
              localeConfig: locale.localeConfig,
              decimals: 0,
              defaultUnitTranslate: i18n.t('unitKilometersPerHour'),
            }) ?? EMPTY_PLACEHOLDER
          }
          label={i18n.t('testDrive.maxSpeed')}
          isAlternative
        />
        <TestDriveContentItem
          data-testid={suffixTestId('avgSpeed', props)}
          value={
            getValueWithUnit({
              auditValue: props.avgSpeed,
              localeConfig: locale.localeConfig,
              decimals: 0,
              defaultUnitTranslate: i18n.t('unitKilometersPerHour'),
            }) ?? EMPTY_PLACEHOLDER
          }
          label={i18n.t('testDrive.averageSpeed')}
          isAlternative
        />
      </Grid>
      <Show when={props.weakGpsSignal === 'true'}>
        <Alert>{i18n.t('testDrive.alert')}</Alert>
      </Show>
    </VStack>
  );
}
