import {LocaleContextValue, LocaleDateTimeFormat} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, EMPTY_PLACEHOLDER, Nullish, parseDate} from 'shared';

import {DocumentationAttribute} from '../components/DocumentationItem/DocumentationItem';
import {getServiceHistory} from './getServiceHistory';
import {getValueWithUnit} from './getValueWithUnit';

export const getServiceHistoryFeatures = (
  serviceHistory: ReturnType<typeof getServiceHistory>,
  formatDateTime: (dateTimeFormat: LocaleDateTimeFormat, value: Date) => string,
  locale: LocaleContextValue
) => {
  const isAttributeVisible = (attribute: {label: string | Nullish}) =>
    isNotNilOrEmpty(attribute?.label);
  if (!serviceHistory) {
    return null;
  }
  const getDate = (value: string | number | Nullish) =>
    isNotNil(value) ? formatDateTime('dateShort', parseDate(value)) : null;

  if (!serviceHistory) {
    return null;
  }
  const serviceHistoryAttributes = buildArray<DocumentationAttribute>()
    .when(isAttributeVisible(serviceHistory.inspections.regularServiceIntervalPerformed), {
      label: serviceHistory?.inspections?.regularServiceIntervalPerformed?.label ?? '',
      value:
        serviceHistory?.inspections?.regularServiceIntervalPerformed?.value ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.dateOfLastService), {
      label: serviceHistory?.inspections?.dateOfLastService?.label ?? '',
      value: getDate(serviceHistory?.inspections?.dateOfLastService?.value) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.dateOfNextService), {
      label: serviceHistory?.inspections?.dateOfNextService?.label ?? '',
      value: getDate(serviceHistory?.inspections?.dateOfNextService?.value) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.mileageOfLastService), {
      label: serviceHistory?.inspections?.mileageOfLastService?.label ?? '',
      value:
        getValueWithUnit({
          localeConfig: locale.localeConfig,
          auditValue: serviceHistory?.inspections?.mileageOfLastService?.value,
        }) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.mileageOfNextService), {
      label: serviceHistory?.inspections?.mileageOfNextService?.label ?? '',
      value:
        getValueWithUnit({
          localeConfig: locale.localeConfig,
          auditValue: serviceHistory?.inspections?.mileageOfNextService?.value,
        }) ?? EMPTY_PLACEHOLDER,
    });

  const lastOilChangeAttributes = buildArray<DocumentationAttribute>()
    .when(isAttributeVisible(serviceHistory?.lastOilChange?.dateOfLastOilChange), {
      label: serviceHistory?.lastOilChange?.dateOfLastOilChange?.label ?? '',
      value:
        getDate(serviceHistory?.lastOilChange?.dateOfLastOilChange?.value) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.lastOilChange?.amountOfKmDrivenFromOilChange), {
      label: serviceHistory?.lastOilChange?.amountOfKmDrivenFromOilChange?.label ?? '',
      value:
        getValueWithUnit({
          localeConfig: locale.localeConfig,
          auditValue: serviceHistory?.lastOilChange?.amountOfKmDrivenFromOilChange?.value,
        }) ?? EMPTY_PLACEHOLDER,
    });

  const lastBrakeFluidChangeAttributes = buildArray<DocumentationAttribute>()
    .when(isAttributeVisible(serviceHistory?.lastBrakeFluidChange?.dateOfLastBrakeFluidChange), {
      label: serviceHistory?.lastBrakeFluidChange?.dateOfLastBrakeFluidChange?.label ?? '',
      value:
        getDate(serviceHistory?.lastBrakeFluidChange?.dateOfLastBrakeFluidChange?.value) ??
        EMPTY_PLACEHOLDER,
    })
    .when(
      isAttributeVisible(
        serviceHistory?.lastBrakeFluidChange?.amountOfKmDrivenFromBrakeFluidChange
      ),
      {
        label:
          serviceHistory?.lastBrakeFluidChange?.amountOfKmDrivenFromBrakeFluidChange?.label ?? '',
        value:
          getValueWithUnit({
            localeConfig: locale.localeConfig,
            auditValue:
              serviceHistory?.lastBrakeFluidChange?.amountOfKmDrivenFromBrakeFluidChange?.value,
          }) ?? EMPTY_PLACEHOLDER,
      }
    );

  const lastOilChangeInGearboxAttributes = buildArray<DocumentationAttribute>()
    .when(
      isAttributeVisible(serviceHistory?.lastOilChangeInGearbox?.dateOfLastOilChangeInGearbox),
      {
        label: serviceHistory?.lastOilChangeInGearbox?.dateOfLastOilChangeInGearbox?.label ?? '',
        value:
          getDate(serviceHistory?.lastOilChangeInGearbox?.dateOfLastOilChangeInGearbox?.value) ??
          EMPTY_PLACEHOLDER,
      }
    )
    .when(
      isAttributeVisible(
        serviceHistory?.lastOilChangeInGearbox?.amountOfKmDrivenFromOilChangeInGearbox
      ),
      {
        label:
          serviceHistory?.lastOilChangeInGearbox?.amountOfKmDrivenFromOilChangeInGearbox?.label ??
          '',
        value:
          getValueWithUnit({
            localeConfig: locale.localeConfig,
            auditValue:
              serviceHistory?.lastOilChangeInGearbox?.amountOfKmDrivenFromOilChangeInGearbox?.value,
          }) ?? EMPTY_PLACEHOLDER,
      }
    );

  const lastOilChangeInDifferentialAttributes = buildArray<DocumentationAttribute>()
    .when(
      isAttributeVisible(
        serviceHistory?.lastOilChangeInDifferential?.dateOfLastOilChangeInDifferential
      ),
      {
        label:
          serviceHistory?.lastOilChangeInDifferential?.dateOfLastOilChangeInDifferential?.label ??
          '',
        value:
          getDate(
            serviceHistory?.lastOilChangeInDifferential?.dateOfLastOilChangeInDifferential?.value
          ) ?? EMPTY_PLACEHOLDER,
      }
    )
    .when(
      isAttributeVisible(
        serviceHistory?.lastOilChangeInDifferential?.amountOfKmDrivenFromOilChangeInDifferential
      ),
      {
        label:
          serviceHistory?.lastOilChangeInDifferential?.amountOfKmDrivenFromOilChangeInDifferential
            ?.label ?? '',
        value:
          getValueWithUnit({
            localeConfig: locale.localeConfig,
            auditValue:
              serviceHistory?.lastOilChangeInDifferential
                ?.amountOfKmDrivenFromOilChangeInDifferential?.value,
          }) ?? EMPTY_PLACEHOLDER,
      }
    );
  const allFeatures = [
    ...lastOilChangeAttributes,
    ...lastBrakeFluidChangeAttributes,
    ...lastOilChangeInGearboxAttributes,
    ...lastOilChangeInDifferentialAttributes,
  ];
  const features = {
    serviceHistoryAttributes,
    lastOilChangeAttributes,
    lastBrakeFluidChangeAttributes,
    lastOilChangeInGearboxAttributes,
    lastOilChangeInDifferentialAttributes,
  };
  return {allFeatures, features};
};
