import {Show, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import {match} from 'ts-pattern';

import {Fragment} from 'react';

import {all, always, any, dec, filter, isNotNil, length, pipe, propEq, values} from 'ramda';

import {Nullish} from 'shared';

import {CarouselPrint} from '../../components/Carousel/Carousel.print';
import {
  ListFeaturesItemProps,
  ListFeaturesItemVariant,
} from '../../components/ListFeatures/components/ListFeaturesItem';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {SectionStateFlagProps} from '../../components/SectionStateFlag/SectionStateFlag';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import {i18n} from '../../i18n/i18n';
import {getFeatures} from '../../utils/getFeatures';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';

export function FeaturesPrint() {
  const locale = useLocale();
  const {vehicleAudit} = useGetDigitalCertificateData();

  const featuresData = getFeatures({vehicleAudit, language: locale.language});

  if (!featuresData?.length) {
    return null;
  }

  const features = featuresData.reduce((filteredFeatures: ListFeaturesItemProps[], feature) => {
    if (isNotNil(feature.title)) {
      filteredFeatures.push({
        title: feature.title,
        description: feature.field?.statusValue === 'NON_FUNCTIONAL' ? feature.comment : null,
        variant: match<[string | Nullish, string | Nullish], ListFeaturesItemVariant>([
          feature.field?.value,
          feature.field?.statusValue,
        ])
          .with(['AVAILABLE', 'FUNCTIONAL'], always('ready'))
          .with(['AVAILABLE', 'NON_FUNCTIONAL'], always('damaged'))
          .with(['AVAILABLE', 'NON_TESTED'], always('neutral'))
          .otherwise(always('neutral')),
        flagText: match<[string | Nullish, string | Nullish], string | Nullish>([
          feature.field?.value,
          feature.field?.statusValue,
        ])
          .with(['AVAILABLE', 'NON_FUNCTIONAL'], always(feature.field?.statusLabel))
          .with(['AVAILABLE', 'NON_TESTED'], always(feature.field?.statusLabel))
          .otherwise(always(null)),
      });
    }
    return filteredFeatures;
  }, []);

  const featuresSlideshow = featuresData?.filter((f) => isNotNil(f.slides) && f.slides.length > 0);
  const isNotTested = all((f) => f.variant === 'neutral', features);
  const isDamaged = any((f) => f.variant === 'damaged', features);
  const countDamages: (object: object) => number = pipe(
    values,
    filter(propEq('damaged', 'variant')),
    length
  );

  const optimiseFeaturesForPdf =
    features?.length > MAX_FEATURES_ON_PDF_PAGE
      ? [
          features.slice(0, dec(MAX_FEATURES_ON_PDF_PAGE)),
          features.slice(dec(MAX_FEATURES_ON_PDF_PAGE), features.length),
        ]
      : [features];

  return (
    <SectionPrint
      icon="features"
      heading={i18n.t('featuresHeader')}
      flag={match<[boolean, boolean], Nullish | SectionStateFlagProps>([isNotTested, isDamaged])
        .with(
          [false, true],
          always({severity: 'damage', text: getRecordsTranslate(countDamages(features))})
        )
        .with([true, false], always(null))
        .otherwise(always({severity: 'good', text: i18n.t('sectionState.good')}))}
    >
      <VStack spacing={10}>
        {optimiseFeaturesForPdf.map((features) => (
          <Fragment key={features[0].title}>
            <ListFeatures columns={2} spacing={4} features={features} />
          </Fragment>
        ))}
        <Show when={featuresSlideshow && featuresSlideshow.length > 0}>
          <CarouselPrint isFilterOfDamageDisabled data={featuresSlideshow} />
        </Show>
      </VStack>
    </SectionPrint>
  );
}

const MAX_FEATURES_ON_PDF_PAGE = 66;
