import {AppLanguage} from 'platform/locale';
import {match, P} from 'ts-pattern';

import {always, isNil, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {
  AuditCategory,
  Category,
  RelatedAction,
  VehicleAudit,
} from '../../../api/digitalCertificateApi';
import {getAuditAssetsById} from '../../../utils/getAuditAssetsById';
import {getAuditFieldById} from '../../../utils/getAuditFieldById';
import {getCategoriesByUniqueKeys} from '../../../utils/getCategoriesByUniqueKeys';
import {mapAssetsToSlideImages} from '../../../utils/mapAssetsToSlideImages';

interface SlideshowByUniqueKeyParams {
  language: AppLanguage;
  vehicleAudit: VehicleAudit | Nullish;
  parentUniqueKey: string;
  uniqueKey?: string | string[] | Nullish;
  isSlidesRequired?: boolean;
  isCommentRequired?: boolean;
}

export function getSlideshowByUniqueKey(params: SlideshowByUniqueKeyParams) {
  if (!params.vehicleAudit || !params.vehicleAudit?.auditStructure.categories) {
    return null;
  }
  const categories = getCategoriesByUniqueKeys({
    parentUniqueKey: params.parentUniqueKey,
    uniqueKey: params.uniqueKey,
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!categories?.length) {
    return null;
  }

  const slideData = categories
    .map((category: Category) => ({
      title: category.name[params.language],
      comment: getComment(
        category.paramDefinitions?.additionalInformation,
        params.vehicleAudit?.audit.categories
      ),
      slides: getSlides(
        category.paramDefinitions?.additionalInformation,
        params.vehicleAudit?.audit.categories
      ),
    }))
    .filter((item) =>
      match([
        item.slides && item.slides.length > 0,
        isNotNil(item.comment),
        isTrue(params.isSlidesRequired),
        isTrue(params.isCommentRequired),
      ])
        .with([true, P.boolean, true, P.boolean], always(true))
        .with([P.boolean, true, P.boolean, true], always(true))
        .with([true, P.boolean, P.boolean, P.boolean], always(true))
        .with([P.boolean, true, P.boolean, P.boolean], always(true))
        .otherwise(always(false))
    );

  return slideData?.length > 0 ? slideData : null;
}

const getComment = (
  relatedActions: RelatedAction[] | Nullish,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (isNil(auditCategories) || isNil(relatedActions)) {
    return null;
  }
  const action = relatedActions?.find((i) => i.type === 'additional_comment');
  return getAuditFieldById(action?.id, auditCategories)?.value ?? null;
};

const getSlides = (
  relatedActions: RelatedAction[] | Nullish,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (isNil(auditCategories) || isNil(relatedActions)) {
    return null;
  }
  const action = relatedActions?.find((i) => i.type === 'additional_photos');
  const assets = getAuditAssetsById(action?.id, auditCategories)?.files;
  return assets?.length ? mapAssetsToSlideImages(assets) : null;
};
