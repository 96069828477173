import {componentsTheme} from 'platform/components';

import {themeIcons} from '../assets/icons/themeIcons';
import {digitalCertificateTheme} from './theme';

const accent = {
  darkest: '#00031E',
  darker: '#010635',
  dark: '#000746',
  base: '#00095B',
  light: '#0093F0',
  lighter: '#82CEFF',
  lightest: '#B8E3FE',
} as const;

const text = {
  primary: accent.darkest,
  secondary: '#42526E',
  tertiary: '#7A869A',
  inverted: '#FFFFFF',
  accent: accent.base,
  link: '#0047C1',
} as const;

const fill = {
  greyLight: '#FAFBFC',
  white: '#FFFFFF',
  overlayStrong: '#000C20F2',
  overlayMedium: '#000C20CC',
  cebia: '#5EDB3D',
} as const;

export const fordTheme = {
  ...componentsTheme,
  radii: digitalCertificateTheme.radii,
  fonts: digitalCertificateTheme.fonts,
  fontWeights: digitalCertificateTheme.fontWeights,
  fontSizes: digitalCertificateTheme.fontSizes,
  lineHeights: digitalCertificateTheme.lineHeights,
  letterSpacing: digitalCertificateTheme.letterSpacing,
  devices: digitalCertificateTheme.devices,
  zIndices: digitalCertificateTheme.zIndices,
  icons: themeIcons,
  colors: {
    ...componentsTheme.colors,
    accent,
    text,
    fill,
    severity: digitalCertificateTheme.colors.severity,
    border: digitalCertificateTheme.colors.border,
  },
} as const;
