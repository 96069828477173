import {APPLICATION_NAME} from '@digital-certificate/config';
import {
  Box,
  Center,
  getValueByDevice,
  Hide,
  HStack,
  Show,
  Space,
  useDevice,
} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {useParams} from 'react-router-dom';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {useLazyGetPdfByAuditIdQuery} from '../../api/digitalCertificateApi';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getCertificateHeader} from '../../utils/getCertificateHeader';
import {getWorkspaceCustomization} from '../../utils/getWorkspaceCustomization';
import {getWorkspaceFromUri} from '../../utils/getWorkspaceFromUri';
import {normalizeString} from '../../utils/normalizeString';
import {Button} from '../Button/Button';
import {FetchingButton} from '../FetchingButton/FetchingButton';
import {MobileNavigation} from './components/MobileNavigation';

const {workspace} = getWorkspaceFromUri();

export interface NavigationProps extends TestIdProps {
  sections: string[];
}

export function Navigation(props: NavigationProps) {
  const params = useParams<{auditId: string}>();
  const locale = useLocale();

  const {logo: applicationLogo} = getWorkspaceCustomization();

  const {vehicleAudit, vehicleCatalogue} = useGetDigitalCertificateData();
  const [getPdfByAuditId, {isFetching, isSuccess, isError}] = useLazyGetPdfByAuditIdQuery();

  const [isMobileNavigationOpen, openMobileNavigation, closeMobileNavigation] = useBoolean();
  const device = useDevice();

  const certificateHeader = getCertificateHeader({
    vehicle: vehicleAudit?.vehicle,
    vehicleCatalogue,
  });

  const downloadPdf = () => {
    if (!params.auditId || !workspace) {
      return;
    }

    getPdfByAuditId({
      auditId: params.auditId,
      workspace,
      locale: locale.language,
      filename: certificateHeader?.makeModel
        ? normalizeString(certificateHeader.makeModel)
        : 'digital-certificate',
    })
      .unwrap()
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = response.uri;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
      .catch(() => {});
  };

  const shareReport = () => {
    navigator
      .share({
        title: `${certificateHeader?.makeModel} • ${APPLICATION_NAME}`,
        url: window.location.href,
      })
      .then(() => {
        if (isMobileNavigationOpen) {
          closeMobileNavigation();
        }
      });
  };

  return (
    <>
      <Box
        position={getValueByDevice(device, 'sticky', 'static', 'static', 'static')}
        top={0}
        backgroundColor="general.white"
        zIndex="MENU_BAR"
        data-testid={suffixTestId('navigation', props)}
      >
        <Center justify="center" width="100%">
          <Box
            flexGrow={1}
            maxWidth={400}
            paddingVertical={[2, 4, 6, 6]}
            paddingHorizontal={[2, 10, 10, 20]}
          >
            <HStack align="center" justify="space-between">
              {applicationLogo}
              <Space horizontal={4} fillAvailable />
              <Show onMobile>
                <Button
                  data-testid="mobileMenuButton"
                  variant="inverted"
                  size="small"
                  leftIcon="menu"
                  title={i18n.t('menu')}
                  onClick={openMobileNavigation}
                />
              </Show>
              <Hide onMobile>
                <HStack spacing={2}>
                  <FetchingButton
                    data-testid="downloadButton"
                    variant="outline"
                    title={i18n.t('downloadPDF')}
                    icon="download"
                    isSuccess={isSuccess}
                    isFetching={isFetching}
                    isError={isError}
                    onClick={downloadPdf}
                  />
                  <Show when={navigator.share}>
                    <Button
                      data-testid="shareButton"
                      variant="outline"
                      title={i18n.t('share')}
                      leftIcon="share"
                      onClick={shareReport}
                    />
                  </Show>
                </HStack>
              </Hide>
            </HStack>
          </Box>
        </Center>
      </Box>
      <MobileNavigation
        onShareClick={shareReport}
        onDownloadClick={downloadPdf}
        sections={props.sections}
        isVisible={isMobileNavigationOpen}
        onClose={closeMobileNavigation}
        isPdfDownloading={isFetching}
      />
    </>
  );
}
