import {componentsTheme} from 'platform/components';

import {themeIcons} from '../assets/icons/themeIcons';

const radii = {
  xSmall: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
  border_radius_full: '1000px',
};

const fonts = {
  body: 'Inter, sans-serif',
  heading: 'Inter, sans-serif',
} as const;

const fontSizes = {
  display: {
    printer: {1: '2.5rem'},
    desktop: {1: '2.5rem'},
    notebook: {1: '2.5rem'},
    tablet: {1: '2rem'},
    mobile: {1: '1.75rem'},
  },
  heading: {
    printer: {
      1: '1.75rem',
      2: '1.5rem',
      3: '1.3125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    desktop: {
      1: '1.75rem',
      2: '1.5rem',
      3: '1.3125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    notebook: {
      1: '1.75rem',
      2: '1.5rem',
      3: '1.3125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    tablet: {
      1: '1.5rem',
      2: '1.25rem',
      3: '1.125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    mobile: {
      1: '1.25rem',
      2: '1.125rem',
      3: '1rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
  },
  text: {
    large: '1.125rem',
    base: '1rem',
    small: '0.875rem',
    xSmall: '0.75rem',
    xxSmall: '0.625rem',
  },
} as const;

const headlingLineHeights = {
  1: 1.25,
  2: 1.25,
  3: 1.25,
  4: 1.25,
  5: 1.25,
  6: 1.25,
} as const;

const lineHeights = {
  display: {
    printer: {1: 1.2},
    desktop: {1: 1.2},
    notebook: {1: 1.2},
    tablet: {1: 1.2},
    mobile: {1: 1.2},
  },
  heading: {
    printer: headlingLineHeights,
    desktop: headlingLineHeights,
    notebook: headlingLineHeights,
    tablet: headlingLineHeights,
    mobile: headlingLineHeights,
  },
  text: {
    large: 1.125,
    base: 1.4,
    small: 1.45,
    xSmall: 1.45,
    xxSmall: 1.5,
  },
} as const;

const letterSpacing = {
  display: {
    printer: {1: '-0.002em'},
    desktop: {1: '-0.002em'},
    notebook: {1: '-0.002em'},
    tablet: {1: '-0.002em'},
    mobile: {1: '-0.002em'},
  },
  heading: {
    printer: {
      1: '-0.004em',
      2: '-0.002em',
      3: '0.002em',
      4: '0.004em',
      5: '0.02em',
      6: '0.02em',
    },
    desktop: {
      1: '-0.004em',
      2: '-0.002em',
      3: '0.002em',
      4: '0.004em',
      5: '0.02em',
      6: '0.02em',
    },
    notebook: {
      1: '-0.004em',
      2: '-0.002em',
      3: '0.002em',
      4: '0.004em',
      5: '0.02em',
      6: '0.02em',
    },
    tablet: {
      1: '-0.002em',
      2: '0.004em',
      3: '0.008em',
      4: '0.004em',
      5: '0.02em',
      6: '0.02em',
    },
    mobile: {
      1: '0.004em',
      2: '0.008em',
      3: '0.004em',
      4: '0.004em',
      5: '0.02em',
      6: '0.02em',
    },
  },
  text: {
    large: '0.008em',
    base: '0.004em',
    small: '0.001em',
    xSmall: '0.01em',
    xxSmall: '0.02em',
  },
} as const;

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 600,
  display: {
    default: 600,
    alternative: 400,
  },
  heading: {
    default: 600,
    alternative: 400,
  },
  text: {
    default: 400,
    alternative: 600,
  },
} as const;

const accent = {
  darkest: '#05072C',
  darker: '#262A98',
  dark: '#3E27C4',
  base: '#3E47DD',
  light: '#8B92F4',
  lighter: '#D8DBFD',
  lightest: '#EBEDFE',
} as const;

const text = {
  primary: accent.darkest,
  secondary: '#42526E',
  tertiary: '#7A869A',
  inverted: '#FFFFFF',
  accent: accent.base,
  link: '#3E47DD',
} as const;

const severity = {
  informational: '#86D3FF',
  informationalDark: '#308DC1',
  informationalBase: '#86D3FF',
  informationalLight: '#E1F4FD',
  neutralDark: '#818181',
  neutralBase: '#D8D8D8',
  neutralLight: '#E8E8E8',
  successDark: '#147D3E',
  successBase: '#37A663',
  successLight: '#F7FCF8',
  warningDark: '#FF8A00',
  warningBase: '#FFA928',
  warningLight: '#FDF6E7',
  dangerDark: '#B73208',
  dangerBase: '#FF4409',
  dangerLight: '#FCF0ED',
} as const;

const fill = {
  greyLight: '#F2F6F9',
  white: '#FFFFFF',
  overlayStrong: '#05072CF2',
  overlayMedium: '#05072CCC',
  cebia: '#5EDB3D',
} as const;

const border = {
  emphasisDefault: '#DFE1E6',
} as const;

const breakpoints = {
  notebook: 1370,
  tablet: 1024,
  mobile: 768,
} as const;

const devices = {
  desktop: `(min-width: ${breakpoints.notebook}px)`,
  notebook: `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.notebook}px)`,
  tablet: `(min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.tablet}px)`,
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  printer: 'print',
} as const;

const zIndices = {
  PREVIEW: 1,
  MENU_BAR: 1000,
  NAVIGATION_TABS: 7000,
  CHOICE_MENU: 8000,
  MOBILE_NAVIGATION: 9000,
  LANGUAGE_DIALOG: 9000,
  LIGHTBOX_BODY: 10000,
  LIGHTBOX_SLIDER_ARROW: 10001,
  LIGHTBOX_OVERFLOW_OVERLAY: 10002,
  LIGHTBOX_OVERFLOW_MENU: 10003,
} as const;

export const digitalCertificateTheme = {
  ...componentsTheme,
  radii,
  fonts,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacing,
  devices,
  zIndices,
  icons: themeIcons,
  colors: {
    ...componentsTheme.colors,
    accent,
    text,
    severity,
    fill,
    border,
  },
  components: {
    ...componentsTheme.components,
    Image: {
      emptyFill: 'fill.greyLight',
    },
    Spinner: {
      thickness: {
        xSmall: '4px',
        small: '3px',
        large: '2px',
        default: '2px',
      },
      color: {
        light: 'fill.greyLight',
        dark: 'fill.overlayStrong',
      },
      size: {
        xSmall: 3,
        small: 4,
        large: 16,
        default: 11,
      },
    },
  },
} as const;
