import {DataUtilParams} from '../../../types/DataUtilParams';
import {getAuditFieldName} from '../../../utils/getAuditFieldName';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';
import {getRelatedActionsByUniqueKeys} from '../../../utils/getRelatedActionsByUniqueKeys';

export function getInterior(params: DataUtilParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.audit || !params.vehicleAudit.auditStructure) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: 'INTERIOR',
    categories: params.vehicleAudit.auditStructure.categories,
  });
  if (!relatedActions?.length) {
    return null;
  }

  return {
    comment: getAuditFieldValue({
      key: 'interiorComment',
      relatedActions,
      auditCategories: params.vehicleAudit?.audit.categories,
    }),
    features: [
      {
        name: getAuditFieldName({
          key: 'interiorNoOdorIndoor',
          relatedActions,
          language: params.language,
          auditCategories: params.vehicleAudit?.audit.categories,
        }),
        value: getAuditFieldValue({
          key: 'interiorNoOdorIndoor',
          relatedActions,
          auditCategories: params.vehicleAudit?.audit.categories,
        }),
      },
      {
        name: getAuditFieldName({
          key: 'interiorVehicleNonSmoking',
          relatedActions,
          language: params.language,
          auditCategories: params.vehicleAudit?.audit.categories,
        }),
        value: getAuditFieldValue({
          key: 'interiorVehicleNonSmoking',
          relatedActions,
          auditCategories: params.vehicleAudit?.audit.categories,
        }),
      },
    ],
  };
}
