import {
  Box,
  Heading,
  HStack,
  Icon,
  Image,
  makeImageUrl,
  Show,
  Space,
  VStack,
} from 'platform/foundation';

import {Fragment} from 'react';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {i18n} from '../../i18n/i18n';
import {SlideImage} from '../../types/SlideImage';
import {CarouselHeaderPrint} from '../CarouselHeader/CarouselHeader.print';
import {Comment} from '../Comment/CommentExpandable';

type DamageCarouselPrintSlide = {
  title: string | Nullish;
  comment: string | Nullish;
  location: SlideImage | Nullish;
  value: string | Nullish;
  slides: SlideImage[];
};

interface DamageCarouselPrintProps {
  data: DamageCarouselPrintSlide[] | Nullish;
}

export function DamageCarouselPrint(props: DamageCarouselPrintProps) {
  return (
    <>
      {props.data?.map((damage, index) => (
        <Fragment key={`${damage.title}-${damage.value}`}>
          <VStack spacing={10} width="100%">
            <CarouselHeaderPrint
              title={i18n.t('damage')}
              slideIndex={index}
              flag={{
                severity: 'damage',
              }}
              numberOfSlides={props.data!.length}
            />
            <HStack spacing={6}>
              <Box flex={1} maxWidth={100} width="100%">
                <HStack spacing={3} align="center">
                  <Icon value="location" size={6} color="accent.base" />
                  <Heading size={3} alternative>
                    {damage.title ?? i18n.t('location')}
                  </Heading>
                </HStack>
                <Show when={isNotNil(damage.location?.resizeUrl)}>
                  <Space vertical={3} />
                  <Image
                    borderRadius="small"
                    src={makeImageUrl(damage.location?.resizeUrl, 66)}
                    width="100%"
                    height={66}
                    fit="cover"
                    ratio="4 / 3"
                    position="center"
                  />
                </Show>
                <Show when={isNotNilOrEmpty(damage.comment)}>
                  <Space vertical={6} />
                  <Comment comment={damage.comment} />
                </Show>
              </Box>
              <Box flex={1} width="100%">
                <VStack spacing={6}>
                  {damage.slides.map((slide) => (
                    <Image
                      key={slide.id}
                      fit="cover"
                      position="center"
                      ratio="4 / 3"
                      width="100%"
                      height={100}
                      src={makeImageUrl(slide.resizeUrl, 100)}
                      alt={slide.title}
                      borderRadius="small"
                    />
                  ))}
                </VStack>
              </Box>
            </HStack>
          </VStack>
        </Fragment>
      ))}
    </>
  );
}
