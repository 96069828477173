import {RefObject, useCallback, useRef, useState} from 'react';

import {dec, inc, isNil} from 'ramda';

import {Nullish} from 'shared';

import {SlideImage} from '../../../types/SlideImage';

type UseSlideshowReturnType = {
  slideshowApi: SlideshowApi;
};

export interface SlideshowApi {
  prev: () => void;
  next: () => void;
  goTo: (slideIndex: number) => void;
  slides: SlideImage[] | Nullish;
  activeSlide: number;
  refs: {
    slider: RefObject<HTMLDivElement>;
  };
}

export function useSlideshow(data: SlideImage[] | Nullish): UseSlideshowReturnType {
  const slider = useRef<HTMLDivElement>(null);

  const [activeSlide, setActiveSlide] = useState(0);

  const _addClass = (element: Element | Nullish, ...tokens: string[]) => {
    if (!element) {
      return;
    }
    element.classList.add(...tokens);
  };

  const _removeClass = (element: Element | Nullish, ...tokens: string[]) => {
    if (!element) {
      return;
    }
    element.classList.remove(...tokens);
  };

  const _getAbsoluteIndex = useCallback(
    (slideIndex: number) =>
      slideIndex > (data?.length ?? 0) - 1
        ? 0
        : slideIndex < 0
          ? (data?.length ?? 0) - 1
          : slideIndex,
    [data?.length]
  );

  const _setPrev = (slideIndex: number) => {
    const nextActiveIndex = _getAbsoluteIndex(slideIndex);
    _addClass(slider.current?.children[nextActiveIndex], 'fadeInLeft');
    _addClass(slider.current?.children[activeSlide], 'fadeOutRight');
  };

  const _setNext = (slideIndex: number) => {
    const nextActiveIndex = _getAbsoluteIndex(slideIndex);
    _addClass(slider.current?.children[nextActiveIndex], 'fadeInRight');
    _addClass(slider.current?.children[activeSlide], 'fadeOutLeft');
  };

  const _stopAnimation = useCallback(() => {
    slider.current
      ?.querySelectorAll('.fadeOutRight, .fadeOutLeft, .fadeInLeft, .fadeInRight')
      .forEach((element) => {
        _removeClass(element, 'fadeOutRight', 'fadeOutLeft', 'fadeInLeft', 'fadeInRight');
      });
  }, []);

  const _setActive = useCallback(
    (slideIndex: number) => {
      if (isNil(slider.current)) {
        return;
      }
      const nextActiveIndex = _getAbsoluteIndex(slideIndex);
      setActiveSlide(nextActiveIndex);
    },
    [_getAbsoluteIndex]
  );

  const goTo = (slideIndex: number) => {
    if (slideIndex === activeSlide) {
      return;
    }

    _stopAnimation();
    _setActive(slideIndex);

    if (slideIndex < activeSlide) {
      _setPrev(slideIndex);
    } else {
      _setNext(slideIndex);
    }
  };

  const prev = () => {
    if (data && activeSlide === 0) {
      goTo(dec(data.length));
      return;
    }
    goTo(dec(activeSlide));
  };

  const next = () => {
    goTo(inc(activeSlide));
  };

  return {
    slideshowApi: {
      goTo,
      prev,
      next,
      slides: data,
      activeSlide,
      refs: {
        slider,
      },
    },
  };
}
