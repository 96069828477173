import {formatNumber, LocaleConfig} from 'platform/locale';

import {reject} from 'ramda';
import {isNilOrEmpty, toNumber} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {ValueWithUnit} from '../types/ValueWithUnit';

type Params = {
  auditValue: ValueWithUnit | Nullish;
  defaultUnitTranslate?: string;
  localeConfig?: LocaleConfig;
  decimals?: number;
};

export function getValueWithUnit(params: Params) {
  if (isNilOrEmpty(params.auditValue?.value) || params.auditValue?.value === 'NaN') {
    return null;
  }
  const value = params.localeConfig
    ? formatNumber(params.localeConfig, toNumber(params.auditValue!.value), params?.decimals ?? 0)
    : params.auditValue!.value;
  const unit = params.auditValue?.unit ?? params.defaultUnitTranslate;
  return reject(isNilOrEmpty)([value, unit]).join(' ');
}
