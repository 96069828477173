import {Heading, Show, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import {match} from 'ts-pattern';

import {all, always, any, filter, length, pipe, propEq, values} from 'ramda';
import {isNonEmptyString, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {buildArray, EMPTY_PLACEHOLDER, Nullish} from 'shared';

import {CarouselPrint} from '../../components/Carousel/Carousel.print';
import {ListFeaturesItemProps} from '../../components/ListFeatures/components/ListFeaturesItem';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {SectionStateFlagProps} from '../../components/SectionStateFlag/SectionStateFlag';
import {getSlideshowByUniqueKey} from '../../components/Slideshow/utils/getSlideshowByUniqueKey';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getMechanics} from '../../utils/getMechanics';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';
import {getValueWithUnit} from '../../utils/getValueWithUnit';

export function MechanicsPrint() {
  const locale = useLocale();

  const {vehicleAudit} = useGetDigitalCertificateData();

  const mechanics = getMechanics({vehicleAudit, language: locale.language});
  const mechanicSlideshow = getSlideshowByUniqueKey({
    vehicleAudit,
    language: locale.language,
    parentUniqueKey: 'MECHANICS',
    isSlidesRequired: true,
  });

  if (!mechanics) {
    return null;
  }

  const brakeSystemFeatures = buildArray<ListFeaturesItemProps>()
    .when(isFeatureVisible(mechanics.brake.brakeSystemFuctionality), {
      title: mechanics.brake.brakeSystemFuctionality.title!,
      flagText: mechanics.brake.brakeSystemFuctionality.label,
      variant: mechanics.brake.brakeSystemFuctionality.variant,
      description: mechanics.brake.brakeSystemFuctionality.description,
    })
    .when(isFeatureVisible(mechanics.brake.emergencyParkingBrakeSystem), {
      title: mechanics.brake.emergencyParkingBrakeSystem.title!,
      flagText: mechanics.brake.emergencyParkingBrakeSystem.label,
      variant: mechanics.brake.emergencyParkingBrakeSystem.variant,
      description: mechanics.brake.emergencyParkingBrakeSystem.description,
    })
    .when(isFeatureVisible(mechanics.brake.brakeFluid), {
      title: mechanics.brake.brakeFluid.title!,
      flagText: mechanics.brake.brakeFluid.label,
      variant: mechanics.brake.brakeFluid.variant,
      description: mechanics.brake.brakeFluid.description,
    });

  const driveSystemFeatures = buildArray<ListFeaturesItemProps>()
    .when(isFeatureVisible(mechanics.driveSystem.motor), {
      title: mechanics.driveSystem.motor.title!,
      flagText: mechanics.driveSystem.motor.label,
      variant: mechanics.driveSystem.motor.variant,
      description: mechanics.driveSystem.motor.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.engineCondition), {
      title: mechanics.driveSystem.engineCondition.title!,
      flagText: mechanics.driveSystem.engineCondition.label,
      variant: mechanics.driveSystem.engineCondition.variant,
      description: mechanics.driveSystem.engineCondition.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.vehicleDrivetrain), {
      title: mechanics.driveSystem.vehicleDrivetrain.title!,
      flagText: mechanics.driveSystem.vehicleDrivetrain.label,
      variant: mechanics.driveSystem.vehicleDrivetrain.variant,
      description: mechanics.driveSystem.vehicleDrivetrain.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.leakageOfOperatingFluidsFromEngine), {
      title: mechanics.driveSystem.leakageOfOperatingFluidsFromEngine.title!,
      flagText: mechanics.driveSystem.leakageOfOperatingFluidsFromEngine.label,
      variant: mechanics.driveSystem.leakageOfOperatingFluidsFromEngine.variant,
      description: mechanics.driveSystem.leakageOfOperatingFluidsFromEngine.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.steeringAssembly), {
      title: mechanics.driveSystem.steeringAssembly.title!,
      flagText: mechanics.driveSystem.steeringAssembly.label,
      variant: mechanics.driveSystem.steeringAssembly.variant,
      description: mechanics.driveSystem.steeringAssembly.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.transmission), {
      title: mechanics.driveSystem.transmission.title!,
      flagText: mechanics.driveSystem.transmission.label,
      variant: mechanics.driveSystem.transmission.variant,
      description: mechanics.driveSystem.transmission.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.leakageOfOperatingFluidsFromGearbox), {
      title: mechanics.driveSystem.leakageOfOperatingFluidsFromGearbox.title!,
      flagText: mechanics.driveSystem.leakageOfOperatingFluidsFromGearbox.label,
      variant: mechanics.driveSystem.leakageOfOperatingFluidsFromGearbox.variant,
      description: mechanics.driveSystem.leakageOfOperatingFluidsFromGearbox.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.playInGearbox), {
      title: mechanics.driveSystem.playInGearbox.title!,
      flagText: mechanics.driveSystem.playInGearbox.label,
      variant: mechanics.driveSystem.playInGearbox.variant,
      description: mechanics.driveSystem.playInGearbox.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.otherPartsOfDriveSystem), {
      title: mechanics.driveSystem.otherPartsOfDriveSystem.title!,
      flagText: mechanics.driveSystem.otherPartsOfDriveSystem.label,
      variant: mechanics.driveSystem.otherPartsOfDriveSystem.variant,
      description: mechanics.driveSystem.otherPartsOfDriveSystem.description,
    })
    .when(
      isFeatureVisible(mechanics.driveSystem.leakageOfOperatingFluidsFromOtherPartsDriveSystem),
      {
        title: mechanics.driveSystem.leakageOfOperatingFluidsFromOtherPartsDriveSystem.title!,
        flagText: mechanics.driveSystem.leakageOfOperatingFluidsFromOtherPartsDriveSystem.label,
        variant: mechanics.driveSystem.leakageOfOperatingFluidsFromOtherPartsDriveSystem.variant,
      }
    )
    .when(isFeatureVisible(mechanics.driveSystem.playInOtherParts), {
      title: mechanics.driveSystem.playInOtherParts.title!,
      flagText: mechanics.driveSystem.playInOtherParts.label,
      variant: mechanics.driveSystem.playInOtherParts.variant,
      description: mechanics.driveSystem.playInOtherParts.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.coolingSystem), {
      title: mechanics.driveSystem.coolingSystem.title!,
      flagText: mechanics.driveSystem.coolingSystem.label,
      variant: mechanics.driveSystem.coolingSystem.variant,
      description: mechanics.driveSystem.coolingSystem.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.leakageOfOperatingFluidsFromCoolingSystem), {
      title: mechanics.driveSystem.leakageOfOperatingFluidsFromCoolingSystem.title!,
      flagText: mechanics.driveSystem.leakageOfOperatingFluidsFromCoolingSystem.label,
      variant: mechanics.driveSystem.leakageOfOperatingFluidsFromCoolingSystem.variant,
      description: mechanics.driveSystem.leakageOfOperatingFluidsFromCoolingSystem.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.fuelSystem), {
      title: mechanics.driveSystem.fuelSystem.title!,
      flagText: mechanics.driveSystem.fuelSystem.label,
      variant: mechanics.driveSystem.fuelSystem.variant,
      description: mechanics.driveSystem.fuelSystem.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.fuelLeakFromFuelSystem), {
      title: mechanics.driveSystem.fuelLeakFromFuelSystem.title!,
      flagText: mechanics.driveSystem.fuelLeakFromFuelSystem.label,
      variant: mechanics.driveSystem.fuelLeakFromFuelSystem.variant,
      description: mechanics.driveSystem.fuelLeakFromFuelSystem.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.exhaustSystem), {
      title: mechanics.driveSystem.exhaustSystem.title!,
      flagText: mechanics.driveSystem.exhaustSystem.label,
      variant: mechanics.driveSystem.exhaustSystem.variant,
      description: mechanics.driveSystem.exhaustSystem.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.exhaustSystemLeak), {
      title: mechanics.driveSystem.exhaustSystemLeak.title!,
      flagText: mechanics.driveSystem.exhaustSystemLeak.label,
      variant: mechanics.driveSystem.exhaustSystemLeak.variant,
      description: mechanics.driveSystem.exhaustSystemLeak.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.exhaustSystemCorrosion), {
      title: mechanics.driveSystem.exhaustSystemCorrosion.title!,
      flagText: mechanics.driveSystem.exhaustSystemCorrosion.label,
      variant: mechanics.driveSystem.exhaustSystemCorrosion.variant,
      description: mechanics.driveSystem.exhaustSystemCorrosion.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.clutch), {
      title: mechanics.driveSystem.clutch.title!,
      flagText: mechanics.driveSystem.clutch.label,
      variant: mechanics.driveSystem.clutch.variant,
      description: mechanics.driveSystem.clutch.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.battery), {
      title: mechanics.driveSystem.battery.title!,
      flagText: mechanics.driveSystem.battery.label,
      variant: mechanics.driveSystem.battery.variant,
      description: mechanics.driveSystem.battery.description,
    })
    .when(isFeatureVisible(mechanics.driveSystem.DPFCondition), {
      title: mechanics.driveSystem.DPFCondition.title!,
      flagText: mechanics.driveSystem.DPFCondition.label,
      variant: mechanics.driveSystem.DPFCondition.variant,
      description: mechanics.driveSystem.DPFCondition.description,
    });

  const chassisFeatures = buildArray<ListFeaturesItemProps>()
    .when(isFeatureVisible(mechanics.chassis.status), {
      title: mechanics.chassis.status.title!,
      flagText: mechanics.chassis.status.label,
      variant: mechanics.chassis.status.variant,
      description: mechanics.chassis.status.description,
    })
    .when(isFeatureVisible(mechanics.chassis.chassisPlay), {
      title: mechanics.chassis.chassisPlay.title!,
      flagText: mechanics.chassis.chassisPlay.label,
      variant: mechanics.chassis.chassisPlay.variant,
      description: mechanics.chassis.chassisPlay.description,
    })
    .when(isFeatureVisible(mechanics.chassis.shockAbsorbers), {
      title: mechanics.chassis.shockAbsorbers.title!,
      flagText: mechanics.chassis.shockAbsorbers.label,
      variant: mechanics.chassis.shockAbsorbers.variant,
      description: mechanics.chassis.shockAbsorbers.description,
    })
    .when(isFeatureVisible(mechanics.chassis.vehicleAxel), {
      title: mechanics.chassis.vehicleAxel.title!,
      flagText: mechanics.chassis.vehicleAxel.label,
      variant: mechanics.chassis.vehicleAxel.variant,
      description: mechanics.chassis.vehicleAxel.description,
    });

  const electricalSystemFeatures = buildArray<ListFeaturesItemProps>()
    .when(isFeatureVisible(mechanics.electricalSystem.standardHeating), {
      title: mechanics.electricalSystem.standardHeating.title!,
      flagText: mechanics.electricalSystem.standardHeating.label,
      variant: mechanics.electricalSystem.standardHeating.variant,
      description: mechanics.electricalSystem.standardHeating.description,
    })
    .when(isFeatureVisible(mechanics.electricalSystem.vehicleLightning), {
      title: mechanics.electricalSystem.vehicleLightning.title!,
      flagText: mechanics.electricalSystem.vehicleLightning.label,
      variant: mechanics.electricalSystem.vehicleLightning.variant,
      description: mechanics.electricalSystem.vehicleLightning.description,
    })
    .when(isFeatureVisible(mechanics.electricalSystem.warningLightsCheck), {
      title: mechanics.electricalSystem.warningLightsCheck.title!,
      flagText: mechanics.electricalSystem.warningLightsCheck.label,
      variant: mechanics.electricalSystem.warningLightsCheck.variant,
      description: mechanics.electricalSystem.warningLightsCheck.description,
    });

  const diagnosticsFeatures = buildArray<ListFeaturesItemProps>().when(
    isFeatureVisible(mechanics.diagnostics.status),
    {
      title: mechanics.diagnostics.status.title!,
      flagText: mechanics.diagnostics.status.label,
      variant: mechanics.diagnostics.status.variant,
      description: mechanics.diagnostics.status.description,
    }
  );

  const bodyConditionFeatures = buildArray<ListFeaturesItemProps>()
    .when(isFeatureVisible(mechanics.bodyCondition.status), {
      title: mechanics.bodyCondition.status.title!,
      flagText: mechanics.bodyCondition.status.label,
      variant: mechanics.bodyCondition.status.variant,
      description: mechanics.bodyCondition.status.description,
    })
    .when(isFeatureVisible(mechanics.bodyCondition.tracesOfAccidentRepair), {
      title: mechanics.bodyCondition.tracesOfAccidentRepair.title!,
      flagText: mechanics.bodyCondition.tracesOfAccidentRepair.label,
      variant: mechanics.bodyCondition.tracesOfAccidentRepair.variant,
      description: mechanics.bodyCondition.tracesOfAccidentRepair.description,
    })
    .when(isFeatureVisible(mechanics.bodyCondition.bodyCorrosion), {
      title: mechanics.bodyCondition.bodyCorrosion.title!,
      flagText: mechanics.bodyCondition.bodyCorrosion.label,
      variant: mechanics.bodyCondition.bodyCorrosion.variant,
      description: mechanics.bodyCondition.bodyCorrosion.description,
    });

  const features = [
    ...bodyConditionFeatures,
    ...diagnosticsFeatures,
    ...electricalSystemFeatures,
    ...chassisFeatures,
    ...driveSystemFeatures,
    ...brakeSystemFeatures,
  ];
  const isNotTested = all((f) => f.variant === 'neutral', features);
  const isDamaged = any((f) => f.variant === 'damaged', features);
  const countDamages: (object: object) => number = pipe(
    values,
    filter(propEq('damaged', 'variant')),
    length
  );

  return (
    <SectionPrint
      icon="mechanics"
      heading={i18n.t('mechanicsHeader')}
      flag={match<[boolean, boolean], Nullish | SectionStateFlagProps>([isNotTested, isDamaged])
        .with(
          [false, true],
          always({severity: 'damage', text: getRecordsTranslate(countDamages(features))})
        )
        .with([true, false], always(null))
        .otherwise(always({severity: 'good', text: i18n.t('sectionState.good')}))}
    >
      <VStack spacing={6}>
        <Show when={isNotNilOrEmpty(brakeSystemFeatures)}>
          <Heading size={2}>{i18n.t('brakeSystem')}</Heading>
          <ListFeatures columns={2} spacing={3} features={brakeSystemFeatures} />
        </Show>
        <Heading size={2}>{i18n.t('brakeDisc')}</Heading>
        <ListFeatures
          columns={2}
          spacing={3}
          features={[
            {
              title: i18n.t('valueLF'),
              variant: mechanics.brake.brakeDiscLF.variant,
              flagTexts: mechanics.brake.brakeDiscLF.flagTexts,
              description: mechanics.brake.brakeDiscLF.description,
            },
            {
              title: i18n.t('valueRF'),
              variant: mechanics.brake.brakeDiscRF.variant,
              flagTexts: mechanics.brake.brakeDiscRF.flagTexts,
              description: mechanics.brake.brakeDiscRF.description,
            },
            {
              title: i18n.t('valueLR'),
              variant: mechanics.brake.brakeDiscLR.variant,
              flagTexts: mechanics.brake.brakeDiscLR.flagTexts,
              description: mechanics.brake.brakeDiscLR.description,
            },
            {
              title: i18n.t('valueRR'),
              variant: mechanics.brake.brakeDiscRR.variant,
              flagTexts: mechanics.brake.brakeDiscRR.flagTexts,
              description: mechanics.brake.brakeDiscRR.description,
            },
          ]}
        />
        <Heading size={2}>{i18n.t('brakePad')}</Heading>
        <ListFeatures
          columns={2}
          spacing={3}
          features={[
            {
              title: i18n.t('valueLF'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakePad.LF.value,
                    unit: mechanics.brake.brakePad.LF.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
            {
              title: i18n.t('valueRF'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakePad.RF.value,
                    unit: mechanics.brake.brakePad.RF.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
            {
              title: i18n.t('valueLR'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakePad.LR.value,
                    unit: mechanics.brake.brakePad.LR.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
            {
              title: i18n.t('valueRR'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakePad.RR.value,
                    unit: mechanics.brake.brakePad.RR.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
          ]}
        />
        <Heading size={2}>{i18n.t('brakeLiningWear')}</Heading>
        <ListFeatures
          columns={2}
          spacing={3}
          features={[
            {
              title: i18n.t('valueLF'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakeLiningWear.LF.value,
                    unit: mechanics.brake.brakeLiningWear.LF.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
            {
              title: i18n.t('valueLR'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakeLiningWear.LR.value,
                    unit: mechanics.brake.brakeLiningWear.LR.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
            {
              title: i18n.t('valueRF'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakeLiningWear.RF.value,
                    unit: mechanics.brake.brakeLiningWear.RF.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
            {
              title: i18n.t('valueRR'),
              value:
                getValueWithUnit({
                  auditValue: {
                    value: mechanics.brake.brakeLiningWear.RR.value,
                    unit: mechanics.brake.brakeLiningWear.RR.unit,
                  },
                }) ?? EMPTY_PLACEHOLDER,
              variant: 'neutral',
            },
          ]}
        />
        <Show when={isNotNilOrEmpty(driveSystemFeatures)}>
          <Heading size={2}>{i18n.t('driveSystem')}</Heading>
          <ListFeatures columns={2} spacing={3} features={driveSystemFeatures} />
        </Show>

        <Show when={isNotNilOrEmpty(chassisFeatures)}>
          <Heading size={2}>{i18n.t('chassis')}</Heading>
          <ListFeatures columns={[1, 2, 2, 2]} spacing={[3, 6, 6, 10]} features={chassisFeatures} />
        </Show>
        <Show when={isNotNilOrEmpty(electricalSystemFeatures)}>
          <Heading size={2}>{i18n.t('electricalSystem')}</Heading>
          <ListFeatures columns={2} spacing={3} features={electricalSystemFeatures} />
        </Show>
        <Show when={isNotNilOrEmpty(diagnosticsFeatures)}>
          <Heading size={2}>{i18n.t('diagnostics')}</Heading>
          <ListFeatures columns={2} spacing={3} features={diagnosticsFeatures} />
        </Show>
        <Show when={isNotNilOrEmpty(bodyConditionFeatures)}>
          <Heading size={2}>{i18n.t('bodyCondition')}</Heading>
          <ListFeatures columns={2} spacing={3} features={bodyConditionFeatures} />
        </Show>
        <Show when={isNotNilOrEmpty(mechanicSlideshow)}>
          <CarouselPrint data={mechanicSlideshow} />
        </Show>
      </VStack>
    </SectionPrint>
  );
}

type Feature = {
  title: string | Nullish;
  isMandatory: boolean | Nullish;
};

const isFeatureVisible = (feature: Feature) =>
  isNonEmptyString(feature.title) ||
  (isNonEmptyString(feature.title) && isTrue(feature.isMandatory));
