import {StrictMode} from 'react';
import * as ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {App} from './App';
import {MainBoundary} from './components/MainBoundary/MainBoundary';
import {gtmInit} from './utils/gtmInit';

gtmInit();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <MainBoundary>
        <App />
      </MainBoundary>
    </BrowserRouter>
  </StrictMode>
);
