import {css} from 'styled-components';

export function PageBrakeBefore() {
  return (
    <div
      aria-hidden
      css={css`
        page-break-before: always;
      `}
    />
  );
}
