import {Hide, Show, Space, useResponsivePropValue, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {all, always, any, isNil, isNotNil, reject} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {Section} from '../../components/Section/Section';
import {SectionStateFlagProps} from '../../components/SectionStateFlag/SectionStateFlag';
import {Separator} from '../../components/Separator/Separator';
import {WheelTireDataBundle} from '../../hooks/useGetDataForWheelsAndTires';
import i18n from '../../i18n/i18n';
import {SlideImage} from '../../types/SlideImage';
import {getIfIsFeatureHidden} from '../../utils/getIfIsFeatureHidden';
import {SetHeadline} from './components/SetHeadline';
import {WheelCard} from './components/WheelCard';

type WheelsAndTiresProps = Omit<WheelTireDataBundle, 'sectionConfig'>;
export function WheelsAndTires({wheels, assignee, photos}: WheelsAndTiresProps) {
  const [wheelsAndTiresLightboxControls, {onOpenBySlide}] = useLightbox('wheelsAndTires');
  const cardSpacing = useResponsivePropValue([1, 2, 2, 4]);
  const theme = useTheme();
  if (!wheels) {
    return null;
  }

  const sets = reject(isNilOrEmpty)([
    wheels.firstSet.LEFT_FRONT.state,
    wheels.firstSet.RIGHT_FRONT.state,
    wheels.firstSet.LEFT_REAR.state,
    wheels.firstSet.RIGHT_REAR.state,
    wheels.completeSecondSet?.LEFT_FRONT?.state,
    wheels.completeSecondSet?.RIGHT_FRONT?.state,
    wheels.completeSecondSet?.LEFT_REAR?.state,
    wheels.completeSecondSet?.RIGHT_REAR?.state,
    wheels.rimsOnlySecondSet?.LEFT_FRONT?.state,
    wheels.rimsOnlySecondSet?.RIGHT_FRONT?.state,
    wheels.rimsOnlySecondSet?.LEFT_REAR?.state,
    wheels.rimsOnlySecondSet?.RIGHT_REAR?.state,
  ]);

  const isNotTested = all((state) => state?.severity === 'neutral', sets);
  const isDamaged = any((state) => state?.severity === 'damaged', sets);

  const onPreviewClick = (slide: SlideImage) => {
    onOpenBySlide(slide);
  };

  return (
    <Section
      id="WHEELS_AND_TIRES"
      icon="modules/pneu_hotel"
      heading={i18n.t('wheelsAndTiresHeader')}
      header={
        assignee?.name && isNotNilOrEmpty(wheels.generalComment) ? (
          <CommentMechanic name={assignee.name} comment={wheels.generalComment} />
        ) : null
      }
      flag={match<[boolean, boolean], Nullish | SectionStateFlagProps>([isNotTested, isDamaged])
        .with([false, true], always({severity: 'damage', text: i18n.t('sectionState.damaged')}))
        .with([true, false], always(null))
        .otherwise(always({severity: 'good', text: i18n.t('sectionState.good')}))}
      content={
        <VStack spacing={[3, 6, 6, 10]}>
          <Lightbox
            data-testid="wheelsAndTires"
            controls={wheelsAndTiresLightboxControls}
            images={photos}
          />
          <SetHeadline
            label={i18n.t('firstSet')}
            state={wheels.firstSet.isDimensionCorresponds ? i18n.t('isDimensionCorresponds') : null}
          />

          <div
            css={css`
              columns: ${cardSpacing};
              column-gap: ${theme.getSize(4)};
            `}
          >
            <WheelCard
              position={i18n.t('leftFront')}
              state={wheels.firstSet.LEFT_FRONT.state}
              comment={wheels.firstSet.LEFT_FRONT.comment}
              slideImage={wheels.firstSet.LEFT_FRONT.slideImage}
              discType={wheels.firstSet.LEFT_FRONT.discType}
              diameter={wheels.firstSet.LEFT_FRONT.diameter}
              tire={wheels.firstSet.LEFT_FRONT.tire}
              depth={wheels.firstSet.LEFT_FRONT.depth}
              width={wheels.firstSet.LEFT_FRONT.width}
              height={wheels.firstSet.LEFT_FRONT.height}
              onPreviewClick={onPreviewClick}
            />
            <Space vertical={[4, 4, 4, 0]} />
            <WheelCard
              position={i18n.t('leftRear')}
              state={wheels.firstSet.RIGHT_FRONT.state}
              comment={wheels.firstSet.RIGHT_FRONT.comment}
              slideImage={wheels.firstSet.RIGHT_FRONT.slideImage}
              discType={wheels.firstSet.RIGHT_FRONT.discType}
              diameter={wheels.firstSet.RIGHT_FRONT.diameter}
              tire={wheels.firstSet.RIGHT_FRONT.tire}
              depth={wheels.firstSet.RIGHT_FRONT.depth}
              width={wheels.firstSet.RIGHT_FRONT.width}
              height={wheels.firstSet.RIGHT_FRONT.height}
              onPreviewClick={onPreviewClick}
            />

            <Space vertical={[4, 0, 0, 0]} />
            <WheelCard
              position={i18n.t('rightRear')}
              state={wheels.firstSet.LEFT_REAR.state}
              discType={wheels.firstSet.LEFT_REAR.discType}
              diameter={wheels.firstSet.LEFT_REAR.diameter}
              tire={wheels.firstSet.LEFT_REAR.tire}
              depth={wheels.firstSet.LEFT_REAR.depth}
              width={wheels.firstSet.LEFT_REAR.width}
              height={wheels.firstSet.LEFT_REAR.height}
              slideImage={wheels.firstSet.LEFT_REAR.slideImage}
              comment={wheels.firstSet.LEFT_REAR.comment}
              onPreviewClick={onPreviewClick}
            />

            <Space vertical={[4, 4, 4, 0]} />
            <WheelCard
              position={i18n.t('rightFront')}
              state={wheels.firstSet.RIGHT_REAR.state}
              discType={wheels.firstSet.RIGHT_REAR.discType}
              diameter={wheels.firstSet.RIGHT_REAR.diameter}
              tire={wheels.firstSet.RIGHT_REAR.tire}
              depth={wheels.firstSet.RIGHT_REAR.depth}
              width={wheels.firstSet.RIGHT_REAR.width}
              height={wheels.firstSet.RIGHT_REAR.height}
              slideImage={wheels.firstSet.RIGHT_REAR.slideImage}
              comment={wheels.firstSet.RIGHT_REAR.comment}
              onPreviewClick={onPreviewClick}
            />
          </div>

          <ListFeatures
            columns={[1, 2, 2, 2]}
            spacing={[3, 6, 6, 10]}
            features={[
              {
                title: i18n.t('tireReplacement'),
                variant: 'damaged',
                hidden: getIfIsFeatureHidden(wheels.features?.tireReplacement),
              },
              {
                title: i18n.t('spareWheel'),
                variant: 'ready',
                hidden: getIfIsFeatureHidden(wheels.features?.spareWheel),
              },
              {
                title: i18n.t('safetyNuts'),
                variant: 'ready',
                hidden: getIfIsFeatureHidden(wheels.features?.safetyNuts),
              },

              {
                title: i18n.t('safetyAdapter'),
                variant: 'ready',

                hidden: getIfIsFeatureHidden(wheels.features?.adapterForSafety),
              },
            ]}
          />

          <Hide when={isNil(wheels.secondSetStatus.statusLabel)}>
            <Separator orientation="horizontal" />

            <SetHeadline label={i18n.t('secondSet')} state={wheels.secondSetStatus.statusLabel} />

            <div
              css={css`
                columns: ${cardSpacing};
                column-gap: ${theme.getSize(4)};
              `}
            >
              <Show when={isNotNil(wheels?.completeSecondSet)}>
                <WheelCard
                  position={i18n.t('leftFront')}
                  state={wheels.completeSecondSet?.LEFT_FRONT?.state}
                  comment={wheels.completeSecondSet?.LEFT_FRONT?.comment}
                  slideImage={wheels.completeSecondSet?.LEFT_FRONT?.slideImage}
                  discType={wheels.completeSecondSet?.LEFT_FRONT?.discType}
                  diameter={wheels.completeSecondSet?.LEFT_FRONT?.diameter}
                  tire={wheels.completeSecondSet?.LEFT_FRONT?.tire}
                  depth={wheels.completeSecondSet?.LEFT_FRONT?.depth}
                  width={wheels.completeSecondSet?.LEFT_FRONT?.width}
                  height={wheels.completeSecondSet?.LEFT_FRONT?.height}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 4, 4, 0]} />
                <WheelCard
                  position={i18n.t('leftRear')}
                  state={wheels.completeSecondSet?.RIGHT_FRONT.state}
                  comment={wheels.completeSecondSet?.RIGHT_FRONT.comment}
                  slideImage={wheels.completeSecondSet?.RIGHT_FRONT?.slideImage}
                  discType={wheels.completeSecondSet?.RIGHT_FRONT?.discType}
                  diameter={wheels.completeSecondSet?.RIGHT_FRONT?.diameter}
                  tire={wheels.completeSecondSet?.RIGHT_FRONT?.tire}
                  depth={wheels.completeSecondSet?.RIGHT_FRONT?.depth}
                  width={wheels.completeSecondSet?.RIGHT_FRONT?.width}
                  height={wheels.completeSecondSet?.RIGHT_FRONT?.height}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 0, 0, 0]} />
                <WheelCard
                  position={i18n.t('rightRear')}
                  state={wheels.completeSecondSet?.LEFT_REAR.state}
                  discType={wheels.completeSecondSet?.LEFT_REAR?.discType}
                  diameter={wheels.completeSecondSet?.LEFT_REAR?.diameter}
                  tire={wheels.completeSecondSet?.LEFT_REAR?.tire}
                  depth={wheels.completeSecondSet?.LEFT_REAR?.depth}
                  width={wheels.completeSecondSet?.LEFT_REAR?.width}
                  height={wheels.completeSecondSet?.LEFT_REAR?.height}
                  slideImage={wheels.completeSecondSet?.LEFT_REAR?.slideImage}
                  comment={wheels.completeSecondSet?.LEFT_REAR.comment}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 4, 4, 0]} />
                <WheelCard
                  position={i18n.t('rightFront')}
                  state={wheels.completeSecondSet?.RIGHT_REAR.state}
                  discType={wheels.completeSecondSet?.RIGHT_REAR?.discType}
                  diameter={wheels.completeSecondSet?.RIGHT_REAR?.diameter}
                  tire={wheels.completeSecondSet?.RIGHT_REAR?.tire}
                  depth={wheels.completeSecondSet?.RIGHT_REAR?.depth}
                  width={wheels.completeSecondSet?.RIGHT_REAR?.width}
                  height={wheels.completeSecondSet?.RIGHT_REAR?.height}
                  slideImage={wheels.completeSecondSet?.RIGHT_REAR?.slideImage}
                  comment={wheels.completeSecondSet?.RIGHT_REAR.comment}
                  onPreviewClick={onPreviewClick}
                />
              </Show>
              <Show when={isNotNil(wheels?.rimsOnlySecondSet)}>
                <WheelCard
                  shouldDisplayOnlyRims
                  position={i18n.t('leftFront')}
                  state={wheels.rimsOnlySecondSet?.LEFT_FRONT?.state}
                  comment={wheels.rimsOnlySecondSet?.LEFT_FRONT?.comment}
                  slideImage={wheels.rimsOnlySecondSet?.LEFT_FRONT?.slideImage}
                  discType={wheels.rimsOnlySecondSet?.LEFT_FRONT?.discType}
                  diameter={wheels.rimsOnlySecondSet?.LEFT_FRONT?.diameter}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 4, 4, 0]} />
                <WheelCard
                  shouldDisplayOnlyRims
                  position={i18n.t('leftRear')}
                  state={wheels.rimsOnlySecondSet?.RIGHT_FRONT.state}
                  comment={wheels.rimsOnlySecondSet?.RIGHT_FRONT.comment}
                  slideImage={wheels.rimsOnlySecondSet?.RIGHT_FRONT?.slideImage}
                  discType={wheels.rimsOnlySecondSet?.RIGHT_FRONT?.discType}
                  diameter={wheels.rimsOnlySecondSet?.RIGHT_FRONT?.diameter}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 0, 0, 0]} />

                <WheelCard
                  shouldDisplayOnlyRims
                  position={i18n.t('leftRear')}
                  state={wheels.rimsOnlySecondSet?.RIGHT_REAR.state}
                  discType={wheels.rimsOnlySecondSet?.RIGHT_REAR?.discType}
                  diameter={wheels.rimsOnlySecondSet?.RIGHT_REAR?.diameter}
                  slideImage={wheels.rimsOnlySecondSet?.LEFT_REAR?.slideImage}
                  comment={wheels.rimsOnlySecondSet?.RIGHT_REAR.comment}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 4, 4, 0]} />
                <WheelCard
                  shouldDisplayOnlyRims
                  position={i18n.t('rightRear')}
                  state={wheels.rimsOnlySecondSet?.LEFT_REAR.state}
                  discType={wheels.rimsOnlySecondSet?.LEFT_REAR?.discType}
                  diameter={wheels.rimsOnlySecondSet?.LEFT_REAR?.diameter}
                  slideImage={wheels.rimsOnlySecondSet?.RIGHT_REAR?.slideImage}
                  comment={wheels.rimsOnlySecondSet?.LEFT_REAR.comment}
                  onPreviewClick={onPreviewClick}
                />
              </Show>
              <Show when={isNotNil(wheels?.tiresOnlySecondSet)}>
                <WheelCard
                  shouldDisplayOnlyWheels
                  position={i18n.t('leftFront')}
                  comment={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.comment}
                  slideImage={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.slideImage}
                  tire={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.tire}
                  depth={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.depth}
                  width={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.width}
                  height={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.height}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 4, 4, 0]} />
                <WheelCard
                  shouldDisplayOnlyWheels
                  position={i18n.t('leftRear')}
                  comment={wheels?.tiresOnlySecondSet?.RIGHT_FRONT.comment}
                  slideImage={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.slideImage}
                  tire={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.tire}
                  depth={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.depth}
                  width={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.width}
                  height={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.height}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 0, 0, 0]} />
                <WheelCard
                  position={i18n.t('rightRear')}
                  shouldDisplayOnlyWheels
                  tire={wheels?.tiresOnlySecondSet?.LEFT_REAR?.tire}
                  depth={wheels?.tiresOnlySecondSet?.LEFT_REAR?.depth}
                  width={wheels?.tiresOnlySecondSet?.LEFT_REAR?.width}
                  height={wheels?.tiresOnlySecondSet?.LEFT_REAR?.height}
                  slideImage={wheels?.tiresOnlySecondSet?.LEFT_REAR?.slideImage}
                  comment={wheels?.tiresOnlySecondSet?.LEFT_REAR.comment}
                  onPreviewClick={onPreviewClick}
                />
                <Space vertical={[4, 4, 4, 0]} />
                <WheelCard
                  position={i18n.t('rightFront')}
                  shouldDisplayOnlyWheels
                  tire={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.tire}
                  depth={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.depth}
                  width={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.width}
                  height={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.height}
                  slideImage={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.slideImage}
                  comment={wheels?.tiresOnlySecondSet?.RIGHT_REAR.comment}
                  onPreviewClick={onPreviewClick}
                />
              </Show>
            </div>
          </Hide>
        </VStack>
      }
    />
  );
}
