export const FLAGSMITH_URL = 'https://flagsmith.eag.guru/api/v1/';

export const APPLICATION_NAME = 'Digital Certificate';

export const browserStorageKey = {
  LAST_KNOWN_LANGUAGE: 'lastKnownLanguage',
};

export const notFoundRoutePath = 'not-found';
export const notFoundRoute = `/${notFoundRoutePath}`;
