import {Box, Hide, HStack, Show, Text} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {EMPTY_PLACEHOLDER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {FeatureStateFlag, FeatureStateFlagProps} from '../FeatureStateFlag/FeatureStateFlag';

export interface AttributesItemProps extends TestIdProps {
  label: string;
  text?: string | Nullish;
  flag?: FeatureStateFlagProps;
}

export function AttributesItem(props: AttributesItemProps) {
  const hasFlag = isNotNil(props.flag);

  return (
    <Box paddingVertical={[2, 3, 3, 3]} paddingHorizontal={[3, 4, 6, 6]}>
      <HStack spacing={3} justify="space-between">
        <Text color="secondary" data-testid={suffixTestId('label', props)}>
          {props?.label}
        </Text>
        <Hide when={hasFlag}>
          <Text data-testid={suffixTestId('value', props)}>{props?.text ?? EMPTY_PLACEHOLDER}</Text>
        </Hide>
        <Show when={hasFlag}>
          <FeatureStateFlag {...props.flag!} data-testid={suffixTestId('flag', props)} />
        </Show>
      </HStack>
    </Box>
  );
}
