import {DataUtilParams} from '../../../types/DataUtilParams';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';
import {getAuditFieldValueWithUnit} from '../../../utils/getAuditFieldValueWithUnit';
import {getRelatedActionsByUniqueKeys} from '../../../utils/getRelatedActionsByUniqueKeys';

export function getExterior(params: DataUtilParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.audit || !params.vehicleAudit.auditStructure) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: 'EXTERIOR',
    categories: params.vehicleAudit.auditStructure.categories,
  });
  if (!relatedActions?.length) {
    return null;
  }

  return {
    comment: getAuditFieldValue({
      key: 'exteriorComment',
      relatedActions,
      auditCategories: params.vehicleAudit?.audit.categories,
    }),
    paintData: {
      backDoor: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataBackDoor',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      leftFrontDoor: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataLFDoor',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      leftBackDoor: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataLRDoor',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      leftFrontFender: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataLFFender',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      leftBackFender: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataLRFender',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      rightFrontDoor: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataRFDoor',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      rightBackDoor: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataRRDoor',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      rightFrontFender: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataRFFender',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      rightBackFender: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataRRFender',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      roof: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataRoof',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
      hood: getAuditFieldValueWithUnit({
        key: 'exteriorPaintDataHood',
        relatedActions,
        auditCategories: params.vehicleAudit?.audit.categories,
      }),
    },
  };
}
