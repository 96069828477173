import {isDefined} from 'shared';

import {ListFeaturesItemProps} from '../components/ListFeaturesItem';

export const getIfIsIndexWithPadding = (
  features: ListFeaturesItemProps[],
  index: number,
  columns?: number
) => {
  const isMoreThenOneColumns = isDefined(columns) ? columns > 1 : false;

  const isLastItemWithPadding = features.length - 1 === index;
  const isMiddleItemWithPadding = features.length / 2 === index + 1;

  if (isMoreThenOneColumns) {
    return isLastItemWithPadding || isMiddleItemWithPadding ? 0 : 4;
  }

  return isLastItemWithPadding ? 0 : 4;
};
