import {Nullish} from 'shared';

import {AlphaCatalogue} from '../api/digitalCertificateApi';

type AlphaCatalogueLabelParams = {
  propertyName: string;
  alphaCatalogue: AlphaCatalogue;
  vehicleType: string | Nullish;
  key: string | Nullish;
};

export const getAlphaCatalogueLabel = (params: AlphaCatalogueLabelParams): string | Nullish =>
  params.alphaCatalogue
    ?.find(
      (property) =>
        property.vehicle_type === params.vehicleType &&
        property.property_name === params.propertyName
    )
    ?.keys?.find((propertyKey) => propertyKey.const_key === params.key)?.labels?.[0]?.label ??
  params.key;
