import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {ComparisonKeyOf} from '../consts/comparisonKeys';
import {getAuditFieldById} from './getAuditFieldById';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';

interface AuditFieldValueWithUnitParams {
  key: ComparisonKeyOf;
  relatedActions: RelatedAction[] | Nullish;
  auditCategories: AuditCategory[];
}

export function getAuditFieldValueWithUnit(params: AuditFieldValueWithUnitParams) {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);
  if (isNil(relatedAction)) {
    return null;
  }
  const auditField = getAuditFieldById(relatedAction?.id, params.auditCategories);
  return {value: auditField?.value ?? null, unit: relatedAction?.unit ?? null};
}
