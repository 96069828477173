import {Device} from 'platform/foundation';

export type Positions = 'right' | 'left' | 'center';

export const getPositionsWithPadding = (
  chosenDamageKey: string,
  device: Device,
  damageModalSides: Record<string, {notebook: Positions; mobile: Positions}>
) => {
  const positionXY: Record<Positions, {x: number; y: number}> = {
    right: {x: 3, y: 15},
    left: {x: 2, y: -160},
    center: {x: 3, y: -70},
  };
  const damageSide = damageModalSides[chosenDamageKey] || {
    notebook: 'right',
    mobile: 'right',
  };
  const damageSideByDevice = device === 'mobile' ? damageSide.mobile : damageSide.notebook;
  return positionXY[damageSideByDevice];
};
