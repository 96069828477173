import {
  Box,
  Center,
  getValueByDevice,
  Heading,
  Hide,
  HStack,
  Icon,
  Show,
  Stack,
  useDevice,
  VStack,
} from 'platform/foundation';

import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

import {dec, inc, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {i18n} from '../../i18n/i18n';
import {SlideImage} from '../../types/SlideImage';
import {CarouselSlideshow} from '../Carousel/components/CarouselSlideshow';
import {CarouselHeader} from '../CarouselHeader/CarouselHeader';
import {CommentExpandable} from '../CommentExpandable/CommentExpandable';
import {SectionStateFlag} from '../SectionStateFlag/SectionStateFlag';
import {DamageCarouselLocation} from './components/DamageCarouselLocation';

type DamageCarouselSlide = {
  title: string | Nullish;
  comment: string | Nullish;
  location: SlideImage | Nullish;
  value: string | Nullish;
  slides: SlideImage[];
  point: string | Nullish;
  id: string;
};

interface DamageCarouselProps {
  data: DamageCarouselSlide[] | Nullish;
  chosenDamageIndex: number;
}
export interface DamageCarouselScrollableRef {
  scrollIntoView: () => void;
}

export const DamageCarousel = forwardRef<DamageCarouselScrollableRef, DamageCarouselProps>(
  (props: DamageCarouselProps, ref) => {
    const [page, setPage] = useState(0);
    const carouselRef = useRef<HTMLDivElement>(null);
    const device = useDevice();
    const scrollIntoView = () => {
      if (carouselRef.current) {
        carouselRef.current?.scrollIntoView({behavior: 'smooth'});
      }
    };
    useImperativeHandle(ref, () => ({
      scrollIntoView,
    }));
    useEffect(() => {
      if (props.chosenDamageIndex >= 0) {
        setPage(props.chosenDamageIndex);
      }
    }, [props.chosenDamageIndex]);

    const onNextClick = (numberOfSlides: number) => {
      if (page === dec(numberOfSlides)) {
        setPage(0);
        return;
      }
      setPage(inc(page));
    };

    const onPreviousClick = (numberOfSlides: number) => {
      if (page === 0) {
        setPage(dec(numberOfSlides));
        return;
      }
      setPage(dec(page));
    };

    if (!props.data?.length) {
      return null;
    }

    return (
      <div ref={carouselRef}>
        <Box paddingVertical={5}>
          <CarouselHeader
            title={i18n.t('damage')}
            slides={props.data}
            activeSlide={page}
            onNextClick={() => onNextClick(props.data!.length)}
            onPreviousClick={() => onPreviousClick(props.data!.length)}
          />
        </Box>

        <Stack
          direction={['column', 'column', 'row', 'row']}
          spacing={[3, 6, 6, 10]}
          align="flex-start"
        >
          <Box flex={1} maxWidth={getValueByDevice(device, 'auto', 'auto', 100, 100)} width="100%">
            <VStack spacing={[3, 6, 6, 10]}>
              <HStack spacing={4} align="center">
                <SectionStateFlag severity="damage" />
                <Heading size={2}>{props.data[page].value}</Heading>
              </HStack>
              <HStack spacing={4} align="center">
                <Icon value="location" size={6} color="accent.base" />
                <Heading size={3} alternative>
                  {props.data[page].title ?? i18n.t('location')}
                </Heading>
              </HStack>

              <Show when={isNotNil(props.data[page].location?.url)}>
                <DamageCarouselLocation
                  lightboxId="damageCarouselLocation"
                  slide={props.data[page].location!}
                  allSlides={props.data[page].slides}
                />
              </Show>

              <Hide onMobile when={isNilOrEmpty(props.data[page]?.comment)}>
                <CommentExpandable comment={props.data[page]?.comment} />
              </Hide>
            </VStack>
          </Box>
          <Box flex={1} width="100%">
            <Center>
              {props.data.map((item, index) => (
                <Show key={item.id} when={index === page && item.slides?.length >= 1}>
                  <CarouselSlideshow
                    lightboxId={item.id}
                    isFilterOfDamageDisabled
                    slides={item.slides}
                    location={item.location}
                  />
                </Show>
              ))}
            </Center>
          </Box>
          <Show onMobile when={isNotNilOrEmpty(props.data[page]?.comment)}>
            <CommentExpandable comment={props.data[page]?.comment} />
          </Show>
        </Stack>
      </div>
    );
  }
);
