import {Box, Heading, HStack, Icon, Show, ThemeIconKey, VStack} from 'platform/foundation';

import {ReactElement} from 'react';

import {isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Nullish, TestIdProps} from 'shared';

import {PageBrakeBefore} from '../PageBrakeBefore/PageBrakeBefore';
import {SectionStateFlag, SectionStateFlagProps} from '../SectionStateFlag/SectionStateFlag';

export interface SectionProps extends TestIdProps {
  heading: string;
  icon?: ThemeIconKey;
  header?: ReactElement | Nullish;
  children?: ReactElement | Nullish;
  flag?: SectionStateFlagProps | Nullish;
  shouldBrakePageAtEnd?: boolean;
}

export function SectionPrint(props: SectionProps) {
  return (
    <>
      <Box width="100%" overflow="hidden" paddingHorizontal={10} paddingVertical={5}>
        <VStack spacing={10} width="100%" height="100%">
          <HStack spacing={7}>
            <Icon value={props.icon} color="accent.base" size={10} />
            <Heading size={1}>{props.heading}</Heading>
            <Show when={isNotNil(props.flag)}>
              <SectionStateFlag {...props.flag!} />
            </Show>
          </HStack>
          <Show when={isNotNil(props.header)}>{props.header}</Show>
          {props.children}
        </VStack>
      </Box>
      <Show when={isTrue(props.shouldBrakePageAtEnd)}>
        <PageBrakeBefore />
      </Show>
    </>
  );
}
