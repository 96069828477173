import {Box, HStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {all} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {parseDate} from 'shared';

import {Attributes} from '../../components/Attributes/Attributes';
import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import {i18n} from '../../i18n/i18n';
import {getTestDrive} from '../../utils/getTestDrive';
import {TestDriveContent} from './components/TestDriveContent';

export function TestDrivePrint() {
  const formatDateTime = useDateTimeFormatter();

  const {vehicleAudit} = useGetDigitalCertificateData();

  const testDrive = getTestDrive(vehicleAudit);

  if (!testDrive) {
    return null;
  }

  const isStatusOkay = all(isNotNilOrEmpty, [
    testDrive.title.testDriveDate,
    testDrive.title.driver,
    testDrive.content.driveStart,
    testDrive.content.driveEnd,
    testDrive.content.distance?.value,
    testDrive.content.maxSpeed?.value,
    testDrive.content.avgSpeed?.value,
  ]);

  return (
    <SectionPrint
      icon="testDrive"
      heading={i18n.t('testDriveHeader')}
      header={
        testDrive.mechanic.name ? (
          <CommentMechanic comment={testDrive.mechanic.comment} name={testDrive.mechanic.name} />
        ) : null
      }
      flag={isStatusOkay ? {severity: 'good', text: i18n.t('sectionState.good')} : null}
    >
      <HStack spacing={6} width="100%">
        <Box flex={1} maxWidth={100} width="100%">
          <Attributes
            data-testid="test-Drive-Attributes"
            rows={[
              {
                label: i18n.t('testDrive.date'),
                text: testDrive.title.testDriveDate
                  ? formatDateTime('dateShort', parseDate(testDrive.title.testDriveDate))
                  : null,
              },
              {label: i18n.t('testDrive.driver'), text: testDrive.title.driver},
            ]}
          />
        </Box>
        <Box flex={1} width="100%">
          <TestDriveContent
            duration={testDrive.content.duration}
            driveStart={testDrive.content.driveStart}
            driveEnd={testDrive.content.driveEnd}
            distance={testDrive.content.distance}
            maxSpeed={testDrive.content.maxSpeed}
            avgSpeed={testDrive.content.avgSpeed}
            weakGpsSignal={testDrive.content.weakGpsSignal}
          />
        </Box>
      </HStack>
    </SectionPrint>
  );
}
