import {Box, HStack, Image, Integer, Stack, Text, VStack} from 'platform/foundation';

import {languageDialogItems} from '../../consts/languageDialogItems';
import {Separator} from '../Separator/Separator';
import {LanguageComponent} from './components/LanguageComponent';

export function Footer() {
  const actualYear = new Date().getFullYear();
  return (
    <footer data-testid="footer">
      <Box width="100%" backgroundColor="fill.white">
        <HStack justify="center">
          <Box
            data-testid="footer"
            width="100%"
            maxWidth={400}
            paddingVertical={[6, 6, 10, 10]}
            paddingHorizontal={[2, 10, 10, 20]}
          >
            <VStack spacing={[4, 6, 10, 10]}>
              <Box paddingHorizontal={[3, 0, 0, 0]}>
                <HStack spacing={4} align="center" justify="space-between">
                  <Image
                    data-testid="footerLogo"
                    width="auto"
                    height={6}
                    src="../../assets/images/logo.svg"
                    isLazy
                  />
                  <Box>
                    <LanguageComponent languages={languageDialogItems} />
                  </Box>
                </HStack>
              </Box>
              <Box width="100%" paddingVertical={4.5 as Integer}>
                <Stack direction="row" spacing={3} align="center" justify="center" wrap>
                  <Box paddingTop={2}>
                    <Text size="xSmall" color="tertiary">
                      Powered by
                    </Text>
                  </Box>
                  <a
                    title="CarAudit"
                    href="https://www.omnetic.com/en/caraudit/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Image
                      data-testid="footerCarAuditLogo"
                      alt="CarAudit"
                      src="../../assets/images/logo-caraudit.svg"
                      height={6}
                      width="auto"
                    />
                  </a>
                </Stack>
              </Box>
              <Separator />
              <Box maxWidth={140}>
                <Text size="xSmall" color="tertiary">
                  {`Copyright © ${actualYear} EAG Group a.s. All rights reserved. This document is property of
                    EAG Group a.s. This document may not be reproduced or transmitted in any form, in
                    whole or in part, without the express written permission of EAG Group a. s.`}
                </Text>
              </Box>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </footer>
  );
}
