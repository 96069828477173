import {getSecondSetStatus} from '../(sections)/WheelsAndTires/utils/getSecondSetStatus';
import {getWheelFeatures} from '../(sections)/WheelsAndTires/utils/getWheelFeatures';
import {getWheelPhotoUrl} from '../(sections)/WheelsAndTires/utils/getWheelPhotoUrl';
import {getWheelState} from '../(sections)/WheelsAndTires/utils/getWheelState';
import {isDimensionCorresponds} from '../(sections)/WheelsAndTires/utils/isDimensionCorresponds';
import {DataUtilParams} from '../types/DataUtilParams';
import {getAdditionalRelatedActionsByUniqueKeys} from './getAdditionalRelatedActionsByUniqueKeys';
import {getAuditFieldLabel} from './getAuditFieldLabel';
import {getAuditFieldValue} from './getAuditFieldValue';
import {getAuditFieldValueWithUnit} from './getAuditFieldValueWithUnit';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';

export function getWheelsAndTires(params: DataUtilParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.audit || !params.vehicleAudit.auditStructure) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: 'WHEELS_AND_TIRES',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  const additinalRelatedActions = getAdditionalRelatedActionsByUniqueKeys({
    parentUniqueKey: 'WHEELS_AND_TIRES',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length) {
    return null;
  }

  const secondSetStatus = getSecondSetStatus(params.vehicleAudit, relatedActions, params.language);

  return {
    generalComment: getAuditFieldValue({
      key: 'wheelsAndTiresGeneralComment',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
    }),
    firstSet: {
      isDimensionCorresponds: isDimensionCorresponds(params.vehicleAudit) === 'true',
      LEFT_FRONT: {
        state: getWheelState({
          key: 'wheelsAndTiresFrontLeftStatusOfRim',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        comment: getAuditFieldValue({
          key: 'wheelsAndTiresFrontLeftComment',
          relatedActions: additinalRelatedActions ?? [],
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        slideImage: getWheelPhotoUrl(
          'wheelsAndTiresFrontLeftPhoto',
          relatedActions,
          params.vehicleAudit.audit.categories
        ),
        discType: getAuditFieldLabel({
          key: 'wheelsAndTiresFrontLeftDisk',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        diameter: getAuditFieldValue({
          key: 'wheelsAndTiresFrontLeftDiameter',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        tire: getAuditFieldLabel({
          key: 'wheelsAndTiresFrontLeftTire',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        depth: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresFrontLeftDepth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        width: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresFrontLeftWidth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        height: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresFrontLeftHeight',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      RIGHT_FRONT: {
        state: getWheelState({
          key: 'wheelsAndTiresFrontRightStatusOfRim',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        comment: getAuditFieldValue({
          key: 'wheelsAndTiresFrontRightComment',
          relatedActions: additinalRelatedActions ?? [],
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        slideImage: getWheelPhotoUrl(
          'wheelsAndTiresFrontRightPhoto',
          relatedActions,
          params.vehicleAudit.audit.categories
        ),
        discType: getAuditFieldLabel({
          key: 'wheelsAndTiresFrontRightDisk',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        diameter: getAuditFieldValue({
          key: 'wheelsAndTiresFrontRightDiameter',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        tire: getAuditFieldLabel({
          key: 'wheelsAndTiresFrontRightTire',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        depth: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresFrontRightDepth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        width: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresFrontRightWidth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        height: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresFrontRightHeight',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      LEFT_REAR: {
        state: getWheelState({
          key: 'wheelsAndTiresRearLeftStatusOfRim',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        comment: getAuditFieldValue({
          key: 'wheelsAndTiresRearLeftComment',
          relatedActions: additinalRelatedActions ?? [],
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        slideImage: getWheelPhotoUrl(
          'wheelsAndTiresRearLeftPhoto',
          relatedActions,
          params.vehicleAudit.audit.categories
        ),
        discType: getAuditFieldLabel({
          key: 'wheelsAndTiresRearLeftDisk',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        diameter: getAuditFieldValue({
          key: 'wheelsAndTiresRearLeftDiameter',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        tire: getAuditFieldLabel({
          key: 'wheelsAndTiresRearLeftTire',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        depth: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresRearLeftDepth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        width: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresRearLeftWidth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        height: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresRearLeftHeight',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      RIGHT_REAR: {
        state: getWheelState({
          key: 'wheelsAndTiresRearRightStatusOfRim',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        comment: getAuditFieldValue({
          key: 'wheelsAndTiresRearRightComment',
          relatedActions: additinalRelatedActions ?? [],
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        slideImage: getWheelPhotoUrl(
          'wheelsAndTiresRearRightPhoto',
          relatedActions,
          params.vehicleAudit.audit.categories
        ),
        discType: getAuditFieldLabel({
          key: 'wheelsAndTiresRearRightDisk',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        diameter: getAuditFieldValue({
          key: 'wheelsAndTiresRearRightDiameter',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        tire: getAuditFieldLabel({
          key: 'wheelsAndTiresRearRightTire',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        depth: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresRearRightDepth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        width: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresRearRightWidth',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
        height: getAuditFieldValueWithUnit({
          key: 'wheelsAndTiresRearRightHeight',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    completeSecondSet:
      secondSetStatus.isSecondSetActive && secondSetStatus.statusValue === 'COMPLETE_SET'
        ? {
            LEFT_FRONT: {
              state: getWheelState({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresCompletedSecondSetFrontLeftPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetFrontLeftHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            RIGHT_FRONT: {
              state: getWheelState({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresCompletedSecondSetFrontRightPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetFrontRightHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            LEFT_REAR: {
              state: getWheelState({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresCompletedSecondSetRearLeftPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetRearLeftHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            RIGHT_REAR: {
              state: getWheelState({
                key: 'wheelsAndTiresCompletedSecondSetRearRightStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetRearRightComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresCompletedSecondSetRearRightPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetRearRightDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresCompletedSecondSetRearRightDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresCompletedSecondSetRearRightTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetRearRightDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetRearRightWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresCompletedSecondSetRearRightHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
          }
        : null,
    rimsOnlySecondSet:
      secondSetStatus.isSecondSetActive && secondSetStatus.statusValue === 'RIMS_ONLY'
        ? {
            LEFT_FRONT: {
              state: getWheelState({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontLeftStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontLeftComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresRimsOnlySecondSetFrontLeftPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontLeftDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontLeftDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            RIGHT_FRONT: {
              state: getWheelState({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontRightStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontRightComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresRimsOnlySecondSetFrontRightPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontRightDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetFrontRightDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            LEFT_REAR: {
              state: getWheelState({
                key: 'wheelsAndTiresRimsOnlySecondSetRearLeftStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetRearLeftComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresRimsOnlySecondSetRearLeftPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresRimsOnlySecondSetRearLeftDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetRearLeftDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            RIGHT_REAR: {
              state: getWheelState({
                key: 'wheelsAndTiresRimsOnlySecondSetRearRightStatusOfRim',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetRearRightComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresRimsOnlySecondSetRearRightPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              discType: getAuditFieldLabel({
                key: 'wheelsAndTiresRimsOnlySecondSetRearRightDisk',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              diameter: getAuditFieldValue({
                key: 'wheelsAndTiresRimsOnlySecondSetRearRightDiameter',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
          }
        : null,
    tiresOnlySecondSet:
      secondSetStatus.isSecondSetActive && secondSetStatus.statusValue === 'TIRES_ONLY'
        ? {
            LEFT_FRONT: {
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontLeftComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresTiresOnlySecondSetFrontLeftPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontLeftTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontLeftDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontLeftWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontLeftHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            RIGHT_FRONT: {
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontRightComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresTiresOnlySecondSetFrontRightPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontRightTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontRightDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontRightWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetFrontRightHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            LEFT_REAR: {
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresTiresOnlySecondSetRearLeftComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresTiresOnlySecondSetRearLeftPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresTiresOnlySecondSetRearLeftTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetRearLeftDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetRearLeftWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetRearLeftHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
            RIGHT_REAR: {
              comment: getAuditFieldValue({
                key: 'wheelsAndTiresTiresOnlySecondSetRearRightComment',
                relatedActions: additinalRelatedActions ?? [],
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              slideImage: getWheelPhotoUrl(
                'wheelsAndTiresTiresOnlySecondSetRearRightPhoto',
                relatedActions,
                params.vehicleAudit.audit.categories
              ),
              tire: getAuditFieldLabel({
                key: 'wheelsAndTiresTiresOnlySecondSetRearRightTire',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
                language: params.language,
              }),
              depth: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetRearRightDepth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              width: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetRearRightWidth',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
              height: getAuditFieldValueWithUnit({
                key: 'wheelsAndTiresTiresOnlySecondSetRearRightHeight',
                relatedActions,
                auditCategories: params.vehicleAudit.audit.categories,
              }),
            },
          }
        : null,
    secondSetStatus,
    features: getWheelFeatures(relatedActions, params.vehicleAudit.audit.categories),
  };
}
