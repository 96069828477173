import {Box, Heading, HStack, Image, makeImageUrl, Show, Space, VStack} from 'platform/foundation';

import {Fragment} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {i18n} from '../../i18n/i18n';
import {SlideImage} from '../../types/SlideImage';
import {CarouselHeaderPrint} from '../CarouselHeader/CarouselHeader.print';
import {Comment} from '../Comment/CommentExpandable';

export type CarouselSlide = {
  title: string;
  comment: string | Nullish;
  slides: SlideImage[] | Nullish;
};

interface CarouselProps {
  data: CarouselSlide[] | Nullish;
  isFilterOfDamageDisabled?: true;
}

export function CarouselPrint(props: CarouselProps) {
  return (
    <>
      {props.data?.map((carouselSlide, index) => (
        <Fragment key={carouselSlide.title}>
          <VStack spacing={10} width="100%">
            <CarouselHeaderPrint
              title={i18n.t('photoGallery')}
              slideIndex={index}
              numberOfSlides={props.data!.length}
            />
            <HStack spacing={6}>
              <Box flex={1} maxWidth={100} width="100%">
                <Heading size={2}>{carouselSlide.title}</Heading>

                <Show when={isNotNilOrEmpty(carouselSlide.comment)}>
                  <Space vertical={6} />
                  <Comment comment={carouselSlide.comment} />
                </Show>
              </Box>
              <Box flex={1} width="100%">
                <VStack spacing={6}>
                  {carouselSlide.slides?.map((slide) => (
                    <Image
                      key={slide.id}
                      fit="cover"
                      position="center"
                      ratio="4 / 3"
                      width="100%"
                      height={100}
                      src={makeImageUrl(slide.resizeUrl, 100)}
                      alt={slide.title}
                      borderRadius="small"
                    />
                  ))}
                </VStack>
              </Box>
            </HStack>
          </VStack>
        </Fragment>
      ))}
    </>
  );
}
