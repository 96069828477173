import {environment} from '@digital-certificate/environment';

import TagManager from 'react-gtm-module';

import {isNil} from 'ramda';

export function gtmInit() {
  if (isNil(environment.GTM_CONTAINER_ID) || environment.ENV_TYPE !== 'prod') {
    return;
  }
  TagManager.initialize({
    gtmId: environment.GTM_CONTAINER_ID,
  });
}
