import {match} from 'ts-pattern';

import {always, flatten, map, pipe, reject, tryCatch} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {
  ListFeatureFlagItem,
  ListFeaturesItemVariant,
} from '../components/ListFeatures/components/ListFeaturesItem';
import {DataUtilParams} from '../types/DataUtilParams';
import {NestedValuesByKeyParams} from '../types/NestedValuesByKeyParams';
import {getAdditionalRelatedActionsByUniqueKeys} from './getAdditionalRelatedActionsByUniqueKeys';
import {getAuditFieldById} from './getAuditFieldById';
import {getAuditFieldLabel} from './getAuditFieldLabel';
import {getAuditFieldName} from './getAuditFieldName';
import {getAuditFieldValue} from './getAuditFieldValue';
import {getAuditFieldValueWithUnit} from './getAuditFieldValueWithUnit';
import {getCategoriesByUniqueKeys} from './getCategoriesByUniqueKeys';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';
import {isAuditFieldMandatory} from './isAuditFieldMandatory';

export function getMechanics(params: DataUtilParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.audit || !params.vehicleAudit.auditStructure) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: 'MECHANICS',
    categories: params.vehicleAudit.auditStructure.categories,
  });
  const additionalRelatedActions = getAdditionalRelatedActionsByUniqueKeys({
    parentUniqueKey: 'MECHANICS',
    categories: params.vehicleAudit.auditStructure.categories,
  });
  const categories = getCategoriesByUniqueKeys({
    parentUniqueKey: 'MECHANICS',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length) {
    return null;
  }

  return {
    brake: {
      brakeSystemFuctionality: {
        title: getAuditFieldName({
          key: 'mechanicsBrakeSystemFuctionality',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsBrakeSystemFuctionality',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_AMOUNT_OF_KM_DRIVEN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsBrakeSystemFuctionality',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsBrakeSystemFuctionality',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBrakeSystemFuctionalityComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      emergencyParkingBrakeSystem: {
        title: getAuditFieldName({
          key: 'mechanicsEmergencyParkingBrakeSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsEmergencyParkingBrakeSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsEmergencyParkingBrakeSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsEmergencyParkingBrakeSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsEmergencyParkingBrakeSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      brakeFluid: {
        title: getAuditFieldName({
          key: 'mechanicsBrakeFluid',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsBrakeFluid',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('OK', always('ready'))
          .with('RECOMMENDED_REPLACEMENT', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsBrakeFluid',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsBrakeFluid',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBrakeFluidComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      brakeDiscLF: {
        ...getBrakeDisc({
          key: 'mechanicsBrakeDiscLF',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBrakeDiscLFComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      brakeDiscRF: {
        ...getBrakeDisc({
          key: 'mechanicsBrakeDiscRF',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBrakeDiscRFComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      brakeDiscLR: {
        ...getBrakeDisc({
          key: 'mechanicsBrakeDiscLR',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBrakeDiscLRComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      brakeDiscRR: {
        ...getBrakeDisc({
          key: 'mechanicsBrakeDiscRR',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBrakeDiscRRComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      brakePad: {
        LF: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakePadLF',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
        RF: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakePadRF',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
        LR: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakePadLR',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
        RR: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakePadRR',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
      },
      brakeLiningWear: {
        LF: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakeLiningWearLF',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
        LR: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakeLiningWearLR',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
        RF: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakeLiningWearRF',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
        RR: {
          ...getAuditFieldValueWithUnit({
            key: 'mechanicsBrakeLiningWearRR',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          }),
        },
      },
    },
    driveSystem: {
      motor: {
        title: getAuditFieldName({
          key: 'mechanicsMotor',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsMotor',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsMotor',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsMotor',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsMotorComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      engineCondition: {
        title: getAuditFieldName({
          key: 'mechanicsEngineCondition',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsEngineCondition',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('ORIGINAL', always('ready'))
          .with('CHANGE_OF_SOFTWARE', always('repaired'))
          .with('CHANGE_OF_ENGINE', always('repaired'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsEngineCondition',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsEngineCondition',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsEngineConditionComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      vehicleDrivetrain: {
        ...getVehicleDrivetrain({
          key: 'mechanicsVehicleDrivetrain',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsVehicleDrivetrainComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },

      leakageOfOperatingFluidsFromEngine: {
        title: getAuditFieldName({
          key: 'mechanicsLeakageOfOperatingFluidsFromEngine',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsLeakageOfOperatingFluidsFromEngine',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_LEAKAGE', always('ready'))
          .with('SLIGHT_LEAKAGE_OF_OPERATING_FLUIDS', always('damaged'))
          .with('LARGE_LEAKAGE_OF_OPERATING_FLUIDS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsLeakageOfOperatingFluidsFromEngine',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsLeakageOfOperatingFluidsFromEngine',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsLeakageOfOperatingFluidsFromEngineComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },

      steeringAssembly: {
        title: getAuditFieldName({
          key: 'mechanicsSteeringAssembly',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsSteeringAssembly',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsSteeringAssembly',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsSteeringAssembly',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsSteeringAssemblyComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      transmission: {
        title: getAuditFieldName({
          key: 'mechanicsTransmission',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsTransmission',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsTransmission',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsTransmission',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsTransmissionComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      leakageOfOperatingFluidsFromGearbox: {
        title: getAuditFieldName({
          key: 'mechanicsLeakageOfOperatingFluidsFromGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsLeakageOfOperatingFluidsFromGearbox',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_LEAKAGE', always('ready'))
          .with('SLIGHT_LEAKAGE_OF_OPERATING_FLUIDS', always('damaged'))
          .with('LARGE_LEAKAGE_OF_OPERATING_FLUIDS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsLeakageOfOperatingFluidsFromGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsLeakageOfOperatingFluidsFromGearbox',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsLeakageOfOperatingFluidsFromGearboxComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      playInGearbox: {
        title: getAuditFieldName({
          key: 'mechanicsPlayInGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsPlayInGearbox',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_PLAY', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('EXCESSIVE_PLAY', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsPlayInGearbox',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsPlayInGearbox',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsPlayInGearboxComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      otherPartsOfDriveSystem: {
        title: getAuditFieldName({
          key: 'mechanicsOtherPartsOfDriveSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsOtherPartsOfDriveSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsOtherPartsOfDriveSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsOtherPartsOfDriveSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsOtherPartsOfDriveSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      leakageOfOperatingFluidsFromOtherPartsDriveSystem: {
        title: getAuditFieldName({
          key: 'mechanicsLeakageOfOperatingFluidsFromOtherPartsDriveSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsLeakageOfOperatingFluidsFromOtherPartsDriveSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_LEAKAGE', always('ready'))
          .with('SLIGHT_LEAKAGE_OF_OPERATING_FLUIDS', always('damaged'))
          .with('LARGE_LEAKAGE_OF_OPERATING_FLUIDS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsLeakageOfOperatingFluidsFromOtherPartsDriveSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsLeakageOfOperatingFluidsFromOtherPartsDriveSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsLeakageOfOperatingFluidsFromOtherPartsDriveSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      playInOtherParts: {
        title: getAuditFieldName({
          key: 'mechanicsPlayInOtherParts',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsPlayInOtherParts',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_PLAY', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('EXCESSIVE_PLAY', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsPlayInOtherParts',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsPlayInOtherParts',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsPlayInOtherPartsComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      coolingSystem: {
        title: getAuditFieldName({
          key: 'mechanicsCoolingSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsCoolingSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsCoolingSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsCoolingSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsCoolingSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      leakageOfOperatingFluidsFromCoolingSystem: {
        title: getAuditFieldName({
          key: 'mechanicsLeakageOfOperatingFluidsFromCoolingSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsLeakageOfOperatingFluidsFromCoolingSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_LEAKAGE', always('ready'))
          .with('SLIGHT_REFRIGERANT_LEAKAGE', always('damaged'))
          .with('LARGE_REFRIGERANT_LEAKAGE', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsLeakageOfOperatingFluidsFromCoolingSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsLeakageOfOperatingFluidsFromCoolingSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsLeakageOfOperatingFluidsFromCoolingSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      fuelSystem: {
        title: getAuditFieldName({
          key: 'mechanicsFuelSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsFuelSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsFuelSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsFuelSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsFuelSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      fuelLeakFromFuelSystem: {
        title: getAuditFieldName({
          key: 'mechanicsFuelLeakFromFuelSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsFuelLeakFromFuelSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_LEAKAGE', always('ready'))
          .with('SLIGHT_LEAKAGE_OF_OPERATING_FLUIDS', always('damaged'))
          .with('LARGE_FUEL_LEAK', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsFuelLeakFromFuelSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsFuelLeakFromFuelSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsFuelLeakFromFuelSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      exhaustSystem: {
        title: getAuditFieldName({
          key: 'mechanicsExhaustSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsExhaustSystem',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsExhaustSystem',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsExhaustSystem',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsExhaustSystemComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      exhaustSystemLeak: {
        title: getAuditFieldName({
          key: 'mechanicsExhaustSystemLeak',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsExhaustSystemLeak',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_LEAK', always('ready'))
          .with('SLIGHT_LEAK', always('damaged'))
          .with('LARGE_LEAK', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsExhaustSystemLeak',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsExhaustSystemLeak',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsExhaustSystemLeakComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      exhaustSystemCorrosion: {
        title: getAuditFieldName({
          key: 'mechanicsExhaustSystemCorrosion',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsExhaustSystemCorrosion',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_CORROSION', always('ready'))
          .with('SLIGHT_CORROSION', always('damaged'))
          .with('MAJOR_CORROSION', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsExhaustSystemCorrosion',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsExhaustSystemCorrosion',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsExhaustSystemCorrosionComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      clutch: {
        title: getAuditFieldName({
          key: 'mechanicsClutch',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsClutch',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsClutch',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsClutch',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsClutchComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      battery: {
        title: getAuditFieldName({
          key: 'mechanicsBattery',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsBattery',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsBattery',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsBattery',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBatteryComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      DPFCondition: {
        title: getAuditFieldName({
          key: 'mechanicsDPFCondition',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsDPFCondition',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsDPFCondition',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsDPFCondition',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsDPFConditionComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    chassis: {
      status: {
        title: getAuditFieldName({
          key: 'mechanicsChassisStatus',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsChassisStatus',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsChassisStatus',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsChassisStatus',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsChassisStatusComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      chassisPlay: {
        title: getAuditFieldName({
          key: 'mechanicsChassisPlay',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsChassisPlay',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_PLAY', always('ready'))
          .with('SLIGHT_PLAY', always('ready'))
          .with('EXCESSIVE_PLAY', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsChassisPlay',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsChassisPlay',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsChassisPlayComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      shockAbsorbers: {
        title: getAuditFieldName({
          key: 'mechanicsShockAbsorbers',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),

        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsShockAbsorbers',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsShockAbsorbers',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsShockAbsorbers',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsShockAbsorbersComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      vehicleAxel: {
        title: getAuditFieldName({
          key: 'mechanicsVehicleAxel',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsVehicleAxel',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsVehicleAxel',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsVehicleAxel',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsVehicleAxelComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    electricalSystem: {
      standardHeating: {
        title: getAuditFieldName({
          key: 'mechanicsStandardHeating',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsStandardHeating',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FULLY_FUNCTIONAL', always('ready'))
          .with('LOW_HEATING_OUTPUT', always('damaged'))
          .with('NOT_FUNCTIONAL', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsStandardHeating',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsStandardHeating',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsStandardHeatingComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      vehicleLightning: {
        title: getAuditFieldName({
          key: 'mechanicsVehicleLightning',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsVehicleLightning',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('ALL_LIGHTS_WORK', always('ready'))
          .with('SOME_LIGHTS_DO_NOT_WORK', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsVehicleLightning',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsVehicleLightning',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsVehicleLightningComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      warningLightsCheck: {
        title: getAuditFieldName({
          key: 'mechanicsWarningLightsCheck',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsWarningLightsCheck',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('YES', always('ready'))
          .with('NO', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsWarningLightsCheck',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsWarningLightsCheck',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsWarningLightsCheckComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    diagnostics: {
      status: {
        title: getAuditFieldName({
          key: 'mechanicsDiagnostics',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsDiagnostics',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('OK', always('ready'))
          .with('MEMORY_ERRORS', always('damaged'))
          .with('NOT_PERFORMED', always('neutral'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsDiagnostics',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsDiagnostics',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsDiagnosticsComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
    bodyCondition: {
      status: {
        title: getAuditFieldName({
          key: 'mechanicsBodyCondition',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsBodyCondition',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('FLAWLESS', always('ready'))
          .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
          .with('DAMAGE_OR_DEFECTS', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsBodyCondition',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsBodyCondition',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBodyConditionComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      tracesOfAccidentRepair: {
        title: getAuditFieldName({
          key: 'mechanicsTracesOfAccidentRepair',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsTracesOfAccidentRepair',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NOT_FOUND', always('ready'))
          .with('THEY_ARE_PRESENT', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsTracesOfAccidentRepair',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsTracesOfAccidentRepair',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsTracesOfAccidentRepairComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
      bodyCorrosion: {
        title: getAuditFieldName({
          key: 'mechanicsBodyCorrosion',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        variant: match<string | Nullish, ListFeaturesItemVariant>(
          getAuditFieldValue({
            key: 'mechanicsBodyCorrosion',
            relatedActions,
            auditCategories: params.vehicleAudit.audit.categories,
          })
        )
          .with('NO_CORROSION', always('ready'))
          .with('SLIGHT_CORROSION', always('damaged'))
          .with('SEVERE_CORROSION', always('damaged'))
          .otherwise(always('neutral')),
        label: getAuditFieldLabel({
          key: 'mechanicsBodyCorrosion',
          relatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
          language: params.language,
        }),
        isMandatory: isAuditFieldMandatory({
          key: 'mechanicsBodyCorrosion',
          relatedActions,
        }),
        description: getAuditFieldValue({
          key: 'mechanicsBodyCorrosionComment',
          relatedActions: additionalRelatedActions,
          auditCategories: params.vehicleAudit.audit.categories,
        }),
      },
    },
  };
}

const getVehicleDrivetrain = (params: NestedValuesByKeyParams) => {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);

  const auditFieldKey = getAuditFieldValue({
    key: params.key,
    relatedActions: params.relatedActions,
    auditCategories: params.auditCategories,
  });

  const content = relatedAction?.values?.find((v) => v.value === auditFieldKey)?.content;

  const action = content?.relatedActions?.[0];

  const value = getAuditFieldById(action?.id, params.auditCategories)?.value;

  const auditStructureValue = action?.values?.find((v) => v.value === value);
  const flagText = auditStructureValue?.label?.[params.language] ?? null;

  return {
    title: getAuditFieldName({
      key: params.key,
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
      language: params.language,
    }),
    variant: match<string | Nullish, ListFeaturesItemVariant>(value)
      .with('FLAWLESS', always('ready'))
      .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
      .with('DAMAGE_OR_DEFECTS', always('damaged'))
      .otherwise(always('neutral')),
    label: getAuditFieldLabel({
      key: params.key,
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
      language: params.language,
    }),
    isMandatory: isAuditFieldMandatory({
      key: params.key,
      relatedActions: params.relatedActions,
    }),
    flagText,
  };
};

const getBrakeDisc = (params: NestedValuesByKeyParams) => {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);

  const auditValueKey = getAuditFieldValue({
    key: params.key,
    relatedActions: params.relatedActions,
    auditCategories: params.auditCategories,
  });

  const auditStructureValue = relatedAction?.values?.find((v) => v.value === auditValueKey);

  const flagTexts = auditStructureValue?.content?.relatedActions?.map((action) => {
    const value = getAuditFieldById(action?.id, params.auditCategories)?.value;

    const getFieldLabel = (keyValue: string) => {
      const auditStructureValue = action.values?.find((v) => v.value === keyValue);
      return auditStructureValue?.label?.[params.language] ?? null;
    };

    return pipe(
      tryCatch(JSON.parse, always([])),
      reject(isNilOrEmpty),
      map<string, ListFeatureFlagItem>((keyValue: string) => ({
        flagText: getFieldLabel(keyValue),
        variant: 'damaged',
      }))
    )(value ?? '');
  });

  return {
    variant: match<string | Nullish, ListFeaturesItemVariant>(auditValueKey)
      .with('FLAWLESS', always('ready'))
      .with('CORRESPONDS_TO_AGE_AND_RUN_IN', always('ready'))
      .with('DAMAGE_OR_DEFECTS', always('damaged'))
      .otherwise(always('neutral')),
    isMandatory: isAuditFieldMandatory({
      key: params.key,
      relatedActions: params.relatedActions,
    }),
    flagTexts: flatten(flagTexts ?? []),
  };
};
