import {Box, HStack, Image, Text, VStack} from 'platform/foundation';

import {TestIdProps} from 'shared';

import i18n from '../../i18n/i18n';
import {Button} from '../Button/Button';

export type Autotracer = {
  dateTime: string;
  url: string;
};

interface CardCebiaProps extends Autotracer, TestIdProps {}

export function CardCebia(props: CardCebiaProps) {
  return (
    <Box
      backgroundColor="fill.greyLight"
      borderRadius={['small', 'medium', 'large', 'large']}
      padding={[3, 6, 10, 10]}
    >
      <VStack spacing={[3, 6, 6, 6]}>
        <HStack spacing={4} align="center">
          <Image isLazy src="../../assets/images/certification.svg" height={12} width="auto" />
          <Image isLazy src="../../assets/images/cebiaBlack.svg" height={7} width="auto" />
        </HStack>
        <Text size="large" color="tertiary">
          {i18n.t('cebiaTitle')}
        </Text>
        <Text size="xSmall" color="tertiary">
          {props.dateTime}
        </Text>
        <Button
          variant="secondary"
          title={i18n.t('showAutotracer')}
          rightIcon="action/open_in_new"
          onClick={() => window.open(props.url, '_blank', 'noreferrer')}
        />
      </VStack>
    </Box>
  );
}
