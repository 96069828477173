import {GetWheelsReturnType, SetsKeys} from './getWheels';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
export const objectEntriesTyped = <T extends Record<never, never>>(object: T) =>
  Object.entries(object) as Entries<T>;

export const getIfIsDataEmpty = (wheels: GetWheelsReturnType) => {
  if (!wheels) {
    return {sectionsShowState: undefined, isAllDataEmpty: true};
  }

  const sectionsShowState = objectEntriesTyped(wheels).reduce<Record<SetsKeys, boolean>>(
    (prev, [key, value]) => ({
      ...prev,
      [key]:
        value.state?.text ||
        value.comment ||
        value.photos ||
        value.discType ||
        value.diameter ||
        value.tire ||
        value.depth?.value ||
        value.width?.value ||
        value.height?.value,
    }),
    {} as Record<SetsKeys, boolean>
  );

  return {sectionsShowState, isAllDataEmpty: !Object.values(sectionsShowState).some(Boolean)};
};
