import {match} from 'ts-pattern';

import {always} from 'ramda';

import {Nullish} from 'shared';

import {FeatureStateFlagSeverity} from '../../../components/FeatureStateFlag/FeatureStateFlag';
import {NestedValuesByKeyParams} from '../../../types/NestedValuesByKeyParams';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';

export const getWheelState = (params: NestedValuesByKeyParams) => {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);
  const auditValueKey = getAuditFieldValue({
    key: params.key,
    relatedActions: params?.relatedActions,
    auditCategories: params.auditCategories,
  });

  const getFieldLabel = (keyValue: string | Nullish) => {
    const auditStructureValue = relatedAction?.values?.find((v) => v.value === keyValue);
    return auditStructureValue?.label?.[params.language] ?? null;
  };

  const severity = match<string | Nullish, FeatureStateFlagSeverity>(auditValueKey)
    .with('OK', always('ready'))
    .with('DAMAGED', always('damaged'))
    .otherwise(always('neutral'));

  return {
    severity,
    text: getFieldLabel(auditValueKey),
  };
};
