import {AppLanguage} from 'platform/locale';

import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {ComparisonKeyOf} from '../consts/comparisonKeys';
import {getAuditFieldById} from './getAuditFieldById';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';

interface AuditFieldLabelParams {
  key: ComparisonKeyOf;
  language: AppLanguage;
  auditCategories: AuditCategory[];
  relatedActions: RelatedAction[] | Nullish;
}

export function getAuditFieldLabel(params: AuditFieldLabelParams): string | null {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);
  const auditFieldKey = getAuditFieldById(relatedAction?.id, params.auditCategories)?.value;
  const auditStructureValue = relatedAction?.values?.find((v) => v.value === auditFieldKey);
  return auditStructureValue?.label?.[params.language] ?? null;
}
