import {flatten} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {VehicleAudit} from '../../../api/digitalCertificateApi';
import {composeAssetsByRelatedActions} from '../../../utils/composeAssetsByRelatedActions';
import {getRelatedActionsByUniqueKeys} from '../../../utils/getRelatedActionsByUniqueKeys';
import {mapAssetsToSlideImages} from '../../../utils/mapAssetsToSlideImages';

export function getFilteredPhotosByKeys(vehicleAudit: VehicleAudit, keys: string[]) {
  const relatedActions = getRelatedActionsByUniqueKeys({
    categories: vehicleAudit.auditStructure.categories,
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });

  const filteredActions = relatedActions?.filter(
    (action) => action.key && keys.includes(action.key)
  );

  if (isNilOrEmpty(filteredActions)) {
    return null;
  }

  const assets = composeAssetsByRelatedActions(filteredActions!, vehicleAudit.audit.categories);

  if (isNilOrEmpty(assets)) {
    return null;
  }

  return mapAssetsToSlideImages(flatten(assets!.map((a) => a.files)));
}
