import i18n from 'i18next';
import {
  Clickable,
  getValueByDevice,
  Grid,
  HStack,
  Icon,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import {css, useTheme} from 'styled-components';

import {AppLanguageDialogItems} from '../../../consts/languageDialogItems';
import {ButtonIcon} from '../../ButtonIcon/ButtonIcon';

type LanguagesDialogModalProps = {
  languages: AppLanguageDialogItems;
  isVisible: boolean;
  onChoseLanguage: (lang: AppLanguage) => void;
  onClose: () => void;
  title: string;
};
export const LanguagesDialogModal = ({
  languages,
  isVisible,
  onChoseLanguage,
  title,
  onClose,
}: LanguagesDialogModalProps) => {
  const theme = useTheme();
  const device = useDevice();
  const modalWidth = getValueByDevice(device, 120, 700, 800, 800);

  return (
    <div
      style={{
        width: `${modalWidth}px`,
        height: 'auto',
        borderRadius: '25px',
        boxShadow: '0px 0px 80px 0px rgba(0, 0, 0, 0.10)',
        backgroundColor: 'white',
        padding: '40px',
        display: isVisible ? 'block' : 'none',
      }}
    >
      <VStack spacing={8}>
        <HStack align="center" justify="space-between">
          <Text alternative size="large">
            {title}
          </Text>
          <ButtonIcon variant="outline" icon="close" onClick={onClose} />
        </HStack>
        <Grid columns={2}>
          {Object.entries(languages).map(([lang, item]) => {
            const handleClickButton = () => {
              onChoseLanguage(lang as AppLanguage);
            };
            return (
              <div
                key={item.title}
                css={css`
                  border: 1px solid transparent;

                  &:hover {
                    border-color: ${theme.colors.severity.neutralLight};
                  }

                  background-color: ${i18n?.resolvedLanguage === lang
                    ? theme.colors.fill.greyLight
                    : 'initial'};
                  border-radius: 8px;
                  padding: 16px;
                `}
              >
                <Clickable onClick={handleClickButton}>
                  <HStack align="center" spacing={2}>
                    <Icon value={item.icon} size={5} />
                    <Text alternative>{item.title}</Text>
                  </HStack>
                </Clickable>
              </div>
            );
          })}
        </Grid>
      </VStack>
    </div>
  );
};
