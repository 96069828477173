import {map, pipe, prop, reject, uniqBy} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {Category} from '../../../api/digitalCertificateApi';
import {SectionId} from '../../../consts/Sections';

export const getActiveSections = pipe(
  (items: Category[] | Nullish) => (items?.length ? items : []),
  uniqBy(prop('parentUniqueKey')),
  map(prop('parentUniqueKey')),
  reject(isNilOrEmpty)
);

export const isSectionActive = (sectionId: SectionId, sections: string[] | Nullish) =>
  sectionId === 'SPECIFICATION' || sections?.includes(sectionId);
