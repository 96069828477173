import {isNonEmptyString, isTrue} from 'ramda-adjunct';

import {buildArray, Nullish} from 'shared';

import {FeatureStateFlagSeverity} from '../components/FeatureStateFlag/FeatureStateFlag';
import {ListFeaturesItemProps} from '../components/ListFeatures/components/ListFeaturesItem';

type Feature = {
  title: string | Nullish;
  isMandatory: boolean | Nullish;
};
type FeaturesType = {
  title: string | null;
  variant: FeatureStateFlagSeverity;
  label: string | null;
  isMandatory: boolean | Nullish;
  description: string | Nullish;
};

export function createFeaturesList(features: FeaturesType[]) {
  return features.reduce(
    (builder, feature) =>
      builder.when(isFeatureVisible(feature), {
        title: feature.title!,
        flagText: feature.label,
        variant: feature.variant,
        description: feature.description,
      }),
    buildArray<ListFeaturesItemProps>()
  );
}
const isFeatureVisible = (feature: Feature) =>
  isNonEmptyString(feature.title) ||
  (isNonEmptyString(feature.title) && isTrue(feature.isMandatory));
