import {Box, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {EMPTY_PLACEHOLDER, Nullish, TestIdProps} from 'shared';

import {i18n} from '../../i18n/i18n';
import {Avatar} from '../Avatar/Avatar';
import {Mechanic} from '../CardMechanic/CardMechanic';

interface CommentMechanicProps extends Mechanic, TestIdProps {
  comment?: string | Nullish;
}

export function CommentMechanic(props: CommentMechanicProps) {
  return (
    <Box
      backgroundColor="fill.greyLight"
      borderRadius={['small', 'medium', 'large', 'large']}
      padding={[3, 6, 6, 6]}
    >
      <HStack spacing={4} align="flex-start" justify="flex-start" wrap={[true, true, false, false]}>
        <Box flex={0}>
          <Avatar name={props.name ?? EMPTY_PLACEHOLDER} />
        </Box>
        <VStack shrink={0} spacing={1}>
          <Heading size={3} color="primary">
            {props.name ?? EMPTY_PLACEHOLDER}
          </Heading>
          <Text size="small" color="secondary">
            {props.description ?? i18n.t('mechanic')}
          </Text>
        </VStack>
        <Show onNotebook onDesktop>
          <Space horizontal={10} />
        </Show>
        <Text size="base" color="primary">
          {props.comment}
        </Text>
      </HStack>
    </Box>
  );
}
