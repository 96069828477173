import {Heading, HStack, Text} from 'platform/foundation';

import {Nullish} from 'shared';

interface SetHeadlineProps {
  label: string;
  state: string | Nullish;
}

export function SetHeadline(props: SetHeadlineProps) {
  return (
    <HStack align="baseline" justify="flex-start" spacing={4}>
      <Heading size={3} color="primary">
        {props.label}
      </Heading>
      <Text color="tertiary">{props.state}</Text>
    </HStack>
  );
}
