import {AppLanguage} from 'platform/locale';

import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {ComparisonKeyOf} from '../consts/comparisonKeys';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';

interface AuditFieldNameParams {
  key: ComparisonKeyOf;
  language: AppLanguage;
  auditCategories: AuditCategory[];
  relatedActions: RelatedAction[] | Nullish;
}

export function getAuditFieldName(params: AuditFieldNameParams): string | null {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);
  return relatedAction?.name?.[params.language] ?? null;
}
