import {
  Box,
  Clickable,
  Heading,
  HStack,
  Icon,
  Show,
  Space,
  ThemeIconKey,
  VStack,
} from 'platform/foundation';

import {ReactElement} from 'react';

import {isNotNil} from 'ramda';

import {Nullish, suffixTestId, TestIdProps, useToggle} from 'shared';

import {SectionStateFlag, SectionStateFlagProps} from '../SectionStateFlag/SectionStateFlag';
import {Collapse} from './components/Collapse';

export interface CardContainerProps extends TestIdProps {
  heading: string;
  icon?: ThemeIconKey;
  flag?: SectionStateFlagProps | Nullish;
  children?: ReactElement | ReactElement[];
}

export function CardContainer(props: CardContainerProps) {
  const [isExpanded, toggleExpansion] = useToggle(true);

  return (
    <Box
      padding={[3, 6, 10, 10]}
      borderRadius={['small', 'medium', 'large', 'large']}
      backgroundColor="general.white"
      data-testid={suffixTestId('cardContainer', props)}
    >
      <VStack>
        <HStack spacing={[3, 4, 4, 6]} align="center">
          <Show when={isNotNil(props.icon)}>
            <Icon size={[6, 8, 8, 10]} value={props.icon} color="accent.base" />
          </Show>
          <Heading size={1} data-testid={suffixTestId('cardContainerHeading', props)}>
            {props.heading}
          </Heading>
          <Show when={isNotNil(props.flag)}>
            <SectionStateFlag {...props.flag!} />
          </Show>
          <Space horizontal={4} fillAvailable />
          <Clickable onClick={toggleExpansion}>
            <Box
              role="button"
              padding={[1, 3, 3, 3]}
              borderRadius="circular"
              borderColor="border.emphasisDefault"
              border="1px solid"
              data-testid={suffixTestId('cardContainerButton', props)}
            >
              <Icon
                value={isExpanded ? 'hardware/keyboard_arrow_up' : 'hardware/keyboard_arrow_down'}
                size={5}
              />
            </Box>
          </Clickable>
        </HStack>
        <Collapse isOpen={isExpanded}>
          <Box overflowY="hidden">
            <Space vertical={[3, 6, 10, 10]} />
            {props.children}
          </Box>
        </Collapse>
      </VStack>
    </Box>
  );
}
