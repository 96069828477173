import {Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {all, always, any, filter, length, pipe, propEq, values} from 'ramda';

import {Nullish} from 'shared';

import {Carousel} from '../../components/Carousel/Carousel';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {Section} from '../../components/Section/Section';
import {SectionStateFlagProps} from '../../components/SectionStateFlag/SectionStateFlag';
import {Separator} from '../../components/Separator/Separator';
import {FeaturesDataBundle} from '../../hooks/useGetDataForFeatures';
import {i18n} from '../../i18n/i18n';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';

type FeaturesProps = Omit<FeaturesDataBundle, 'sectionConfig'>;
export function Features({featuresSlideshow, featuresData, features}: FeaturesProps) {
  if (!featuresData?.length || !features) {
    return null;
  }

  const isNotTested = all((feature) => feature.variant === 'neutral', features);
  const isDamaged = any((feature) => feature.variant === 'damaged', features);

  const countDamages: (object: object) => number = pipe(
    values,
    filter(propEq('damaged', 'variant')),
    length
  );

  return (
    <Section
      id="EQUIPMENT"
      icon="features"
      heading={i18n.t('featuresHeader')}
      content={
        <VStack spacing={[4, 6, 6, 10]}>
          <ListFeatures columns={[1, 2, 2, 2]} spacing={[4, 6, 6, 10]} features={features} />
          <Show when={featuresSlideshow && featuresSlideshow.length > 0}>
            <Separator />
            <Carousel isFilterOfDamageDisabled data={featuresSlideshow} />
          </Show>
        </VStack>
      }
      flag={match<[boolean, boolean], Nullish | SectionStateFlagProps>([isNotTested, isDamaged])
        .with(
          [false, true],
          always({severity: 'damage', text: getRecordsTranslate(countDamages(features))})
        )
        .with([true, false], always(null))
        .otherwise(always({severity: 'good', text: i18n.t('sectionState.good')}))}
    />
  );
}
