import {
  Box,
  getValueByDevice,
  Heading,
  Hide,
  HStack,
  Image,
  makeImageUrl,
  Show,
  Space,
  useDevice,
  VStack,
} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {isNil} from 'ramda';
import {isFalsy, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish, TestIdProps} from 'shared';

import {Comment} from '../../../components/Comment/CommentExpandable';
import {CommentExpandable} from '../../../components/CommentExpandable/CommentExpandable';
import {
  FeatureStateFlag,
  FeatureStateFlagProps,
} from '../../../components/FeatureStateFlag/FeatureStateFlag';
import {Preview} from '../../../components/Preview/Preview';
import i18n from '../../../i18n/i18n';
import {SlideImage} from '../../../types/SlideImage';
import {ValueWithUnit} from '../../../types/ValueWithUnit';
import {getValueWithUnit} from '../../../utils/getValueWithUnit';
import {WheelAttribute} from './WheelAttribute';

export type Wheel = {
  position: string | Nullish;
  comment: string | Nullish;
  slideImage: SlideImage | Nullish;
  state?: FeatureStateFlagProps;
  discType?: string | Nullish;
  diameter?: string | Nullish;
  tire?: string | Nullish;
  depth?: ValueWithUnit | Nullish;
  width?: ValueWithUnit | Nullish;
  height?: ValueWithUnit | Nullish;
  shouldDisplayOnlyRims?: true;
  shouldDisplayOnlyWheels?: true;
  isPrintView?: boolean;
  onPreviewClick?: (slideImage: SlideImage) => void;
};

interface WheelCardProps extends Wheel, TestIdProps {}

export function WheelCardPrint(props: WheelCardProps) {
  const locale = useLocale();
  const device = useDevice();

  const notPrintViewImage = props.slideImage?.resizeUrl
    ? makeImageUrl(props.slideImage.resizeUrl, getValueByDevice(device, 100, 100, 150, 100))
    : null;

  const onPreview = (slideImage: SlideImage | Nullish) => {
    if (isNil(slideImage)) {
      return null;
    }
    props.onPreviewClick?.(slideImage);
  };
  return (
    <Box padding={[3, 6, 6, 6]} minWidth={0}>
      <HStack spacing={[3, 6, 6, 6]}>
        <Show when={isTrue(props.isPrintView)}>
          <Image
            data-testid="wheelCard"
            borderRadius="small"
            src={props.slideImage?.resizeUrl ? makeImageUrl(props.slideImage.resizeUrl, 140) : null}
            width="100%"
            height="100%"
            ratio="4 / 3"
            fit="cover"
            position="center"
          />
        </Show>
        <Hide when={isTrue(props.isPrintView)}>
          <Show when={isNotNilOrEmpty(notPrintViewImage)}>
            <Preview data-testid="wheelCard" onClick={() => onPreview(props.slideImage)}>
              <Image
                data-testid="wheelCard"
                borderRadius="small"
                src={notPrintViewImage}
                width="100%"
                height="100%"
                ratio="4 / 3"
                fit="cover"
                position="center"
                isLazy
                hasSpinner={!!notPrintViewImage}
              />
            </Preview>
          </Show>
          <Hide when={isNotNilOrEmpty(notPrintViewImage)}>
            <Image
              data-testid="wheelCard"
              borderRadius="small"
              src={notPrintViewImage}
              width="100%"
              height="100%"
              ratio="4 / 3"
              fit="cover"
              position="center"
              isLazy
            />
          </Hide>
        </Hide>
        <VStack spacing={2} width="100%">
          <Hide when={props.shouldDisplayOnlyWheels}>
            <HStack spacing={4} align="center">
              {props.state?.severity ? <FeatureStateFlag severity={props.state.severity} /> : null}
              <Heading size={4}>{props.position}</Heading>
            </HStack>
            <Space vertical={1} />
            <WheelAttribute
              label={i18n.t('wheel.type')}
              text={props.discType ?? EMPTY_PLACEHOLDER}
            />
            <WheelAttribute label={i18n.t('wheel.state')} flag={props.state} />
            <WheelAttribute
              label={i18n.t('wheel.diameter')}
              text={props.diameter ?? EMPTY_PLACEHOLDER}
            />
          </Hide>
          <Hide when={props.shouldDisplayOnlyRims}>
            <WheelAttribute label={i18n.t('wheel.tire')} text={props.tire ?? EMPTY_PLACEHOLDER} />
            <WheelAttribute
              label={i18n.t('wheel.depth')}
              text={getValueWithUnit({
                auditValue: props.depth,
                localeConfig: locale.localeConfig,
                decimals: 1,
              })}
            />
            <WheelAttribute
              label={i18n.t('wheel.width')}
              text={getValueWithUnit({
                auditValue: props.width,
                localeConfig: locale.localeConfig,
                decimals: 1,
              })}
            />
            <WheelAttribute
              label={i18n.t('wheel.height')}
              text={getValueWithUnit({
                auditValue: props.height,
                localeConfig: locale.localeConfig,
                decimals: 1,
              })}
            />
          </Hide>
          <Show when={isFalsy(props.isPrintView) && isNotNilOrEmpty(props.comment)}>
            <CommentExpandable comment={props.comment} />
          </Show>
          <Show when={isTrue(props.isPrintView) && isNotNilOrEmpty(props.comment)}>
            <Comment comment={props.comment} />
          </Show>
        </VStack>
      </HStack>
    </Box>
  );
}
