import {AppLanguage} from 'platform/locale';

import {Nullish} from 'shared';

import {RelatedAction, VehicleAudit} from '../../../api/digitalCertificateApi';
import {getAuditFieldById} from '../../../utils/getAuditFieldById';
import {getAuditFieldLabel} from '../../../utils/getAuditFieldLabel';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';

export const getSecondSetStatus = (
  vehicleAudit: VehicleAudit,
  relatedActions: RelatedAction[] | Nullish,
  language: AppLanguage
) => {
  const relatedAction = getRelatedActionByComparisonKey(
    'wheelsAndTiresIsSecondSetEnabled',
    relatedActions
  );

  const secondSetActiveValue = getAuditFieldById(
    relatedAction?.id,
    vehicleAudit?.audit.categories
  )?.value;

  const auditStructureValue = relatedAction?.values?.find((v) => v.value === secondSetActiveValue);
  const statusActions = auditStructureValue?.content?.relatedActions;
  const statusLabel = getAuditFieldLabel({
    key: 'wheelsAndTiresCompletedSecondSetStatus',
    relatedActions: statusActions,
    auditCategories: vehicleAudit.audit.categories,
    language,
  });
  const statusValue = getAuditFieldValue({
    key: 'wheelsAndTiresCompletedSecondSetStatus',
    relatedActions: statusActions,
    auditCategories: vehicleAudit.audit.categories,
  }) as 'COMPLETE_SET' | 'RIMS_ONLY' | 'TIRES_ONLY' | null;

  return {
    isSecondSetActive: secondSetActiveValue === 'true',
    statusValue,
    statusLabel,
  };
};
