import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../../../api/digitalCertificateApi';
import {ComparisonKeyOf} from '../../../consts/comparisonKeys';
import {getAuditAssetsById} from '../../../utils/getAuditAssetsById';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';
import {mapAssetsToSlideImages} from '../../../utils/mapAssetsToSlideImages';

export const getWheelPhotoUrl = (
  key: ComparisonKeyOf,
  relatedActions: RelatedAction[] | Nullish,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (isNil(auditCategories) || isNil(relatedActions)) {
    return null;
  }
  const relatedAction = getRelatedActionByComparisonKey(key, relatedActions);
  const assets = getAuditAssetsById(relatedAction?.id, auditCategories)?.files;
  return assets?.length ? mapAssetsToSlideImages(assets)[0] : null;
};
