import {AnimatePresence, motion} from 'framer-motion';
import {getCssSize, HStack, Icon, Show, ThemeIconKey, Spinner} from 'platform/foundation';
import styled, {css} from 'styled-components';
import {match} from 'ts-pattern';

import {useEffect, useRef} from 'react';

import {always} from 'ramda';
import {isFalsy, isTrue} from 'ramda-adjunct';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

type FetchingButtonSize = 'default' | 'small';
type FetchingButtonVariant = 'primary' | 'secondary' | 'inverted' | 'outline' | 'link';

export interface FetchingButtonProps extends TestIdProps {
  title: string;
  icon?: ThemeIconKey;
  size?: FetchingButtonSize;
  variant: FetchingButtonVariant;
  isDisabled?: boolean;
  isFetching?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  onClick?: VoidFunction;
}

export function FetchingButton(props: FetchingButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [isVisible, show, hide] = useBoolean(false);

  useEffect(() => {
    if (isFalsy(props.isFetching) && isTrue(props.isSuccess)) {
      show();
      ref?.current?.blur();
      setTimeout(() => {
        hide();
      }, 7000);
    }
  }, [hide, props.isSuccess, props.isFetching, show]);

  return (
    <StyledFetchingButton
      ref={ref}
      role="button"
      $variant={props.variant}
      $size={props.size ?? 'default'}
      $isDisabled={!!props.isDisabled}
      data-testid={suffixTestId('buttonIcon', props)}
      onClick={props.onClick}
    >
      <HStack justify="center" spacing={2} align="center">
        <Show when={isFalsy(props.isFetching) && isFalsy(props.isError)}>
          <AnimatePresence initial>
            {isVisible ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.2}
                stroke="currentColor"
                css={css`
                  width: ${getCssSize(4)};
                  height: ${getCssSize(4)};
                  color: ${({theme}) => theme.colors.severity.successBase};
                `}
              >
                <motion.path
                  initial={{pathLength: 0, opacity: 0}}
                  animate={{pathLength: 1, opacity: 1}}
                  exit={{pathLength: 0}}
                  transition={{
                    type: 'tween',
                    duration: 0.4,
                    ease: 'easeOut',
                  }}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            ) : (
              <Icon value={props.icon} size={4} />
            )}
          </AnimatePresence>
        </Show>
        <Show when={isTrue(props.isFetching)}>
          <Spinner size="small" />
        </Show>
        <Show when={isTrue(props.isError)}>
          <Icon value="alert/error" color="severity.dangerBase" size={4} />
        </Show>
        {props.title}
      </HStack>
    </StyledFetchingButton>
  );
}

// eslint-disable-next-line eag/no-css-property
const StyledFetchingButton = styled.button<{
  $variant: FetchingButtonVariant;
  $isDisabled: boolean;
  $size: FetchingButtonSize;
}>`
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  padding: ${({theme, $size}) => `0 ${theme.getSize($size === 'small' ? 3 : 6)}`};
  border-radius: ${({theme}) => theme.radii.large};
  height: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 11)};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  opacity: ${({$isDisabled}) => ($isDisabled ? 0.5 : 1)};
  cursor: ${({$isDisabled}) => ($isDisabled ? 'auto' : 'pointer')};
  pointer-events: ${({$isDisabled}) => ($isDisabled ? 'none' : 'all')};
  font-family: ${({theme}) => theme.fonts.body};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  font-weight: ${({theme}) => theme.fontWeights.text.default};
  letter-spacing: ${({theme}) => theme.letterSpacing.text.small};
  color: ${({$variant, theme}) =>
    match<FetchingButtonVariant>($variant)
      .with('primary', always(theme.colors.text.white))
      .with('secondary', always(theme.colors.text.accent))
      .with('inverted', always(theme.colors.text.primary))
      .with('outline', always(theme.colors.text.primary))
      .with('link', always(theme.colors.text.link))
      .exhaustive()};
  background-color: ${({$variant, theme}) =>
    match<FetchingButtonVariant>($variant)
      .with('primary', always(theme.colors.accent.base))
      .with('secondary', always(theme.colors.accent.lightest))
      .with('inverted', always(theme.colors.fill.white))
      .with('outline', always(theme.colors.general.transparent))
      .with('link', always(theme.colors.general.transparent))
      .exhaustive()};
  border: ${({$variant, theme}) =>
    $variant === 'outline' ? `1px solid ${theme.colors.border.emphasisDefault}` : 'none'};

  &:hover,
  &:focus {
    color: ${({$variant, theme}) =>
      match<FetchingButtonVariant>($variant)
        .with('primary', always(theme.colors.text.white))
        .with('secondary', always(theme.colors.text.accent))
        .with('inverted', always(theme.colors.text.accent))
        .with('outline', always(theme.colors.text.primary))
        .with('link', always(theme.colors.text.link))
        .exhaustive()};
    background-color: ${({$variant, theme}) =>
      match<FetchingButtonVariant>($variant)
        .with('primary', always(theme.colors.accent.dark))
        .with('secondary', always(theme.colors.accent.lighter))
        .with('inverted', always(theme.colors.fill.white))
        .with('outline', always(theme.colors.border.emphasisDefault))
        .with('link', always(theme.colors.general.transparent))
        .exhaustive()};
  }
`;
