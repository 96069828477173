import {SlideImage} from '../types/SlideImage';

export const getDamageFilesByDamageId = (convertedFiles: SlideImage[]) =>
  convertedFiles.reduce<string[]>((prev, act) => {
    const meta = act.meta;
    if (meta) {
      const metaResult: {damageId: string} = JSON.parse(meta);
      if (!prev.includes(metaResult.damageId)) {
        prev.push(metaResult.damageId);
      }
      return prev;
    }

    return prev;
  }, []);
