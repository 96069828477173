import {Mechanics} from '../types/Mechanics';
import {createFeaturesList} from './createFeatureList';

export const getMechanicFeatures = (mechanics: Mechanics) => {
  if (!mechanics) {
    return null;
  }

  const brakeSystemFeatures = createFeaturesList([
    mechanics.brake.brakeSystemFuctionality,
    mechanics.brake.emergencyParkingBrakeSystem,
    mechanics.brake.brakeFluid,
  ]);

  const driveSystemFeatures = createFeaturesList([
    mechanics.driveSystem.motor,
    mechanics.driveSystem.engineCondition,
    mechanics.driveSystem.vehicleDrivetrain,
    mechanics.driveSystem.leakageOfOperatingFluidsFromEngine,
    mechanics.driveSystem.steeringAssembly,
    mechanics.driveSystem.transmission,
    mechanics.driveSystem.leakageOfOperatingFluidsFromGearbox,
    mechanics.driveSystem.playInGearbox,
    mechanics.driveSystem.otherPartsOfDriveSystem,
    mechanics.driveSystem.leakageOfOperatingFluidsFromOtherPartsDriveSystem,
    mechanics.driveSystem.playInOtherParts,
    mechanics.driveSystem.coolingSystem,
    mechanics.driveSystem.leakageOfOperatingFluidsFromCoolingSystem,
    mechanics.driveSystem.fuelSystem,
    mechanics.driveSystem.fuelLeakFromFuelSystem,
    mechanics.driveSystem.exhaustSystem,
    mechanics.driveSystem.exhaustSystemLeak,
    mechanics.driveSystem.exhaustSystemCorrosion,
    mechanics.driveSystem.clutch,
    mechanics.driveSystem.battery,
    mechanics.driveSystem.DPFCondition,
  ]);

  const chassisFeatures = createFeaturesList([
    mechanics.chassis.status,
    mechanics.chassis.chassisPlay,
    mechanics.chassis.shockAbsorbers,
    mechanics.chassis.vehicleAxel,
  ]);

  const diagnosticsFeatures = createFeaturesList([mechanics.diagnostics.status]);

  const electricalSystemFeatures = createFeaturesList([
    mechanics.electricalSystem.standardHeating,
    mechanics.electricalSystem.vehicleLightning,
    mechanics.electricalSystem.warningLightsCheck,
  ]);
  const bodyConditionFeatures = createFeaturesList([
    mechanics.bodyCondition.status,
    mechanics.bodyCondition.tracesOfAccidentRepair,
    mechanics.bodyCondition.bodyCorrosion,
  ]);

  const allFeatures = [
    ...bodyConditionFeatures,
    ...diagnosticsFeatures,
    ...electricalSystemFeatures,
    ...chassisFeatures,
    ...driveSystemFeatures,
    ...brakeSystemFeatures,
  ];
  const features = {
    bodyConditionFeatures,
    diagnosticsFeatures,
    electricalSystemFeatures,
    chassisFeatures,
    driveSystemFeatures,
    brakeSystemFeatures,
  };

  return {allFeatures, features};
};
