import {Box, HStack, Icon, Text} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

export interface AlertProps extends TestIdProps {
  children?: string;
}

export function Alert(props: AlertProps) {
  return (
    <Box
      padding={[3, 6, 6, 6]}
      backgroundColor="severity.warningLight"
      borderRadius="large"
      data-testid={suffixTestId('alert', props)}
    >
      <HStack spacing={6}>
        <Icon value="damage" size={6} color="severity.warningBase" />
        <Text>{props.children}</Text>
      </HStack>
    </Box>
  );
}
