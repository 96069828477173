import {Nullish} from 'shared';

import {RelatedAction} from '../api/digitalCertificateApi';
import {ComparisonKeyOf} from '../consts/comparisonKeys';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';

interface IsAuditFieldMandatoryParams {
  key: ComparisonKeyOf;
  relatedActions: RelatedAction[] | Nullish;
}

export function isAuditFieldMandatory(params: IsAuditFieldMandatoryParams): boolean | Nullish {
  const relatedAction = getRelatedActionByComparisonKey(params.key, params.relatedActions);
  return relatedAction?.mandatory;
}
