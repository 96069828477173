import {ListFeaturesItemProps} from '../../../components/ListFeatures/components/ListFeaturesItem';
import {DamageModal} from './DamageModal';

type DamageModalProps = {
  listFeatures: (ListFeaturesItemProps & {
    index: number;
    sectionTitle: string;
    onClick: () => void;
    id: string;
  })[];
  title: string;
  top: number;
  left: number;
  onMouseOver: () => void;
  onMouseLeave: () => void;
};
export const DamageModalWrapperComponent = (props: DamageModalProps) => {
  const uniqueTitles = [...new Set(props.listFeatures.map((item) => item.sectionTitle))];
  const features = uniqueTitles.map((title) => ({
    title,
    listFeatures: props.listFeatures
      .filter((item) => item.sectionTitle === title)
      .map((item) => ({
        title: item.title,
        onClick: item.onClick,
        id: item.id,
      })),
  }));

  return (
    <div
      style={{
        position: 'absolute',
        top: `${props.top}px`,
        left: `${props.left}px`,
        borderRadius: '5px',
        zIndex: 100,
      }}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
    >
      <DamageModal features={features} />
    </div>
  );
};
