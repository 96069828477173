import {HStack, Icon, Space, Text, VStack} from 'platform/foundation';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import i18n from '../../i18n/i18n';

interface CommentProps extends TestIdProps {
  comment?: string | Nullish;
}

export function Comment(props: CommentProps) {
  return (
    <VStack>
      <HStack spacing={4} align="center">
        <Icon value="comment" size={6} color="accent.base" />
        <Text size="base">{i18n.t('comment')}</Text>
      </HStack>
      <Space vertical={3} />
      <Text
        size="base"
        color="secondary"
        overflowWrap="anywhere"
        data-testid={suffixTestId('comment', props)}
      >
        {props.comment}
      </Text>
    </VStack>
  );
}
