import {all} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {SectionIdsType} from '../consts/Sections';
import {getTestDrive} from '../utils/getTestDrive';

export type TestDriveDataBundle = {
  testDrive: ReturnType<typeof getTestDrive>;
  sectionConfig: {
    name: SectionIdsType;
    isHidden: boolean;
  };
  isStatusOkay: boolean;
};
export const useGetDataForTestDrive = (
  vehicleAudit: VehicleAudit | null | undefined
): TestDriveDataBundle => {
  const testDrive = getTestDrive(vehicleAudit);
  const testDriveItems = [
    testDrive?.title.testDriveDate,
    testDrive?.title.driver,
    testDrive?.content.driveStart,
    testDrive?.content.driveEnd,
    testDrive?.content.distance?.value,
    testDrive?.content.maxSpeed?.value,
    testDrive?.content.avgSpeed?.value,
  ];
  const isStatusOkay = all(isNotNilOrEmpty, testDriveItems);
  const shouldBeSectionHidden = !testDriveItems.some(isNotNilOrEmpty);

  return {
    testDrive,
    sectionConfig: {
      isHidden: shouldBeSectionHidden,
      name: 'TESTDRIVE',
    },
    isStatusOkay,
  };
};
