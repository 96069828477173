import {gt} from 'ramda';
import {toNumber} from 'ramda-adjunct';

import {isNilOrZero} from 'shared';

import {FeatureStateFlagSeverity} from '../../../components/FeatureStateFlag/FeatureStateFlag';
import i18n from '../../../i18n/i18n';
import {getValueWithUnit} from '../../../utils/getValueWithUnit';

export const getSeverityAndTextForPaintThickness = (
  row: {value: string | null; unit: string} | null,
  warningTreshold: number
) => {
  const text =
    getValueWithUnit({
      auditValue: row,
      defaultUnitTranslate: i18n.t('metricMicroMetre'),
    }) ?? null;

  const severityOverride: FeatureStateFlagSeverity | false = !text && 'neutral';

  const resultSeverity: FeatureStateFlagSeverity =
    severityOverride ||
    (gt(toNumber(row?.value) ?? 0, warningTreshold) || isNilOrZero(toNumber(row?.value))
      ? 'damaged'
      : 'ready');

  return {text, severity: resultSeverity};
};
