import {Box, Clickable, Heading, HStack, Icon, Show, ThemeIconKey} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

export interface NavigationItemProps extends TestIdProps {
  onClick: VoidFunction;
  title: string;
  icon?: ThemeIconKey;
}

export function NavigationItem(props: NavigationItemProps) {
  return (
    <Clickable onClick={props.onClick}>
      <Box padding={4} data-testid={suffixTestId('navigationItem', props)}>
        <HStack spacing={3} align="center">
          <Show when={props.icon}>
            <Icon size={5} value={props.icon} />
          </Show>
          <Heading size={3}>{props.title}</Heading>
        </HStack>
      </Box>
    </Clickable>
  );
}
