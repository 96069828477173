import {getValueByDevice, Hide, Show, useDevice, makeImageUrl} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {append, isNil, isNotNil, reject} from 'ramda';

import {SlideImage} from '../../../types/SlideImage';
import {AdaptiveImageDc} from '../../AdaptiveImageDc/AdaptiveImageDc';
import {Preview} from '../../Preview/Preview';

interface DamageCarouselLocationProps {
  lightboxId: string;
  slide: SlideImage;
  allSlides: SlideImage[];
}

export function DamageCarouselLocation(props: DamageCarouselLocationProps) {
  const device = useDevice();

  const [damageLightboxControls, {onOpenBySlide}] = useLightbox(props.lightboxId);

  const data = reject(isNil)(append(props.slide, props.allSlides));

  const src = makeImageUrl(props.slide.resizeUrl, getValueByDevice(device, 120, 160, 120, 120));

  return (
    <>
      <Show when={isNotNil(src)}>
        <Lightbox
          data-testid="damageCarouselLocation"
          controls={damageLightboxControls}
          images={data}
        />
        <Preview
          data-testid="damageCarouselLocation"
          onClick={() => onOpenBySlide(props.slide, data)}
        >
          <AdaptiveImageDc
            data-testid="damageCarouselLocation"
            url={src ?? undefined}
            fit="cover"
          />
        </Preview>
      </Show>
      <Hide when={isNotNil(src)}>
        <AdaptiveImageDc data-testid="damageCarouselLocation" url={src ?? undefined} fit="cover" />
      </Hide>
    </>
  );
}
