import {createGlobalStyle} from 'styled-components';
import {Normalize} from 'styled-normalize';

// eslint-disable-next-line eag/no-css-property
const BasicStyles = createGlobalStyle`
  @media print {
    @page {
      margin: 40px 0;
    }
    svg {
      page-break-inside: avoid;
    }
    body {
      margin: 0;
      padding: 0;
    }
  }

  * {
   box-sizing: border-box;
  }

  img, svg, video, canvas, audio, iframe, embed, object {
   display: block;
  }

  body {
    min-height: 100vh;
    min-height: 100dvh;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background-color: ${({theme}) => theme.colors.fill.greyLight};
  }

  .svg-exterior-overlay, .svg-interior-overlay, .svg-paint-overlay {
    opacity: 0;
    transition: opacity 1s ease-in-out;

  }

  .svg-exterior-point, .svg-interior-point, .svg-paint-point {
    will-change: transform, opacity;
    transform-origin: center;
    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    transform: scale(0);
    opacity: 0;
  }
  .svg-exterior-point:hover,
  .svg-interior-point:hover {
    filter: drop-shadow(0px 0px 8px  #EF6F48);
    cursor: pointer;
  }

  .svg-paint-point-bg {
    transform-origin: center;
  }

  svg * {
    transform-box: fill-box;
  }
`;

export const GlobalStyles = () => (
  <>
    <Normalize />
    <BasicStyles />
  </>
);
