import {Section} from '../../components/Section/Section';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getAuditAssignee} from '../../utils/getAuditAssignee';
import {SpecificationContent} from './components/SpecificationContent';
import {SpecificationSidebar} from './components/SpecificationSidebar';
import {getSpecification} from './utils/getSpecification';

export function Specification() {
  const {vehicleAudit, alphaCatalogue} = useGetDigitalCertificateData();

  if (!vehicleAudit) {
    return null;
  }

  const assignee = getAuditAssignee(vehicleAudit);
  const specification = getSpecification(vehicleAudit, alphaCatalogue);

  return (
    <Section
      id="SPECIFICATION"
      data-testid="specification"
      icon="specification"
      heading={i18n.t('specificationHeader')}
      content={<SpecificationContent data-testid="specificationContent" {...specification} />}
      directionOverride={['column', 'column', 'row', 'row']}
      sidebarWidthOverride={['100%', '100%', 100, 100]}
      sidebar={
        <SpecificationSidebar
          mechanic={{
            name: assignee?.name,
            dateOfInspection: vehicleAudit.audit.finishedAt ?? vehicleAudit.audit.updateAt,
          }}
          autotracer={null}
          data-testid="specificationSidebar"
        />
      }
    />
  );
}
