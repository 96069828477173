import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

export interface TabProps extends TestIdProps {
  title: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: VoidFunction;
}

export function Tab(props: TabProps) {
  return (
    <StyledTag
      onClick={props.onClick}
      $isActive={!!props.isActive}
      $isDisabled={!!props.isDisabled}
      data-testid={suffixTestId('tab', props)}
    >
      {props.title}
    </StyledTag>
  );
}

// eslint-disable-next-line eag/no-css-property
const StyledTag = styled.button<{
  $isActive: boolean;
  $isDisabled: boolean;
}>`
  flex-shrink: 0;
  flex-grow: 0;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  border-radius: ${({theme}) => theme.radii.large};
  padding: ${({theme}) => `${theme.getSize(2)} ${theme.getSize(4)}`};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  opacity: ${({$isDisabled}) => ($isDisabled ? 0.5 : 1)};
  cursor: ${({$isDisabled, $isActive}) => ($isDisabled || $isActive ? 'auto' : 'pointer')};
  pointer-events: ${({$isDisabled, $isActive}) => ($isDisabled || $isActive ? 'none' : 'all')};
  font-family: ${({theme}) => theme.fonts.body};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  font-weight: ${({theme}) => theme.fontWeights.text.default};
  letter-spacing: ${({theme}) => theme.letterSpacing.text.small};
  color: ${({theme, $isActive}) =>
    $isActive ? theme.colors.text.accent : theme.colors.text.tertiary};
  background-color: ${({theme, $isActive}) =>
    $isActive ? theme.colors.accent.lightest : theme.colors.general.transparent};

  &:hover {
    color: ${({$isActive, theme}) =>
      $isActive ? theme.colors.text.accent : theme.colors.text.primary};
    background-color: ${({$isActive, theme}) =>
      $isActive ? theme.colors.accent.lightest : theme.colors.fill.greyLight};
  }
`;
