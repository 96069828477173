import {Box, Heading, Show, Stack, Text, VStack} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER} from 'shared';

import {Carousel} from '../../components/Carousel/Carousel';
import {CommentExpandable} from '../../components/CommentExpandable/CommentExpandable';
import {DocumentationItem} from '../../components/DocumentationItem/DocumentationItem';
import {Section} from '../../components/Section/Section';
import {Separator} from '../../components/Separator/Separator';
import {DocumentationDataBundle} from '../../hooks/useGetDataForDocumentation';
import i18n from '../../i18n/i18n';

type DocumentationProps = Omit<DocumentationDataBundle, 'sectionConfig'>;
export const Documentation = ({
  documentationSlideshow,
  documentationItemParams,
  documentation,
  firstRegistration,
  dateOfRegistration,
}: DocumentationProps) => {
  if (!documentation || !documentationItemParams) {
    return null;
  }

  return (
    <Section
      id="VEHICLE_DOCUMENTATION"
      icon="documentation"
      heading={i18n.t('documentationHeader')}
      content={
        <VStack spacing={[3, 6, 6, 10]}>
          <DocumentationItem
            heading={i18n.t('vin')}
            attributes={[{label: 'VIN', value: documentation.vin?.value ?? EMPTY_PLACEHOLDER}]}
            comment={documentation.vin?.comment}
          />
          <Separator />
          <DocumentationItem
            heading={i18n.t('technicalCertificate')}
            comment={documentation.technicalCert?.comment}
            attributes={documentationItemParams.technicalCertification}
          />
          <Separator />
          <DocumentationItem
            heading={i18n.t('dateOfRegistration')}
            attributes={[
              {
                label: i18n.t('date'),
                value: dateOfRegistration,
              },
            ]}
            comment={documentation.dateOfRegistration?.comment}
          />

          <DocumentationItem
            heading={i18n.t('firstRegistration')}
            attributes={[
              {
                label: i18n.t('date'),
                value: firstRegistration,
              },
            ]}
            comment={documentation.firstRegistration?.comment}
          />
          <Separator />
          <DocumentationItem
            heading={i18n.t('cocList')}
            attributes={[
              {label: i18n.t('status'), value: documentation.cocListStatus ?? EMPTY_PLACEHOLDER},
            ]}
          />
          <Separator />
          <DocumentationItem
            heading={i18n.t('vehicleUnderFactoryWarranty')}
            attributes={documentationItemParams.vehicleUnderFactoryWarranty}
          />
          <Separator />
          <DocumentationItem
            heading={i18n.t('serviceBook')}
            attributes={[
              {label: i18n.t('type'), value: documentation.serviceBookType ?? EMPTY_PLACEHOLDER},
              {
                label: i18n.t('status'),
                value: documentation.serviceBookState?.value ?? EMPTY_PLACEHOLDER,
              },
            ]}
            comment={documentation.serviceBookState?.comment}
          />
          <Separator />
          <DocumentationItem
            heading={i18n.t('currentMileage')}
            attributes={[{label: i18n.t('mileage'), value: documentationItemParams.currentMileage}]}
            comment={documentation.currentMileage?.comment}
          />
          <Separator />
          <DocumentationItem
            heading={i18n.t('operatingInstructions')}
            attributes={documentationItemParams.operatingInstructions}
          />
          <Separator />
          <Stack
            direction={['column', 'row', 'row', 'row']}
            spacing={[3, 6, 10, 10]}
            align="flex-start"
          >
            <Box flex={1} maxWidth={100} width="100%">
              <Heading size={2}>{i18n.t('mandatoryEquipmentAndAccessories')}</Heading>
            </Box>
            <Box flex={1} width="100%">
              <VStack spacing={[3, 6, 6, 10]}>
                <Box>
                  <Heading size={3} alternative>
                    {documentation.mandatoryEquipmentAndAccessories?.value ?? EMPTY_PLACEHOLDER}
                  </Heading>
                  <Text size="small" color="tertiary">
                    {i18n.t('status')}
                  </Text>
                </Box>
                <Show
                  when={isNotNilOrEmpty(documentation.mandatoryEquipmentAndAccessories?.comment)}
                >
                  <CommentExpandable
                    comment={documentation.mandatoryEquipmentAndAccessories?.comment}
                  />
                </Show>
              </VStack>
            </Box>
          </Stack>
          <Show when={isNotNilOrEmpty(documentationSlideshow)}>
            <Separator />
            <Carousel isFilterOfDamageDisabled data={documentationSlideshow} />
          </Show>
        </VStack>
      }
    />
  );
};
