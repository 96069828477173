import {
  Box,
  Center,
  Hide,
  HStack,
  Icon,
  Show,
  Text,
  ThemeColorPath,
  ThemeColorTextPath,
  ThemeIconKey,
} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

export type FeatureStateFlagSeverity = 'ready' | 'damaged' | 'missing' | 'repaired' | 'neutral';

export interface FeatureStateFlagProps extends TestIdProps {
  severity: FeatureStateFlagSeverity;
  text?: string | Nullish;
}

export function FeatureStateFlag(props: FeatureStateFlagProps) {
  const hasText = isNotNilOrEmpty(props.text);

  const backgroundColor = match<[FeatureStateFlagSeverity, boolean], ThemeColorPath>([
    props.severity,
    hasText,
  ])
    .with(['ready', true], always('severity.successBase'))
    .with(['ready', false], always('severity.successLight'))
    .with(['damaged', true], always('severity.warningBase'))
    .with(['damaged', false], always('severity.warningLight'))
    .with(['missing', true], always('severity.dangerBase'))
    .with(['missing', false], always('severity.dangerLight'))
    .with(['repaired', true], always('severity.informationalBase'))
    .with(['repaired', false], always('severity.informationalLight'))
    .with(['neutral', true], always('severity.neutralLight'))
    .with(['neutral', false], always('severity.neutralLight'))
    .exhaustive();

  const iconColor = match<FeatureStateFlagSeverity, ThemeColorPath>(props.severity)
    .with('ready', always('severity.successBase'))
    .with('damaged', always('severity.warningBase'))
    .with('missing', always('severity.dangerBase'))
    .with('repaired', always('severity.informationalDark'))
    .with('neutral', always('severity.neutralDark'))
    .exhaustive();

  const icon = match<FeatureStateFlagSeverity, ThemeIconKey>(props.severity)
    .with('ready', always('good'))
    .with('damaged', always('damage'))
    .with('missing', always('missing'))
    .with('repaired', always('repaired'))
    .with('neutral', always('neutral'))
    .exhaustive();

  const textColor = match<FeatureStateFlagSeverity, ThemeColorTextPath>(props.severity)
    .with('ready', always('inverted'))
    .with('damaged', always('primary'))
    .with('missing', always('inverted'))
    .with('repaired', always('primary'))
    .with('neutral', always('primary'))
    .exhaustive();

  return (
    <Box
      flex={0}
      height={6}
      paddingHorizontal={3}
      width={hasText ? 'auto' : 6}
      borderRadius="medium"
      backgroundColor={hasText ? backgroundColor : undefined}
      data-testid={suffixTestId('sectionStateFlag', props)}
    >
      <Center height={6}>
        <HStack align="center" spacing={2}>
          <Hide when={hasText}>
            <Icon value={icon} size={6} color={iconColor} />
          </Hide>
          <Show when={hasText}>
            <Text color={textColor} alternative size="xSmall" noWrap>
              {props.text}
            </Text>
          </Show>
        </HStack>
      </Center>
    </Box>
  );
}
