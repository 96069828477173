import {Box, getSize, getValueByDevice, Hide, Text, TextSize, useDevice} from 'platform/foundation';
import {css} from 'styled-components';

import {gte, isNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {SlideImage} from '../../types/SlideImage';
import {ButtonIcon} from '../ButtonIcon/ButtonIcon';
import {Slide} from './components/Slide';
import {SlideshowApi} from './hooks/useSlideshow';

interface SlideshowProps extends TestIdProps {
  api: SlideshowApi;
  onInspectImage: (slideShowItem: SlideImage) => void;
}

export function Slideshow(props: SlideshowProps) {
  const device = useDevice();

  const offset = getValueByDevice(device, 3, 6, 6, 10);

  const pagination = `${props.api.activeSlide + 1} / ${props.api.slides?.length}`;

  const isSlideButtonsHidden = props.api.slides?.length === 1;
  const handleOnInspectImage = () => {
    const slideShowItem = props.api.slides?.[props.api.activeSlide];
    if (isNil(slideShowItem)) {
      return;
    }
    return props.onInspectImage(slideShowItem);
  };

  return (
    <div
      ref={props.api.refs.slider}
      css={css`
        border-radius: ${({theme}) => theme.radii.small};
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
      `}
      data-testid={suffixTestId('slideshowSection', props)}
    >
      {props.api.slides?.map((slide, index) => (
        <Slide
          key={slide.id}
          isActive={index === props.api.activeSlide}
          {...slide}
          onClick={handleOnInspectImage}
          data-testid={suffixTestId(`slideshow-slide-[${index}]`, props)}
        />
      ))}
      <Hide when={isSlideButtonsHidden}>
        <div
          data-testid={suffixTestId('slideshow-leftIcon', props)}
          css={css`
            position: absolute;
            display: flex;
            align-items: center;
            left: ${getSize(offset)};
            margin: auto 0;
          `}
        >
          <ButtonIcon
            icon="arrowLeft"
            variant="transparent"
            onClick={props.api.prev}
            size={getValueByDevice(device, 'small', 'default')}
            isDisabled={gte(1, props.api.slides?.length ?? 0)}
            data-testid={suffixTestId('slideshow-leftIcon', props)}
          />
        </div>
      </Hide>
      <Hide when={isSlideButtonsHidden}>
        <div
          data-testid={suffixTestId('slideshow-rightIcon', props)}
          css={css`
            position: absolute;
            display: flex;
            align-items: center;
            right: ${getSize(offset)};
            margin: auto 0;
          `}
        >
          <ButtonIcon
            icon="arrowRight"
            variant="transparent"
            onClick={props.api.next}
            size={getValueByDevice(device, 'small', 'default')}
            isDisabled={gte(1, props.api.slides?.length ?? 0)}
            data-testid={suffixTestId('slideshow-rightIcon', props)}
          />
        </div>
      </Hide>

      <Hide when={gte(1, props.api.slides?.length ?? 0)}>
        <div
          data-testid={suffixTestId('slideshow-control', props)}
          css={css`
            position: absolute;
            display: flex;
            align-items: center;
            left: ${getSize(offset)};
            bottom: ${getSize(offset)};
            gap: ${getSize(3)};
          `}
        >
          <Box
            opacity={0.8}
            borderRadius="border_radius_full"
            paddingVertical={getValueByDevice(device, 1, 3)}
            paddingHorizontal={getValueByDevice(device, 2, 4)}
            backgroundColor="fill.overlayMedium"
          >
            <Text
              color="inverted"
              size={getValueByDevice<TextSize, TextSize>(device, 'small', 'base')}
            >
              {pagination}
            </Text>
          </Box>
        </div>
      </Hide>
    </div>
  );
}
