import {
  AdaptiveImage,
  CSSDimension,
  getCssSize,
  Heading,
  Integer,
  makeStorageUrl,
} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {Nullish, TestIdProps} from 'shared';

export interface CardImageProps extends TestIdProps {
  src: string | Nullish;
  title?: string;
  width?: Integer | CSSDimension;
  height?: Integer | CSSDimension;
  maxWidth?: Integer | CSSDimension;
  maxHeight?: Integer | CSSDimension;
  onClick?: VoidFunction;
  position?: string;
  alt?: string;
}

export function CardImage(props: CardImageProps) {
  return (
    <div
      onClick={props.onClick}
      css={css`
        width: ${getCssSize(props.width)};
        height: ${getCssSize(props.height)};
        max-width: ${getCssSize(props.maxWidth)};
        max-height: ${getCssSize(props.maxHeight)};
        border-radius: ${({theme}) => theme.radii.small};
        position: relative;
        cursor: pointer;
        overflow: hidden;
      `}
    >
      <AdaptiveImage
        height="100%"
        width="100%"
        fit="cover"
        url={props.src ?? undefined}
        makeUrl={makeStorageUrl}
        maxWidth={props.maxWidth}
        maxHeight={props.maxHeight}
        alt={props.alt}
      />

      <Background />
      <DarkBackground />
      <TextWrapper>
        <Heading size={3} color="inverted">
          {props.title}
        </Heading>
      </TextWrapper>
    </div>
  );
}

const TextWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  padding: ${({theme}) => theme.getSize(5)};
`;

const Background = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    0deg,
    ${({theme}) => theme.colors.accent.darkest}CC,
    ${({theme}) => theme.colors.accent.darkest}00
  );
`;

const DarkBackground = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  background: linear-gradient(
    0deg,
    ${({theme}) => theme.colors.accent.darkest}CC,
    ${({theme}) => theme.colors.accent.darkest}CC
  );

  &:hover {
    opacity: 1;
  }
`;
