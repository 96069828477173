import {VehicleAudit} from '../../../api/digitalCertificateApi';
import {getAuditFieldById} from '../../../utils/getAuditFieldById';
import {getCategoryByUniqueKey} from '../../../utils/getCategoryByUniqueKey';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';

export const isDimensionCorresponds = (vehicleAudit: VehicleAudit) => {
  const category = getCategoryByUniqueKey({
    categories: vehicleAudit.auditStructure.categories,
    parentUniqueKey: 'WHEELS_AND_TIRES',
    uniqueKey:
      'THE_DIMENSION_CORRESPONDS_TO_THE_REGISTRATION_CERTIFICATE_CERTIFICATE_OF_CONFORMITY',
  });

  const relatedAction = getRelatedActionByComparisonKey(
    'wheelsAndTiresIsDimensionCorresponds',
    category?.paramDefinitions?.relatedActions
  );

  const field = getAuditFieldById(relatedAction?.id, vehicleAudit?.audit.categories);

  return field?.value ?? null;
};
