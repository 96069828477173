import {Box, HStack, Icon, Text, VStack} from 'platform/foundation';

import {Separator} from '../../../components/Separator/Separator';

type DamageModalProps = {
  features: {
    title: string;
    listFeatures: {title: string; onClick: () => void; id: string}[];
  }[];
};
export const DamageModal = (props: DamageModalProps) => (
  <Box
    borderRadius="small"
    border="1px solid #DFE1E6"
    width={42}
    padding={2}
    backgroundColor="general.white"
  >
    {props.features.map((item, index) => (
      <>
        {index > 0 && <Separator spacing={3} />}
        <Box>
          <VStack spacing={2} key={index + 100}>
            <Text size="xxSmall" color="secondary">
              {item.title}
            </Text>
            {item.listFeatures.map((feature, index) => (
              <div
                onClick={feature.onClick}
                style={{cursor: 'pointer'}}
                key={`${feature.id}-${index}`}
              >
                <HStack spacing={2}>
                  <Icon value="alert/error" size={4} color="severity.warningBase" />
                  <Text alternative size="xSmall">
                    {feature.title}
                  </Text>
                </HStack>
              </div>
            ))}
          </VStack>
        </Box>
      </>
    ))}
  </Box>
);
