type VehicleArea = 'exterior-front' | 'exterior-back' | 'interior-front' | 'interior-back';
export const addClickListenerToDamagePoints = <T extends Record<string, number>>(
  damagesData: T,
  handleHoverPoint: (key: keyof T, index: number, area: VehicleArea) => void,
  handleClickPoint: () => void,
  vehicleArea: VehicleArea
) => {
  const resultDamageData = Object.entries(damagesData).filter(
    ([_key, numberOfDamage]) => numberOfDamage !== 0
  );

  const pointEvents = resultDamageData.map(([key], index) => ({
    element: document.getElementById(`${vehicleArea}-${key}-point`),
    handleHover: () => handleHoverPoint(key as keyof T, index, vehicleArea),
    handleClick: () => handleClickPoint(),
    offsetLeft: document.getElementById(`${vehicleArea}-${key}-point`)?.offsetLeft,
  }));

  pointEvents.forEach((key) => {
    key?.element?.addEventListener('mouseover', key.handleHover);
    key?.element?.addEventListener('click', key.handleClick);
  });
  return pointEvents;
};
