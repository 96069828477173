import i18n from '../i18n/i18n';

export function getRecordsTranslate(count: number) {
  if (count === 1) {
    return i18n.t('sectionState.records_one');
  }

  if (count > 1 && count < 5) {
    return i18n.t('sectionState.records_2to4', {count});
  }

  return i18n.t('sectionState.records', {count});
}
