import {Box, Grid, GridItem, Hide, Show, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {useState} from 'react';

import {dec, divide, inc, isNotNil} from 'ramda';

import {AdaptiveImageDc} from '../../components/AdaptiveImageDc/AdaptiveImageDc';
import {CarouselSlideshow} from '../../components/Carousel/components/CarouselSlideshow';
import {CarouselHeader} from '../../components/CarouselHeader/CarouselHeader';
import {Preview} from '../../components/Preview/Preview';
import {Section} from '../../components/Section/Section';
import {PhotoDocumentationDataBundle} from '../../hooks/useGetDataForPhotoDocumentation';
import i18n from '../../i18n/i18n';

type PhotoDocumentationProps = Omit<PhotoDocumentationDataBundle, 'sectionConfig'>;
export function PhotoDocumentation({photoDocumentationPhotos}: PhotoDocumentationProps) {
  const [photoDocumentationLightboxControls, {onOpenBySlide}] = useLightbox('photoDocumentation');
  const [page, setPage] = useState(0);

  const onNext = (numberOfSlides: number) => {
    if (page === dec(numberOfSlides)) {
      setPage(0);
      return;
    }
    setPage(inc(page));
  };

  const onPrevious = (numberOfSlides: number) => {
    if (page === 0) {
      setPage(dec(numberOfSlides));
      return;
    }
    setPage(dec(page));
  };

  if (!photoDocumentationPhotos?.length) {
    return null;
  }

  const sliceStart = page * NUMBER_OF_PHOTOS_ON_PAGES;
  const sliceEnd = sliceStart + NUMBER_OF_PHOTOS_ON_PAGES;
  const slides = photoDocumentationPhotos.slice(sliceStart, sliceEnd);
  const roundedTotalSlides = divide(photoDocumentationPhotos.length, NUMBER_OF_PHOTOS_ON_PAGES);
  const totalSlides = Math.ceil(roundedTotalSlides);

  return (
    <>
      <Lightbox
        data-testid="photoDocumentation"
        controls={photoDocumentationLightboxControls}
        images={photoDocumentationPhotos}
      />
      <Section
        id="PHOTODOCUMENTATION"
        data-testid="photoDocumentation"
        icon="photoDocumentation"
        heading={i18n.t('photosHeader')}
        content={
          <Box>
            <Hide onMobile>
              <VStack spacing={[3, 6, 6, 10]}>
                <CarouselHeader
                  data-testid="photoDocumentation"
                  title={i18n.t('photos')}
                  slides={photoDocumentationPhotos}
                  activeSlide={page}
                  onNextClick={() => onNext(totalSlides)}
                  onPreviousClick={() => onPrevious(totalSlides)}
                  numberOfImages={NUMBER_OF_PHOTOS_ON_PAGES}
                />
                <Grid columns={[1, 2, 4, 4]} spacing={[6, 6, 6, 10]}>
                  {slides.map((slide, index) => (
                    <GridItem key={slide.id} span={1}>
                      <Show when={isNotNil(slide.resizeUrl)}>
                        <Preview
                          data-testid={`photo-[${index}]`}
                          onClick={() => onOpenBySlide(slide, photoDocumentationPhotos)}
                        >
                          <AdaptiveImageDc
                            data-testid={`photo-[${index}]`}
                            fit="contain"
                            url={slide.resizeUrl}
                          />
                        </Preview>
                      </Show>
                      <Hide when={isNotNil(slide.resizeUrl)}>
                        <AdaptiveImageDc
                          data-testid={`photo-[${index}]`}
                          fit="contain"
                          url={slide.resizeUrl}
                        />
                      </Hide>
                    </GridItem>
                  ))}
                </Grid>
              </VStack>
            </Hide>
            <Show onMobile>
              <CarouselSlideshow
                data-testid="photoDocumentation"
                lightboxId="photoDocumentation"
                slides={slides}
              />
            </Show>
          </Box>
        }
      />
    </>
  );
}

const NUMBER_OF_PHOTOS_ON_PAGES = 8;
