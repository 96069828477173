import {Box, HStack, Text, VStack} from 'platform/foundation';

import {CardMechanic} from '../../components/CardMechanic/CardMechanic';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getAuditAssignee} from '../../utils/getAuditAssignee';
import {SpecificationContentPrint} from './components/SpecificationContent.print';
import {getSpecification} from './utils/getSpecification';

export function SpecificationPrint() {
  const {vehicleAudit, alphaCatalogue} = useGetDigitalCertificateData();

  if (!vehicleAudit) {
    return null;
  }

  const assignee = getAuditAssignee(vehicleAudit);
  const specification = getSpecification(vehicleAudit, alphaCatalogue);

  if (!specification) {
    return null;
  }

  return (
    <SectionPrint icon="specification" heading={i18n.t('specificationHeader')}>
      <VStack spacing={10} width="100%">
        <HStack align="flex-start" spacing={10}>
          <Box flex={1}>
            <Text>{i18n.t('specificationDescription')}</Text>
          </Box>
          <Box flex={1}>
            <CardMechanic
              name={assignee?.name}
              dateOfInspection={vehicleAudit.audit.finishedAt ?? vehicleAudit.audit.updateAt}
            />
          </Box>
        </HStack>
        <SpecificationContentPrint
          data-testid="specificationContent"
          isPrintView
          {...specification}
        />
      </VStack>
    </SectionPrint>
  );
}
