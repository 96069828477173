import {Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {all, always, any, isNotNil, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {ListFeaturesItemVariant} from '../../components/ListFeatures/components/ListFeaturesItem';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {SectionStateFlagProps} from '../../components/SectionStateFlag/SectionStateFlag';
import {WheelTireDataBundle} from '../../hooks/useGetDataForWheelsAndTires';
import i18n from '../../i18n/i18n';
import {SetHeadline} from './components/SetHeadline';
import {WheelCardPrint} from './components/WheelCard.print';

type WheelsAndTiresProps = Omit<WheelTireDataBundle, 'sectionConfig'>;
export function WheelsAndTiresPrint({wheels, assignee, photos}: WheelsAndTiresProps) {
  if (!wheels) {
    return null;
  }

  const sets = reject(isNilOrEmpty)([
    wheels.firstSet.LEFT_FRONT.state,
    wheels.firstSet.RIGHT_FRONT.state,
    wheels.firstSet.LEFT_REAR.state,
    wheels.firstSet.RIGHT_REAR.state,
    wheels.completeSecondSet?.LEFT_FRONT?.state,
    wheels.completeSecondSet?.RIGHT_FRONT?.state,
    wheels.completeSecondSet?.LEFT_REAR?.state,
    wheels.completeSecondSet?.RIGHT_REAR?.state,
    wheels.rimsOnlySecondSet?.LEFT_FRONT?.state,
    wheels.rimsOnlySecondSet?.RIGHT_FRONT?.state,
    wheels.rimsOnlySecondSet?.LEFT_REAR?.state,
    wheels.rimsOnlySecondSet?.RIGHT_REAR?.state,
  ]);

  const isNotTested = all((state) => state?.severity === 'neutral', sets);
  const isDamaged = any((state) => state?.severity === 'damaged', sets);

  return (
    <SectionPrint
      icon="mechanics"
      heading={i18n.t('wheelsAndTiresHeader')}
      header={<CommentMechanic name={assignee?.name} comment={wheels.generalComment} />}
      flag={match<[boolean, boolean], Nullish | SectionStateFlagProps>([isNotTested, isDamaged])
        .with([false, true], always({severity: 'damage', text: i18n.t('sectionState.damaged')}))
        .with([true, false], always(null))
        .otherwise(always({severity: 'good', text: i18n.t('sectionState.good')}))}
    >
      <VStack spacing={10} width="100%">
        <SetHeadline
          label={i18n.t('firstSet')}
          state={wheels.firstSet.isDimensionCorresponds ? i18n.t('isDimensionCorresponds') : null}
        />

        <WheelCardPrint
          isPrintView
          position={i18n.t('leftFront')}
          state={wheels.firstSet.LEFT_FRONT.state}
          comment={wheels.firstSet.LEFT_FRONT.comment}
          slideImage={wheels.firstSet.LEFT_FRONT.slideImage}
          discType={wheels.firstSet.LEFT_FRONT.discType}
          diameter={wheels.firstSet.LEFT_FRONT.diameter}
          tire={wheels.firstSet.LEFT_FRONT.tire}
          depth={wheels.firstSet.LEFT_FRONT.depth}
          width={wheels.firstSet.LEFT_FRONT.width}
          height={wheels.firstSet.LEFT_FRONT.height}
        />

        <WheelCardPrint
          isPrintView
          position={i18n.t('rightFront')}
          state={wheels.firstSet.RIGHT_FRONT.state}
          comment={wheels.firstSet.RIGHT_FRONT.comment}
          slideImage={wheels.firstSet.RIGHT_FRONT.slideImage}
          discType={wheels.firstSet.RIGHT_FRONT.discType}
          diameter={wheels.firstSet.RIGHT_FRONT.diameter}
          tire={wheels.firstSet.RIGHT_FRONT.tire}
          depth={wheels.firstSet.RIGHT_FRONT.depth}
          width={wheels.firstSet.RIGHT_FRONT.width}
          height={wheels.firstSet.RIGHT_FRONT.height}
        />

        <WheelCardPrint
          isPrintView
          position={i18n.t('leftRear')}
          state={wheels.firstSet.LEFT_REAR.state}
          comment={wheels.firstSet.LEFT_REAR.comment}
          slideImage={wheels.firstSet.LEFT_REAR.slideImage}
          discType={wheels.firstSet.LEFT_REAR.discType}
          diameter={wheels.firstSet.LEFT_REAR.diameter}
          tire={wheels.firstSet.LEFT_REAR.tire}
          depth={wheels.firstSet.LEFT_REAR.depth}
          width={wheels.firstSet.LEFT_REAR.width}
          height={wheels.firstSet.LEFT_REAR.height}
        />

        <WheelCardPrint
          isPrintView
          position={i18n.t('rightRear')}
          state={wheels.firstSet.RIGHT_REAR.state}
          comment={wheels.firstSet.RIGHT_REAR.comment}
          slideImage={wheels.firstSet.RIGHT_REAR.slideImage}
          discType={wheels.firstSet.RIGHT_REAR.discType}
          diameter={wheels.firstSet.RIGHT_REAR.diameter}
          tire={wheels.firstSet.RIGHT_REAR.tire}
          depth={wheels.firstSet.RIGHT_REAR.depth}
          width={wheels.firstSet.RIGHT_REAR.width}
          height={wheels.firstSet.RIGHT_REAR.height}
        />

        <ListFeatures
          columns={2}
          spacing={6}
          features={[
            {
              title: i18n.t('tireReplacement'),
              variant: match<string | Nullish, ListFeaturesItemVariant>(
                wheels.features?.tireReplacement
              )
                .with('true', always('damaged'))
                .with('false', always('ready'))
                .otherwise(always('ready')),
              description: match<string | Nullish, ListFeaturesItemVariant>(
                wheels.features?.tireReplacement
              )
                .with('true', always(i18n.t('yes')))
                .with('false', always(i18n.t('notNeeded')))
                .otherwise(always(i18n.t('notNeeded'))),
            },
            {
              title: i18n.t('spareWheel'),
              variant: match<string | Nullish, ListFeaturesItemVariant>(wheels.features?.spareWheel)
                .with('true', always('ready'))
                .with('false', always('damaged'))
                .otherwise(always('damaged')),
              description: match<string | Nullish, ListFeaturesItemVariant>(
                wheels.features?.spareWheel
              )
                .with('true', always(i18n.t('yes')))
                .with('false', always(i18n.t('missing')))
                .otherwise(always(i18n.t('missing'))),
            },
            {
              title: i18n.t('safetyNuts'),

              variant: match<string | Nullish, ListFeaturesItemVariant>(wheels.features?.safetyNuts)
                .with('true', always('ready'))
                .with('false', always('damaged'))
                .otherwise(always('damaged')),
              description: match<string | Nullish, ListFeaturesItemVariant>(
                wheels.features?.safetyNuts
              )
                .with('true', always(i18n.t('yes')))
                .with('false', always(i18n.t('missing')))
                .otherwise(always(i18n.t('missing'))),
            },
            {
              title: i18n.t('safetyAdapter'),
              variant: match<string | Nullish, ListFeaturesItemVariant>(
                wheels.features?.adapterForSafety
              )
                .with('true', always('ready'))
                .with('false', always('damaged'))
                .otherwise(always('damaged')),
              description: match<string | Nullish, ListFeaturesItemVariant>(
                wheels.features?.adapterForSafety
              )
                .with('true', always(i18n.t('yes')))
                .with('false', always(i18n.t('missing')))
                .otherwise(always(i18n.t('missing'))),
            },
          ]}
        />

        <SetHeadline
          label={i18n.t('secondSet')}
          state={wheels.secondSetStatus.statusLabel ?? i18n.t('no')}
        />
        <Show when={isNotNil(wheels.completeSecondSet)}>
          <>
            <WheelCardPrint
              isPrintView
              position={i18n.t('leftFront')}
              state={wheels.completeSecondSet?.LEFT_FRONT?.state}
              comment={wheels.completeSecondSet?.LEFT_FRONT?.comment}
              slideImage={wheels.completeSecondSet?.LEFT_FRONT?.slideImage}
              discType={wheels.completeSecondSet?.LEFT_FRONT?.discType}
              diameter={wheels.completeSecondSet?.LEFT_FRONT?.diameter}
              tire={wheels.completeSecondSet?.LEFT_FRONT?.tire}
              depth={wheels.completeSecondSet?.LEFT_FRONT?.depth}
              width={wheels.completeSecondSet?.LEFT_FRONT?.width}
              height={wheels.completeSecondSet?.LEFT_FRONT?.height}
            />

            <WheelCardPrint
              isPrintView
              position={i18n.t('rightFront')}
              state={wheels.completeSecondSet?.RIGHT_FRONT.state}
              comment={wheels.completeSecondSet?.RIGHT_FRONT.comment}
              slideImage={wheels.completeSecondSet?.RIGHT_FRONT?.slideImage}
              discType={wheels.completeSecondSet?.RIGHT_FRONT?.discType}
              diameter={wheels.completeSecondSet?.RIGHT_FRONT?.diameter}
              tire={wheels.completeSecondSet?.RIGHT_FRONT?.tire}
              depth={wheels.completeSecondSet?.RIGHT_FRONT?.depth}
              width={wheels.completeSecondSet?.RIGHT_FRONT?.width}
              height={wheels.completeSecondSet?.RIGHT_FRONT?.height}
            />

            <WheelCardPrint
              isPrintView
              position={i18n.t('leftRear')}
              state={wheels.completeSecondSet?.LEFT_REAR.state}
              comment={wheels.completeSecondSet?.LEFT_REAR.comment}
              slideImage={wheels.completeSecondSet?.LEFT_REAR?.slideImage}
              discType={wheels.completeSecondSet?.LEFT_REAR?.discType}
              diameter={wheels.completeSecondSet?.LEFT_REAR?.diameter}
              tire={wheels.completeSecondSet?.LEFT_REAR?.tire}
              depth={wheels.completeSecondSet?.LEFT_REAR?.depth}
              width={wheels.completeSecondSet?.LEFT_REAR?.width}
              height={wheels.completeSecondSet?.LEFT_REAR?.height}
            />

            <WheelCardPrint
              isPrintView
              position={i18n.t('rightRear')}
              state={wheels.completeSecondSet?.RIGHT_REAR.state}
              comment={wheels.completeSecondSet?.RIGHT_REAR.comment}
              slideImage={wheels.completeSecondSet?.RIGHT_REAR?.slideImage}
              discType={wheels.completeSecondSet?.RIGHT_REAR?.discType}
              diameter={wheels.completeSecondSet?.RIGHT_REAR?.diameter}
              tire={wheels.completeSecondSet?.RIGHT_REAR?.tire}
              depth={wheels.completeSecondSet?.RIGHT_REAR?.depth}
              width={wheels.completeSecondSet?.RIGHT_REAR?.width}
              height={wheels.completeSecondSet?.RIGHT_REAR?.height}
            />
          </>
        </Show>
        <Show when={isNotNil(wheels.rimsOnlySecondSet)}>
          <>
            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyRims
              position={i18n.t('leftFront')}
              state={wheels.rimsOnlySecondSet?.LEFT_FRONT?.state}
              comment={wheels.rimsOnlySecondSet?.LEFT_FRONT?.comment}
              slideImage={wheels.rimsOnlySecondSet?.LEFT_FRONT?.slideImage}
              discType={wheels.rimsOnlySecondSet?.LEFT_FRONT?.discType}
              diameter={wheels.rimsOnlySecondSet?.LEFT_FRONT?.diameter}
            />

            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyRims
              position={i18n.t('rightFront')}
              state={wheels.rimsOnlySecondSet?.RIGHT_FRONT.state}
              comment={wheels.rimsOnlySecondSet?.RIGHT_FRONT.comment}
              slideImage={wheels.rimsOnlySecondSet?.RIGHT_FRONT?.slideImage}
              discType={wheels.rimsOnlySecondSet?.RIGHT_FRONT?.discType}
              diameter={wheels.rimsOnlySecondSet?.RIGHT_FRONT?.diameter}
            />

            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyRims
              position={i18n.t('leftRear')}
              state={wheels.rimsOnlySecondSet?.LEFT_REAR.state}
              comment={wheels.rimsOnlySecondSet?.LEFT_REAR.comment}
              slideImage={wheels.rimsOnlySecondSet?.LEFT_REAR?.slideImage}
              discType={wheels.rimsOnlySecondSet?.LEFT_REAR?.discType}
              diameter={wheels.rimsOnlySecondSet?.LEFT_REAR?.diameter}
            />

            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyRims
              position={i18n.t('rightRear')}
              state={wheels.rimsOnlySecondSet?.RIGHT_REAR.state}
              comment={wheels.rimsOnlySecondSet?.RIGHT_REAR.comment}
              slideImage={wheels.rimsOnlySecondSet?.RIGHT_REAR?.slideImage}
              discType={wheels.rimsOnlySecondSet?.RIGHT_REAR?.discType}
              diameter={wheels.rimsOnlySecondSet?.RIGHT_REAR?.diameter}
            />
          </>
        </Show>
        <Show when={isNotNil(wheels?.tiresOnlySecondSet)}>
          <>
            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyWheels
              position={i18n.t('leftFront')}
              comment={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.comment}
              slideImage={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.slideImage}
              tire={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.tire}
              depth={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.depth}
              width={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.width}
              height={wheels?.tiresOnlySecondSet?.LEFT_FRONT?.height}
            />

            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyWheels
              position={i18n.t('rightFront')}
              comment={wheels?.tiresOnlySecondSet?.RIGHT_FRONT.comment}
              slideImage={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.slideImage}
              tire={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.tire}
              depth={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.depth}
              width={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.width}
              height={wheels?.tiresOnlySecondSet?.RIGHT_FRONT?.height}
            />

            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyWheels
              position={i18n.t('leftRear')}
              comment={wheels?.tiresOnlySecondSet?.LEFT_REAR.comment}
              slideImage={wheels?.tiresOnlySecondSet?.LEFT_REAR?.slideImage}
              tire={wheels?.tiresOnlySecondSet?.LEFT_REAR?.tire}
              depth={wheels?.tiresOnlySecondSet?.LEFT_REAR?.depth}
              width={wheels?.tiresOnlySecondSet?.LEFT_REAR?.width}
              height={wheels?.tiresOnlySecondSet?.LEFT_REAR?.height}
            />

            <WheelCardPrint
              isPrintView
              shouldDisplayOnlyWheels
              position={i18n.t('rightRear')}
              comment={wheels?.tiresOnlySecondSet?.RIGHT_REAR.comment}
              slideImage={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.slideImage}
              tire={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.tire}
              depth={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.depth}
              width={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.width}
              height={wheels?.tiresOnlySecondSet?.RIGHT_REAR?.height}
            />
          </>
        </Show>
      </VStack>
    </SectionPrint>
  );
}
