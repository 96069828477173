import {AppLanguage} from 'platform/locale';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {DataUtilParams} from '../types/DataUtilParams';
import {getAuditAssetsById} from './getAuditAssetsById';
import {getAuditFieldById} from './getAuditFieldById';
import {getCategoriesByUniqueKeys} from './getCategoriesByUniqueKeys';
import {mapAssetsToSlideImages} from './mapAssetsToSlideImages';

export function getFeatures(params: DataUtilParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.auditStructure.categories) {
    return null;
  }

  const categories = getCategoriesByUniqueKeys({
    parentUniqueKey: 'EQUIPMENT',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!categories?.length) {
    return null;
  }

  const features = categories
    .map((category) => ({
      title: category.name?.[params.language] ?? null,
      comment: getComment(
        category.paramDefinitions?.additionalInformation,
        params.vehicleAudit?.audit.categories
      ),
      slides: getSlides(
        category.paramDefinitions?.additionalInformation,
        params.vehicleAudit?.audit.categories
      ),
      field: getField(
        category.paramDefinitions?.relatedActions?.[0],
        params.language,
        params.vehicleAudit?.audit.categories
      ),
      id: category.key,
    }))
    .filter((feature) => isNotNilOrEmpty(feature.title) && feature.field?.value !== null);

  return features?.length > 0 ? features : null;
}

export const getComment = (
  relatedActions: RelatedAction[] | Nullish,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (isNil(auditCategories) || isNil(relatedActions)) {
    return null;
  }
  const action = relatedActions?.find((i) => i.type === 'additional_comment');
  return getAuditFieldById(action?.id, auditCategories)?.value ?? null;
};
const getSlides = (
  relatedActions: RelatedAction[] | Nullish,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (isNil(auditCategories) || isNil(relatedActions)) {
    return null;
  }
  const action = relatedActions?.find((i) => i.type === 'additional_photos');
  const assets = getAuditAssetsById(action?.id, auditCategories)?.files;
  return assets?.length ? mapAssetsToSlideImages(assets) : null;
};
const getField = (
  action: RelatedAction | Nullish,
  language: AppLanguage,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (isNil(auditCategories) || isNil(action)) {
    return null;
  }
  const keyValue = getAuditFieldById(action?.id, auditCategories)?.value ?? null;
  const auditStructureValue = action.values?.find((v) => v.value === keyValue);
  const statusAction = auditStructureValue?.content?.relatedActions?.[0];
  const statusValue = getAuditFieldById(statusAction?.id, auditCategories)?.value ?? null;
  return {
    value: keyValue ?? null,
    statusLabel:
      auditStructureValue?.content?.relatedActions?.[0]?.values?.find(
        (v) => v.value === statusValue
      )?.label?.[language] ?? null,
    statusValue,
  };
};
