import {
  Box,
  Center,
  getValueByDevice,
  Heading,
  Hide,
  Show,
  Stack,
  useDevice,
  VStack,
} from 'platform/foundation';

import {useState} from 'react';

import {dec, inc} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {i18n} from '../../i18n/i18n';
import {SlideImage} from '../../types/SlideImage';
import {CarouselHeader} from '../CarouselHeader/CarouselHeader';
import {CommentExpandable} from '../CommentExpandable/CommentExpandable';
import {CarouselSlideshow} from './components/CarouselSlideshow';

export type CarouselSlide = {
  title: string;
  comment: string | Nullish;
  slides: SlideImage[] | Nullish;
};

interface CarouselProps {
  data: CarouselSlide[] | Nullish;
  isFilterOfDamageDisabled?: true;
}

export function Carousel(props: CarouselProps) {
  const [page, setPage] = useState(0);
  const device = useDevice();

  const onNextClick = (numberOfSlides: number) => {
    if (page === dec(numberOfSlides)) {
      setPage(0);
      return;
    }
    setPage(inc(page));
  };

  const onPreviousClick = (numberOfSlides: number) => {
    if (page === 0) {
      setPage(dec(numberOfSlides));
      return;
    }
    setPage(dec(page));
  };

  if (!props.data?.length) {
    return null;
  }

  return (
    <>
      <CarouselHeader
        slides={props.data}
        activeSlide={page}
        title={i18n.t(getValueByDevice(device, 'photos', 'photosAdditional'))}
        onNextClick={() => onNextClick(props.data?.length ?? 0)}
        onPreviousClick={() => onPreviousClick(props.data?.length ?? 0)}
      />

      <Stack
        direction={['column', 'column', 'row', 'row']}
        spacing={[3, 6, 10, 10]}
        align="flex-start"
      >
        <Box flex={1} maxWidth={100} width="100%">
          <VStack spacing={[3, 6, 10, 10]}>
            <Heading size={2}>{props.data[page].title}</Heading>
            <Hide onMobile when={isNilOrEmpty(props.data[page].comment)}>
              <CommentExpandable comment={props.data[page].comment} />
            </Hide>
          </VStack>
        </Box>
        <Box flex={1} width="100%">
          <Center>
            {props.data?.map((item, index) => (
              <Show
                key={item.title}
                when={index === page && item.slides && item.slides.length >= 1}
              >
                <CarouselSlideshow
                  lightboxId={item.title}
                  isFilterOfDamageDisabled={props.isFilterOfDamageDisabled}
                  slides={item.slides}
                />
              </Show>
            ))}
          </Center>
        </Box>
        <Show onMobile when={isNotNilOrEmpty(props.data[page].comment)}>
          <CommentExpandable comment={props.data[page].comment} />
        </Show>
      </Stack>
    </>
  );
}
