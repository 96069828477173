import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {getNaturalPersonFullName} from './getNaturalPersonFullName';

export function getAuditAssignee(vehicleAudit: VehicleAudit | Nullish) {
  if (!vehicleAudit?.assignments || !vehicleAudit.audit) {
    return null;
  }

  const users = vehicleAudit.assignments.filter((a) => a.identity.id === vehicleAudit.audit.id);
  const assignee = users?.[0];

  if (isNil(assignee)) {
    return null;
  }

  return {
    name: getNaturalPersonFullName({
      firstName: assignee.user.firstName,
      lastName: assignee.user.lastName,
    }),
    description: assignee.identity.type,
  };
}
