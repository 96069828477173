import {useDateTimeFormatter} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {parseDate} from 'shared';

import {Attributes} from '../../components/Attributes/Attributes';
import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {Section} from '../../components/Section/Section';
import {TestDriveDataBundle} from '../../hooks/useGetDataForTestDrive';
import {i18n} from '../../i18n/i18n';
import {TestDriveContent} from './components/TestDriveContent';

type TestDriveProps = Omit<TestDriveDataBundle, 'sectionConfig'>;
export function TestDrive({testDrive, isStatusOkay}: TestDriveProps) {
  const formatDateTime = useDateTimeFormatter();

  if (!testDrive) {
    return null;
  }

  return (
    <Section
      id="TESTDRIVE"
      icon="testDrive"
      heading={i18n.t('testDriveHeader')}
      header={
        testDrive.mechanic.name && isNotNilOrEmpty(testDrive.mechanic.comment) ? (
          <CommentMechanic comment={testDrive.mechanic.comment} name={testDrive.mechanic.name} />
        ) : null
      }
      directionOverride={['column', 'column', 'row', 'row']}
      sidebarWidthOverride={['100%', '100%', 100, 100]}
      sidebar={
        <Attributes
          data-testid="testDriveAttributes"
          rows={[
            {
              label: i18n.t('testDrive.date'),
              text: testDrive.title.testDriveDate
                ? formatDateTime('dateShort', parseDate(testDrive.title.testDriveDate))
                : null,
            },
            {label: i18n.t('testDrive.driver'), text: testDrive.title.driver},
          ]}
        />
      }
      content={
        <TestDriveContent
          duration={testDrive.content.duration}
          driveStart={testDrive.content.driveStart}
          driveEnd={testDrive.content.driveEnd}
          distance={testDrive.content.distance}
          maxSpeed={testDrive.content.maxSpeed}
          avgSpeed={testDrive.content.avgSpeed}
          weakGpsSignal={testDrive.content.weakGpsSignal}
        />
      }
      flag={isStatusOkay ? {severity: 'good', text: i18n.t('sectionState.good')} : null}
    />
  );
}
