import {Show, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {Autotracer, CardCebia} from '../../../components/CardCebia/CardCebia';
import {CardMechanic, Mechanic} from '../../../components/CardMechanic/CardMechanic';
import {i18n} from '../../../i18n/i18n';

interface SpecificationSidebarProps extends TestIdProps {
  mechanic: Mechanic;
  autotracer: Autotracer | Nullish;
}

export function SpecificationSidebar(props: SpecificationSidebarProps) {
  return (
    <VStack spacing={[4, 6, 6, 6]}>
      <Text size="base" color="tertiary" data-testid={suffixTestId('title', props)}>
        {i18n.t('specificationDescription')}
      </Text>
      <CardMechanic {...props.mechanic} />
      <Show when={isNotNil(props.autotracer)}>
        <CardCebia {...props.autotracer!} />
      </Show>
    </VStack>
  );
}
