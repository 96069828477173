import {Box, Hide, Image, Show, Stack, useDevice, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {useEffect, useMemo, useRef} from 'react';

import {__, all, filter, gt, keys, length, pipe, values} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isPositive, isTrue} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER} from 'shared';

import InteriorBackOverlay from '../../assets/images/interior-back-overlay.svg';
import InteriorFrontOverlay from '../../assets/images/interior-front-overlay.svg';
import {BannerSuccess} from '../../components/BannerSuccess/BannerSuccess';
import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {DamageCarousel} from '../../components/DamageCarousel/DamageCarousel';
import {getDamageCarouselDataFromMap} from '../../components/DamageCarousel/utils/getDamageCarouselDataFromMap';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {Section} from '../../components/Section/Section';
import {Separator} from '../../components/Separator/Separator';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getAuditAssignee} from '../../utils/getAuditAssignee';
import {getDamageValues} from '../../utils/getDamageValues';
import {getIfIsFeatureHidden} from '../../utils/getIfIsFeatureHidden';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';
import {DamageModalWrapperComponent} from '../Exterior/components/DamageModalWrapperComponent';
import {useGetDamageModalConfiguration} from '../Exterior/hooks/useGetDamageModalConfiguration';
import {useGetDamageStatesAndRefs} from '../Exterior/hooks/useGetDamageStatesAndRefs';
import {addClickListenerToDamagePoints} from '../Exterior/utils/addClickListenerToDamagePoints';
import {findAndSetModalPosition} from '../Exterior/utils/findAndSetModalPosition';
import {removeDamagePointListeners} from '../Exterior/utils/removeDamagePointListeners';
import {DamageDataInterior} from './types/DamageDataInterior';
import {getInterior} from './utils/getInterior';

export function Interior() {
  const imagesRef = useRef<HTMLDivElement>(null);
  const device = useDevice();
  const backDamagesRef = useRef<HTMLDivElement>(null);
  const frontDamagesRef = useRef<HTMLDivElement>(null);
  const {
    isDamageModalShown,
    setIsDamageModalShown,
    damagesCarouselRef,
    paintRef,
    setChosenDamage,
    setModalPosition,
    modalPosition,
    chosenDamage,
  } = useGetDamageStatesAndRefs();

  const locale = useLocale();

  const {vehicleAudit} = useGetDigitalCertificateData();
  const assignee = getAuditAssignee(vehicleAudit);

  const damageValues = useMemo(
    () => ({
      coverRightPillar: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'COLUMN_TRIM_ON_THE_RIGHT',
        point: 'coverRightPillar',
      }),
      coverLeftPillar: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'COLUMN_TRIM_ON_THE_LEFT',
        point: 'coverLeftPillar',
      }),
      coverFrontRightDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'RF_DOOR_TRIM',
        point: 'coverFrontRightDoor',
      }),

      coverFrontLeftDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'LF_DOOR_TRIM',
        point: 'coverFrontLeftDoor',
      }),

      cockpit: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'DASHBOARD',
        point: 'cockpit',
      }),

      steeringWheel: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'DRIVING_WHEEL',
        point: 'steeringWheel',
      }),

      driverSeat: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'DRIVERS_SEAT',
        point: 'driverSeat',
      }),

      passengerSeat: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'PASSENGER_SEAT',
        point: 'passengerSeat',
      }),

      coverBackLeftDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'LR_DOOR_TRIM',
        point: 'coverBackLeftDoor',
      }),

      coverBackRightDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'RR_DOOR_TRIM',
        point: 'coverBackRightDoor',
      }),

      backSeats: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'BACKBENCH',
        point: 'backSeats',
      }),

      ceiling: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'CEILINGS',
        point: 'ceiling',
      }),

      carpet: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'CARPET',
        point: 'carpet',
      }),

      other: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'OTHERS',
        point: 'other',
      }),
    }),
    [locale.language, vehicleAudit]
  );

  const carouselData = getDamageCarouselDataFromMap(Object.values(damageValues));
  const damageModalConfiguration = useGetDamageModalConfiguration(
    carouselData,
    damagesCarouselRef,
    'interior',
    chosenDamage
  );
  const damagesData: DamageDataInterior = useMemo(
    () => ({
      ceiling: damageValues.ceiling?.values?.length ?? 0,
      backSeats: damageValues.backSeats?.values?.length ?? 0,
      coverRightPillar: damageValues.coverRightPillar?.values?.length ?? 0,
      coverLeftPillar: damageValues.coverLeftPillar?.values?.length ?? 0,
      coverFrontRightDoor: damageValues.coverFrontRightDoor?.values?.length ?? 0,
      coverFrontLeftDoor: damageValues.coverFrontLeftDoor?.values?.length ?? 0,
      coverBackLeftDoor: damageValues.coverBackLeftDoor?.values?.length ?? 0,
      coverBackRightDoor: damageValues.coverBackRightDoor?.values?.length ?? 0,
      passengerSeat: damageValues.passengerSeat?.values?.length ?? 0,
      driverSeat: damageValues.driverSeat?.values?.length ?? 0,
      carpet: damageValues.carpet?.values?.length ?? 0,
      steeringWheel: damageValues.steeringWheel?.values?.length ?? 0,
      cockpit: damageValues.cockpit?.values?.length ?? 0,
      other: damageValues.other?.values?.length ?? 0,
    }),
    [damageValues]
  );

  const isStatusOkay = all(isTrue, [
    isNilOrEmpty(damageValues.backSeats?.values),
    isNilOrEmpty(damageValues.carpet?.values),
    isNilOrEmpty(damageValues.ceiling?.values),
    isNilOrEmpty(damageValues.cockpit?.values),
    isNilOrEmpty(damageValues.coverBackLeftDoor?.values),
    isNilOrEmpty(damageValues.coverBackRightDoor?.values),
    isNilOrEmpty(damageValues.coverFrontLeftDoor?.values),
    isNilOrEmpty(damageValues.coverFrontRightDoor?.values),
    isNilOrEmpty(damageValues.coverLeftPillar?.values),
    isNilOrEmpty(damageValues.coverRightPillar?.values),
    isNilOrEmpty(damageValues.driverSeat?.values),
    isNilOrEmpty(damageValues.other?.values),
    isNilOrEmpty(damageValues.passengerSeat?.values),
    isNilOrEmpty(damageValues.steeringWheel?.values),
  ]);

  useEffect(() => {
    function callback(entries: IntersectionObserverEntry[]) {
      if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0) {
        showOverlay(damagesData);
      }
    }
    const handleMouseOverPoint = (
      key: string,
      index: number,
      area: 'exterior-front' | 'exterior-back' | 'interior-front' | 'interior-back'
    ) => {
      setTimeout(() => setIsDamageModalShown(true), 10);

      setChosenDamage({key, index, area});
    };
    const handleClickPoint = () => {
      setTimeout(() => setIsDamageModalShown(true), 10);
      if (device !== 'mobile') {
        damageModalConfiguration?.selectedDamageData?.[0].onClick();
      }
    };
    const frontPointEvents = addClickListenerToDamagePoints(
      damagesData,
      handleMouseOverPoint,
      handleClickPoint,
      'interior-front'
    );
    const backPointEvents = addClickListenerToDamagePoints(
      damagesData,
      handleMouseOverPoint,
      handleClickPoint,
      'interior-back'
    );
    if (chosenDamage !== undefined) {
      findAndSetModalPosition(
        chosenDamage,
        setModalPosition,
        frontPointEvents,
        backPointEvents,
        'INTERIOR'
      );
    }
    const handleLeaveDamagesContent = () => {
      setIsDamageModalShown(false);
    };
    const frontDamagesElement = frontDamagesRef.current?.addEventListener(
      'mouseleave',
      handleLeaveDamagesContent
    );
    const backDamagesElement = backDamagesRef.current?.addEventListener(
      'mouseleave',
      handleLeaveDamagesContent
    );
    const observer = new IntersectionObserver(callback, {threshold: DAMAGES_IN_VIEW_TRESHOLD});
    imagesRef.current && observer.observe(imagesRef.current);

    return () => {
      observer.disconnect();
      removeDamagePointListeners(frontPointEvents);
      removeDamagePointListeners(backPointEvents);
      frontDamagesElement &&
        frontDamagesRef.current?.removeEventListener('mouseleave', handleLeaveDamagesContent);
      backDamagesElement &&
        backDamagesRef.current?.removeEventListener('mouseleave', handleLeaveDamagesContent);
    };
  }, [damagesData, chosenDamage]);

  const interior = getInterior({vehicleAudit, language: locale.language});

  const countDamages: (object: object) => number = pipe(values, filter(gt(__, 0)), length);

  if (!interior) {
    return null;
  }
  const isSomeValueDefine = interior.features.some((f) => !!f.value);
  return (
    <section id="INTERIOR">
      <div style={{position: 'relative'}}>
        {isDamageModalShown && damageModalConfiguration && (
          <DamageModalWrapperComponent
            listFeatures={damageModalConfiguration.selectedDamageData || []}
            title={damageModalConfiguration.selectedDamageData?.[0]?.sectionTitle || ''}
            onMouseOver={() => {
              setIsDamageModalShown(true);
            }}
            top={modalPosition.top + damageModalConfiguration.damageModalPosition.x}
            left={modalPosition.left + damageModalConfiguration.damageModalPosition.y}
            onMouseLeave={() => {
              setIsDamageModalShown(false);
            }}
          />
        )}
        <Section
          id="INTERIOR"
          icon="interior"
          heading={i18n.t('interiorHeader')}
          header={
            assignee?.name && isNotNilOrEmpty(interior?.comment) ? (
              <CommentMechanic name={assignee?.name} comment={interior?.comment} />
            ) : null
          }
          content={
            <VStack spacing={[3, 6, 6, 10]}>
              <Hide when={isTrue(isStatusOkay)}>
                <Stack direction={['column', 'row', 'row', 'row']} spacing={[3, 6, 6, 10]}>
                  <Box position="relative" flex={1} ref={imagesRef}>
                    <Image
                      borderRadius="small"
                      src="../../assets/images/interior-front.jpg"
                      width="100%"
                      height="auto"
                      isLazy
                      hasSpinner
                      data-testid="interior-front-image"
                    />
                    <Box
                      ref={frontDamagesRef}
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                    >
                      <InteriorFrontOverlay />
                    </Box>
                  </Box>

                  <Box position="relative" flex={1}>
                    <Image
                      borderRadius="small"
                      src="../../assets/images/interior-back.jpg"
                      width="100%"
                      height="auto"
                      isLazy
                      hasSpinner
                      data-testid="interior-back-image"
                    />
                    <Box
                      ref={backDamagesRef}
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                    >
                      <InteriorBackOverlay />
                    </Box>
                  </Box>
                </Stack>
                <div ref={damagesCarouselRef}>
                  <Show when={isNotNilOrEmpty(carouselData)}>
                    <Separator />
                    <DamageCarousel
                      data={carouselData}
                      chosenDamageIndex={
                        damageModalConfiguration?.chosenImageDamageIndex !== undefined
                          ? damageModalConfiguration?.chosenImageDamageIndex
                          : 0
                      }
                    />
                  </Show>
                </div>
              </Hide>
              <Show when={isTrue(isStatusOkay)}>
                <BannerSuccess />
              </Show>
              <Show when={isNotNilOrEmpty(interior.features)}>
                <Show
                  when={interior.features.length > 0 && isSomeValueDefine && !isTrue(isStatusOkay)}
                >
                  <Separator />
                </Show>
                <ListFeatures
                  columns={[1, 2, 2, 2]}
                  spacing={[3, 6, 6, 10]}
                  features={interior.features.map((f) => ({
                    title: f.name ?? EMPTY_PLACEHOLDER,
                    description: f.value === 'true' ? i18n.t('yes') : null,
                    variant: 'ready',
                    hidden: getIfIsFeatureHidden(f.value),
                  }))}
                />
              </Show>
            </VStack>
          }
          flag={
            isStatusOkay
              ? {severity: 'good', text: i18n.t('sectionState.good')}
              : {
                  severity: 'damage',
                  text: getRecordsTranslate(countDamages(damagesData)),
                }
          }
        />
      </div>
    </section>
  );
}

const showOverlay = (points: Record<string, number>) => {
  keys(points).forEach((key, index) => {
    const count = points[key];
    if (isPositive(count)) {
      setTimeout(
        () => {
          const partTextElement = document.getElementById(`interior-front-${key}-text`);
          const partPointElement = document.getElementById(`interior-front-${key}-point`);
          const partOverlayElement = document.getElementById(`interior-front-${key}-overlay`);
          if (partTextElement) {
            partTextElement.textContent = count.toString();
          }
          if (partOverlayElement) {
            partOverlayElement.style.opacity = '0.3';
          }
          if (partPointElement) {
            partPointElement.style.opacity = '1';
            partPointElement.style.transform = 'scale(1)';
          }
        },
        BASE_DELAY + index * POINT_DELAY
      );

      setTimeout(
        () => {
          const partTextElement = document.getElementById(`interior-back-${key}-text`);
          const partPointElement = document.getElementById(`interior-back-${key}-point`);
          const partOverlayElement = document.getElementById(`interior-back-${key}-overlay`);
          if (partTextElement) {
            partTextElement.textContent = count.toString();
          }
          if (partOverlayElement) {
            partOverlayElement.style.opacity = '0.3';
          }
          if (partPointElement) {
            partPointElement.style.opacity = '1';
            partPointElement.style.transform = 'scale(1)';
          }
        },
        BASE_DELAY - Math.round(POINT_DELAY / 2) + index * POINT_DELAY
      );
    }
  });
};

const DAMAGES_IN_VIEW_TRESHOLD = 0.5;
const BASE_DELAY = 70;
const POINT_DELAY = 30;
