import {Hide, HStack, Show, Text} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {EMPTY_PLACEHOLDER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {
  FeatureStateFlag,
  FeatureStateFlagProps,
} from '../../../components/FeatureStateFlag/FeatureStateFlag';

export interface WheelAttributeProps extends TestIdProps {
  label: string;
  text?: string | Nullish;
  flag?: FeatureStateFlagProps;
}

export function WheelAttribute(props: WheelAttributeProps) {
  const hasFlag = isNotNil(props.flag);

  return (
    <HStack spacing={4} justify="space-between">
      <Text size="small" color="tertiary" data-testid={suffixTestId('label', props)}>
        {props?.label}
      </Text>
      <Hide when={hasFlag}>
        <Text size="small" data-testid={suffixTestId('value', props)}>
          {props?.text ?? EMPTY_PLACEHOLDER}
        </Text>
      </Hide>
      <Show when={hasFlag}>
        <FeatureStateFlag {...props.flag!} data-testid={suffixTestId('flag', props)} />
      </Show>
    </HStack>
  );
}
