import {
  Box,
  CSSDimension,
  Heading,
  HStack,
  Image,
  Show,
  Stack,
  useElementSize,
} from 'platform/foundation';
import styled from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {Nullish, TestIdProps} from 'shared';

import {getWorkspaceCustomization} from '../../../utils/getWorkspaceCustomization';

export interface CertificateBadgeProps extends TestIdProps {
  makeModel: string | Nullish;
  specification: string | Nullish;
}

export function CertificateBadge(props: CertificateBadgeProps) {
  const [containerRef, width] = useElementSize<HTMLDivElement>();
  const {isDigitalCertificateLogoShown} = getWorkspaceCustomization();

  const top = match<number | null, CSSDimension>(width)
    .with(Pattern.number.between(0, 680), always('-20%'))
    .with(Pattern.number.between(680, 945), always('-50%'))
    .with(Pattern.number.between(945, 1290), always('-140%'))
    .otherwise(always('-140%'));

  const left = match<number | null, CSSDimension>(width)
    .with(Pattern.number.between(0, 680), always('15%'))
    .with(Pattern.number.between(680, 945), always('30%'))
    .with(Pattern.number.between(945, 1290), always('50%'))
    .otherwise(always('40%'));

  const right = match<number | null, CSSDimension>(width)
    .with(Pattern.number.between(0, 680), always('-30%'))
    .otherwise(always('0rem'));

  return (
    <Box
      ref={containerRef}
      flexGrow={0}
      flexShrink={0}
      overflow="hidden"
      position="relative"
      borderRadius="small"
      paddingVertical={[3, 4, 4, 4]}
      paddingHorizontal={[3, 4, 4, 6]}
      data-testid="certificateBadge"
    >
      <GradientBackground />
      <Box position="absolute" overflow="hidden" top={top} left={left} right={right} bottom={0}>
        <HStack height="100%" align="flex-end" justify="flex-end">
          <Image
            data-testid="watermark"
            isLazy
            maxWidth={230}
            fit="cover"
            width="100%"
            height="100%"
            src="../../assets/images/watermark.svg"
          />
        </HStack>
      </Box>
      <Box position="relative">
        <Stack
          spacing={[3, 3, 10, 10]}
          direction={['column', 'column', 'row', 'row']}
          align={['flex-start', 'flex-start', 'center', 'center']}
          justify="flex-start"
        >
          <Show when={isDigitalCertificateLogoShown}>
            <Image
              data-testid="logo"
              isLazy
              src="../../assets/images/logo.svg"
              alt="Digital Certificate"
              height={[5, 6, 8, 6]}
              width="auto"
            />
          </Show>
          <Stack
            spacing={[1, 1, 1, 4]}
            direction={['column', 'column', 'column', 'row']}
            align={['flex-start', 'flex-start', 'flex-start', 'center']}
          >
            <Heading data-testid="certificateBadgeMakeModel" size={2}>
              {props.makeModel}
            </Heading>
            <Heading
              data-testid="certificateBadgeSpecification"
              size={4}
              color="secondary"
              alternative
            >
              {props.specification}
            </Heading>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

// eslint-disable-next-line eag/no-css-property
const GradientBackground = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  background: linear-gradient(90deg, #fcf2fc 0%, #f9f8f2 52.08%, #f5f0ff 100%);
`;
