import {ThemeIconKey} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';

type AppLanguageItem = {
  title: string;
  icon: ThemeIconKey;
};
export type AppLanguageDialogItems = Record<AppLanguage, AppLanguageItem>;
export const languageDialogItems: AppLanguageDialogItems = {
  cs: {
    title: 'Čeština',
    icon: 'flags/CZE',
  },
  sk: {
    title: 'Slovenčina',
    icon: 'flags/SVK',
  },
  de: {
    title: 'Deutsch',
    icon: 'flags/DEU',
  },
  en: {
    title: 'English',
    icon: 'flags/GBR',
  },
  es: {
    title: 'Español',
    icon: 'flags/ESP',
  },
  fr: {
    title: 'Français',
    icon: 'flags/FRA',
  },
  it: {
    title: 'Italiano',
    icon: 'flags/ITA',
  },
  pl: {
    title: 'Polski',
    icon: 'flags/POL',
  },
} as const;
