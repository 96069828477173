import {Box, Show} from 'platform/foundation';

import {Fragment} from 'react';

import {isPositive} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {Separator} from '../Separator/Separator';
import {AttributesItem, AttributesItemProps} from './AttributesItem';

export interface AttributesProps extends TestIdProps {
  rows?: AttributesItemProps[];
}

export function Attributes(props: AttributesProps) {
  return (
    <Box
      border="1px solid"
      borderRadius="medium"
      borderColor="border.emphasisDefault"
      width="100%"
      data-testid={suffixTestId('attributes', props)}
    >
      {props.rows?.map((row, index) => (
        <Fragment key={`${row.label}-${index}`}>
          <Show when={isPositive(index)}>
            <Separator />
          </Show>
          <AttributesItem label={row.label} text={row.text} flag={row.flag} />
        </Fragment>
      ))}
    </Box>
  );
}
