import {LocaleContextValue} from 'platform/locale';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {SectionConfig} from '../types/SectionConfig';
import {SlideImage} from '../types/SlideImage';
import {getAuditAssignee} from '../utils/getAuditAssignee';
import {getIfIsDataEmpty} from '../utils/getIfIsDataEmpty';
import {getPhotosByKeys} from '../utils/getPhotosByKeys';
import {getWheels} from '../utils/getWheels';
import {getWheelsAndTires} from '../utils/getWheelsAndTires';

export type WheelTireDataBundle = {
  wheels: ReturnType<typeof getWheelsAndTires>;
  assignee: {name: string | null; description: string} | null;
  photos: SlideImage[] | null;
  sectionConfig: SectionConfig;
};
export const useGetDataForWheelsAndTires = (
  vehicleAudit: VehicleAudit | null | undefined,
  locale: LocaleContextValue
): WheelTireDataBundle => {
  const wheels = getWheelsAndTires({vehicleAudit, language: locale.language});

  const resultWheels = getWheels(wheels);

  const assignee = getAuditAssignee(vehicleAudit);
  const photos = getPhotosByKeys({
    vehicleAudit,
    parentUniqueKey: 'WHEELS_AND_TIRES',
  });
  const isAllDataEmpty = getIfIsDataEmpty(resultWheels).isAllDataEmpty;

  const shouldBeHidden = resultWheels === null || isAllDataEmpty;

  return {
    wheels,
    assignee,
    photos,
    sectionConfig: {isHidden: shouldBeHidden, name: 'WHEELS_AND_TIRES'},
  };
};
