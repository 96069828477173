import {Nullish} from 'shared';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {getAdditionalRelatedActionsByUniqueKeys} from './getAdditionalRelatedActionsByUniqueKeys';
import {getAuditFieldValue} from './getAuditFieldValue';
import {getAuditFieldValueWithUnit} from './getAuditFieldValueWithUnit';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';

export function getTestDrive(vehicleAudit: VehicleAudit | Nullish) {
  if (!vehicleAudit || !vehicleAudit.audit.categories || !vehicleAudit.auditStructure.categories) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: 'TESTDRIVE',
    uniqueKey: 'TESTDRIVE',
    categories: vehicleAudit.auditStructure.categories,
  });
  const additionalRelatedActions = getAdditionalRelatedActionsByUniqueKeys({
    parentUniqueKey: 'TESTDRIVE',
    uniqueKey: 'TESTDRIVE',
    categories: vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length) {
    return null;
  }

  return {
    state: getAuditFieldValue({
      key: 'testDriveState',
      relatedActions,
      auditCategories: vehicleAudit.audit.categories,
    }),
    title: {
      driver: getAuditFieldValue({
        key: 'testDriveDriver',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      testDriveDate: getAuditFieldValue({
        key: 'testDriveDate',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
    },
    mechanic: {
      name: getAuditFieldValue({
        key: 'testDriveDriver',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      comment: getAuditFieldValue({
        key: 'testDriveComment',
        relatedActions: additionalRelatedActions ?? [],
        auditCategories: vehicleAudit.audit.categories,
      }),
    },
    content: {
      distance: getAuditFieldValueWithUnit({
        key: 'testDriveDistance',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      avgSpeed: getAuditFieldValueWithUnit({
        key: 'testDriveAvgSpeed',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      maxSpeed: getAuditFieldValueWithUnit({
        key: 'testDriveMaxSpeed',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      driveStart: getAuditFieldValue({
        key: 'testDriveStart',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      driveEnd: getAuditFieldValue({
        key: 'testDriveEnd',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      duration: getAuditFieldValue({
        key: 'testDriveDuration',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
      weakGpsSignal: getAuditFieldValue({
        key: 'testDriveWeakGpsSignal',
        relatedActions,
        auditCategories: vehicleAudit.audit.categories,
      }),
    },
  };
}
