import {APPLICATION_NAME} from '@digital-certificate/config';
import {Box, Hide, Show} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';

import {Documentation} from './(sections)/Documentation/Documentation';
import {DocumentationPrint} from './(sections)/Documentation/Documentation.print';
import {Error} from './(sections)/Error/Error';
import {Exterior} from './(sections)/Exterior/Exterior';
import {ExteriorPrint} from './(sections)/Exterior/Exterior.print';
import {Features} from './(sections)/Features/Features';
import {FeaturesPrint} from './(sections)/Features/Features.print';
import {Hero} from './(sections)/Hero/Hero';
import {HeroPrint} from './(sections)/Hero/Hero.print';
import {Interior} from './(sections)/Interior/Interior';
import {InteriorPrint} from './(sections)/Interior/Interior.print';
import {Mechanics} from './(sections)/Mechanics/Mechanics';
import {MechanicsPrint} from './(sections)/Mechanics/Mechanics.print';
import {PhotoDocumentation} from './(sections)/PhotoDocumentation/PhotoDocumentation';
import {PhotoDocumentationPrint} from './(sections)/PhotoDocumentation/PhotoDocumentation.print';
import {ServiceHistory} from './(sections)/ServiceHistory/ServiceHistory';
import {ServiceHistoryPrint} from './(sections)/ServiceHistory/ServiceHistory.print';
import {Specification} from './(sections)/Specification/Specification';
import {SpecificationPrint} from './(sections)/Specification/Specification.print';
import {TestDrive} from './(sections)/TestDrive/TestDrive';
import {TestDrivePrint} from './(sections)/TestDrive/TestDrive.print';
import {WheelsAndTires} from './(sections)/WheelsAndTires/WheelsAndTires';
import {WheelsAndTiresPrint} from './(sections)/WheelsAndTires/WheelsAndTires.print';
import {ApplicationInitializeBoundary} from './components/ApplicationInitializeBoundary/ApplicationInitializeBoundary';
import {Footer} from './components/Footer/Footer';
import {Navigation} from './components/Navigation/Navigation';
import {NavigationTabs} from './components/NavigationTabs/NavigationTabs';
import {getActiveSections, isSectionActive} from './components/Section/utils/getActiveSections';
import {useGetDataForWheelsAndTires} from './hooks/useGetDataForWheelsAndTires';
import {useGetDigitalCertificateData} from './hooks/useGetDigitalCertificateData';
import {useGetSectionsData} from './hooks/useGetSectionData';
import i18n from './i18n/i18n';

export function App() {
  return (
    <Routes>
      <Route element={<ApplicationInitializeBoundary />}>
        <Route path=":auditId/" element={<DigitalCertificate />} />
        <Route path=":auditId/pdf" element={<DigitalCertificatePrint />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
function DigitalCertificate() {
  const {
    hiddenSections,
    sections,
    documentationData,
    mechanicsData,
    serviceHistoryData,
    testDriveData,
    featuresData,
    wheelsAndTiresData,
    photoDocumentationData,
    canBeSectionHide,
  } = useGetSectionsData();

  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <Navigation sections={sections} />
      <Hero />
      <Hide onMobile>
        <NavigationTabs sections={sections} hiddenSections={hiddenSections} />
      </Hide>
      <Specification />
      <Show when={isSectionActive('EXTERIOR', sections)}>
        <Exterior />
      </Show>
      <Show when={isSectionActive('INTERIOR', sections)}>
        <Interior />
      </Show>
      <Show
        when={
          isSectionActive('TESTDRIVE', sections) &&
          !(canBeSectionHide && testDriveData.sectionConfig.isHidden)
        }
      >
        <TestDrive {...testDriveData} />
      </Show>
      <Show
        when={
          isSectionActive('WHEELS_AND_TIRES', sections) &&
          !(canBeSectionHide && wheelsAndTiresData.sectionConfig.isHidden)
        }
      >
        <WheelsAndTires {...wheelsAndTiresData} />
      </Show>
      <Show
        when={
          isSectionActive('MECHANICS', sections) &&
          !(canBeSectionHide && mechanicsData.sectionConfig.isHidden)
        }
      >
        <Mechanics {...mechanicsData} />
      </Show>
      <Show when={isSectionActive('EQUIPMENT', sections) && !featuresData.sectionConfig.isHidden}>
        <Features {...featuresData} />
      </Show>
      <Show
        when={
          isSectionActive('PHOTODOCUMENTATION', sections) &&
          !(canBeSectionHide && photoDocumentationData.sectionConfig.isHidden)
        }
      >
        <PhotoDocumentation {...photoDocumentationData} />
      </Show>
      <Show
        when={
          isSectionActive('VEHICLE_DOCUMENTATION', sections) &&
          !(canBeSectionHide && documentationData.sectionConfig.isHidden)
        }
      >
        <Documentation {...documentationData} />
      </Show>
      <Show
        when={
          isSectionActive('SERVICE_HISTORY', sections) &&
          !(canBeSectionHide && serviceHistoryData.sectionConfig.isHidden)
        }
      >
        <ServiceHistory {...serviceHistoryData} />
      </Show>
      <Footer />
    </HelmetProvider>
  );
}

function DigitalCertificatePrint() {
  const {vehicleAudit} = useGetDigitalCertificateData();
  const locale = useLocale();

  const wheelsAndTiresData = useGetDataForWheelsAndTires(vehicleAudit, locale);

  const sections = getActiveSections(vehicleAudit?.auditStructure?.categories);

  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <Box maxWidth={278} width={278}>
        <HeroPrint />
        <SpecificationPrint />
        <Show when={isSectionActive('EXTERIOR', sections)}>
          <ExteriorPrint />
        </Show>
        <Show when={isSectionActive('INTERIOR', sections)}>
          <InteriorPrint />
        </Show>
        <Show when={isSectionActive('TESTDRIVE', sections)}>
          <TestDrivePrint />
        </Show>
        <Show
          when={
            isSectionActive('WHEELS_AND_TIRES', sections) &&
            !wheelsAndTiresData.sectionConfig.isHidden
          }
        >
          <WheelsAndTiresPrint {...wheelsAndTiresData} />
        </Show>
        <Show when={isSectionActive('MECHANICS', sections)}>
          <MechanicsPrint />
        </Show>
        <Show when={isSectionActive('EQUIPMENT', sections)}>
          <FeaturesPrint />
        </Show>
        <Show when={isSectionActive('PHOTODOCUMENTATION', sections)}>
          <PhotoDocumentationPrint />
        </Show>
        <Show when={isSectionActive('VEHICLE_DOCUMENTATION', sections)}>
          <DocumentationPrint />
        </Show>
        <Show when={isSectionActive('SERVICE_HISTORY', sections)}>
          <ServiceHistoryPrint />
        </Show>
      </Box>
    </HelmetProvider>
  );
}
