import {Box, Hide, HStack, Show, VStack, Image} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import {match} from 'ts-pattern';

import {useEffect, useMemo, useRef} from 'react';

import {__, all, always, filter, gt, keys, length, pipe, values} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isPositive, isTrue} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

import InteriorBackOverlay from '../../assets/images/interior-back-overlay.svg';
import InteriorFrontOverlay from '../../assets/images/interior-front-overlay.svg';
import {BannerSuccess} from '../../components/BannerSuccess/BannerSuccess';
import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {DamageCarouselPrint} from '../../components/DamageCarousel/DamageCarousel.print';
import {getDamageCarouselDataFromMap} from '../../components/DamageCarousel/utils/getDamageCarouselDataFromMap';
import {ListFeaturesItemVariant} from '../../components/ListFeatures/components/ListFeaturesItem';
import {ListFeatures} from '../../components/ListFeatures/ListFeatures';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {Separator} from '../../components/Separator/Separator';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getAuditAssignee} from '../../utils/getAuditAssignee';
import {getDamageValues} from '../../utils/getDamageValues';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';
import {getInterior} from './utils/getInterior';

export function InteriorPrint() {
  const imagesRef = useRef<HTMLDivElement>(null);

  const locale = useLocale();

  const {vehicleAudit} = useGetDigitalCertificateData();
  const assignee = getAuditAssignee(vehicleAudit);

  const damageValues = useMemo(
    () => ({
      coverRightPillar: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'COLUMN_TRIM_ON_THE_RIGHT',
        point: 'coverRightPillar',
      }),
      coverLeftPillar: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'COLUMN_TRIM_ON_THE_LEFT',
        point: 'coverLeftPillar',
      }),
      coverFrontRightDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'RF_DOOR_TRIM',
        point: 'coverFrontRightDoor',
      }),

      coverFrontLeftDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'LF_DOOR_TRIM',
        point: 'coverFrontLeftDoor',
      }),

      cockpit: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'DASHBOARD',
        point: 'cockpit',
      }),

      steeringWheel: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'DRIVING_WHEEL',
        point: 'steeringWheel',
      }),

      driverSeat: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'DRIVERS_SEAT',
        point: 'driverSeat',
      }),

      passengerSeat: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'PASSENGER_SEAT',
        point: 'passengerSeat',
      }),

      coverBackLeftDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'LR_DOOR_TRIM',
        point: 'coverBackLeftDoor',
      }),

      coverBackRightDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'RR_DOOR_TRIM',
        point: 'coverBackRightDoor',
      }),

      backSeats: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'BACKBENCH',
        point: 'backSeats',
      }),

      ceiling: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'CEILINGS',
        point: 'ceiling',
      }),

      carpet: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'CARPET',
        point: 'carpet',
      }),

      other: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'INTERIOR',
        uniqueKey: 'OTHERS',
        point: 'other',
      }),
    }),
    [locale.language, vehicleAudit]
  );

  const carouselData = getDamageCarouselDataFromMap(Object.values(damageValues));

  const isStatusOkay = all(isTrue, [
    isNilOrEmpty(damageValues.backSeats?.values),
    isNilOrEmpty(damageValues.carpet?.values),
    isNilOrEmpty(damageValues.ceiling?.values),
    isNilOrEmpty(damageValues.cockpit?.values),
    isNilOrEmpty(damageValues.coverBackLeftDoor?.values),
    isNilOrEmpty(damageValues.coverBackRightDoor?.values),
    isNilOrEmpty(damageValues.coverFrontLeftDoor?.values),
    isNilOrEmpty(damageValues.coverFrontRightDoor?.values),
    isNilOrEmpty(damageValues.coverLeftPillar?.values),
    isNilOrEmpty(damageValues.coverRightPillar?.values),
    isNilOrEmpty(damageValues.driverSeat?.values),
    isNilOrEmpty(damageValues.other?.values),
    isNilOrEmpty(damageValues.passengerSeat?.values),
    isNilOrEmpty(damageValues.steeringWheel?.values),
  ]);

  const damagesData = useMemo(
    () => ({
      ceiling: damageValues.ceiling?.values?.length ?? 0,
      backSeats: damageValues.backSeats?.values?.length ?? 0,
      coverRightPillar: damageValues.coverRightPillar?.values?.length ?? 0,
      coverLeftPillar: damageValues.coverLeftPillar?.values?.length ?? 0,
      coverFrontRightDoor: damageValues.coverFrontRightDoor?.values?.length ?? 0,
      coverFrontLeftDoor: damageValues.coverFrontLeftDoor?.values?.length ?? 0,
      coverBackLeftDoor: damageValues.coverBackLeftDoor?.values?.length ?? 0,
      coverBackRightDoor: damageValues.coverBackRightDoor?.values?.length ?? 0,
      passengerSeat: damageValues.passengerSeat?.values?.length ?? 0,
      driverSeat: damageValues.driverSeat?.values?.length ?? 0,
      carpet: damageValues.carpet?.values?.length ?? 0,
      steeringWheel: damageValues.steeringWheel?.values?.length ?? 0,
      cockpit: damageValues.cockpit?.values?.length ?? 0,
      other: damageValues.other?.values?.length ?? 0,
    }),
    [damageValues]
  );

  useEffect(() => {
    if (!damagesData) {
      return;
    }
    showOverlay(damagesData);
  }, [damagesData]);

  const interior = getInterior({vehicleAudit, language: locale.language});

  const countDamages: (object: object) => number = pipe(values, filter(gt(__, 0)), length);

  if (!interior) {
    return null;
  }

  return (
    <SectionPrint
      icon="interior"
      heading={i18n.t('interiorHeader')}
      header={
        assignee?.name && isNotNilOrEmpty(interior?.comment) ? (
          <CommentMechanic name={assignee.name} comment={interior?.comment} />
        ) : null
      }
      flag={
        isStatusOkay
          ? {severity: 'good', text: i18n.t('sectionState.good')}
          : {
              severity: 'damage',
              text: getRecordsTranslate(countDamages(damagesData)),
            }
      }
    >
      <VStack spacing={10} width="100%">
        <Hide when={isTrue(isStatusOkay)}>
          <HStack spacing={6}>
            <Box position="relative" flex={1} ref={imagesRef}>
              <Image
                borderRadius="small"
                src="../../assets/images/interior-front.jpg"
                width="100%"
                height="auto"
              />
              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                <InteriorFrontOverlay />
              </Box>
            </Box>

            <Box position="relative" flex={1}>
              <Image
                borderRadius="small"
                src="../../assets/images/interior-back.jpg"
                width="100%"
                height="auto"
              />
              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                <InteriorBackOverlay />
              </Box>
            </Box>
          </HStack>
          <Show when={isNotNilOrEmpty(carouselData)}>
            <Separator />
            <DamageCarouselPrint data={carouselData} />
          </Show>
        </Hide>
        <Show when={isTrue(isStatusOkay)}>
          <BannerSuccess />
        </Show>
        <Show when={isNotNilOrEmpty(interior.features)}>
          <Separator />
          <ListFeatures
            columns={2}
            spacing={3}
            features={interior.features.map((f) => ({
              title: f.name ?? EMPTY_PLACEHOLDER,
              description: f.value === 'true' ? i18n.t('yes') : null,
              variant: match<string | Nullish, ListFeaturesItemVariant>(f.value)
                .with('true', always('ready'))
                .with('false', always('damaged'))
                .otherwise(always('damaged')),
            }))}
          />
        </Show>
      </VStack>
    </SectionPrint>
  );
}

const showOverlay = (points: Record<string, number>) => {
  keys(points).forEach((key) => {
    const count = points[key];
    if (isPositive(count)) {
      const partFrontTextElement = document.getElementById(`interior-front-${key}-text`);
      const partFrontPointElement = document.getElementById(`interior-front-${key}-point`);
      const partFrontOverlayElement = document.getElementById(`interior-front-${key}-overlay`);
      if (partFrontTextElement) {
        partFrontTextElement.textContent = count.toString();
      }
      if (partFrontOverlayElement) {
        partFrontOverlayElement.style.opacity = '0.3';
      }
      if (partFrontPointElement) {
        partFrontPointElement.style.opacity = '1';
        partFrontPointElement.style.transform = 'scale(1)';
      }

      const partTextElement = document.getElementById(`interior-back-${key}-text`);
      const partPointElement = document.getElementById(`interior-back-${key}-point`);
      const partOverlayElement = document.getElementById(`interior-back-${key}-overlay`);
      if (partTextElement) {
        partTextElement.textContent = count.toString();
      }
      if (partOverlayElement) {
        partOverlayElement.style.opacity = '0.3';
      }
      if (partPointElement) {
        partPointElement.style.opacity = '1';
        partPointElement.style.transform = 'scale(1)';
      }
    }
  });
};
