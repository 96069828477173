import {getValueByDevice, useDevice, makeImageUrl} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {CardImage} from '../../../components/CardImage/CardImage';
import {SlideImage} from '../../../types/SlideImage';

interface HeroCardImage {
  title: string;
  slideImages: SlideImage[];
}

export function HeroCardImage(props: HeroCardImage) {
  const device = useDevice();
  const [heroCardLightboxControls, {onOpen}] = useLightbox(props.title);

  return (
    <>
      <Lightbox
        data-testid="heroAll"
        controls={heroCardLightboxControls}
        images={props.slideImages}
      />
      <CardImage
        onClick={onOpen}
        height="100%"
        maxHeight="100%"
        title={props.title}
        src={makeImageUrl(
          props.slideImages[0].resizeUrl,
          getValueByDevice(device, 100, 65, 65, 65)
        )}
      />
    </>
  );
}
