import {AnimatePresence, Variants, motion} from 'framer-motion';

import {ReactNode} from 'react';

interface CollapseProps {
  isOpen: boolean;
  children: ReactNode;
}

export function Collapse(props: CollapseProps) {
  return (
    <AnimatePresence initial={false} custom={custom}>
      <motion.div
        style={motionStyle}
        variants={variants}
        animate={props.isOpen ? 'enter' : 'exit'}
        custom={custom}
        initial={false}
        exit="exit"
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
}

const variants: Variants = {
  exit: ({startingHeight, transitionEnd}) => ({
    overflow: 'hidden',
    height: startingHeight,
    transitionEnd: transitionEnd?.exit,
    transition: {
      height: {duration: 0.2, ease},
      opacity: {duration: 0.3, ease},
    },
  }),
  enter: ({endingHeight, transitionEnd}) => ({
    height: endingHeight,
    transitionEnd: transitionEnd?.enter,
    transition: {
      height: {duration: 0.3, ease},
      opacity: {duration: 0.4, ease},
    },
  }),
};

const ease = [0.25, 0.1, 0.25, 1];
const motionStyle = {
  overflow: 'hidden',
  display: 'block',
};

const custom = {
  startingHeight: 0,
  endingHeight: 'auto',
  animateOpacity: true,
  transitionEnd: {
    enter: {overflow: 'initial'},
    exit: {
      display: 'none',
    },
  },
};
