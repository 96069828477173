import {FLAGSMITH_URL} from '@digital-certificate/config';
import {environment} from '@digital-certificate/environment';

import {useEffect, useLayoutEffect} from 'react';
import TagManager from 'react-gtm-module';
import {Outlet, useParams} from 'react-router-dom';

import {any, isNotNil, keys} from 'ramda';
import {isFalse, isNotNilOrEmpty, isTrue, noop} from 'ramda-adjunct';

import {useFlagsmith} from 'shared';

import {Error} from '../../(sections)/Error/Error';
import {Loading} from '../../(sections)/Loading/Loading';
import {
  useLazyGetAllApiEnumsQuery,
  useLazyGetVehicleAuditQuery,
  useLazyGetVehicleCatalogueByMakeQuery,
} from '../../api/digitalCertificateApi';
import {languageDialogItems} from '../../consts/languageDialogItems';
import {queryParams} from '../../consts/queryParams';
import {FALLBACK_LANGUAGE} from '../../i18n/fallbackLanguage';
import {i18n} from '../../i18n/i18n';
import {getWorkspaceFromUri} from '../../utils/getWorkspaceFromUri';
import {useQueryState} from '../../utils/useQueryState';

const {workspace} = getWorkspaceFromUri();

const ONE_HOUR = 3600 * 1000;

export function ApplicationInitializeBoundary() {
  const params = useParams<{auditId: string}>();
  const [language] = useQueryState(queryParams.LNG);

  const {isFlagSmithInitialized, handleInitFlagsmith} = useFlagsmith({
    apiUrl: FLAGSMITH_URL,
    environmentID: environment.FLAGSMITH_ENV,
    traits: workspace ? {workspace} : undefined,
    skip: false,
  });

  const [
    getVehicleAudit,
    {data: vehicleAudit, isFetching: isVehicleAuditFetching, isError: isVehicleAuditErrored},
  ] = useLazyGetVehicleAuditQuery({pollingInterval: ONE_HOUR});
  const [
    getAllApiEnums,
    {data: allApiEnums, isFetching: isAllApiEnumsFetching, isError: isAllApiEnumsErrored},
  ] = useLazyGetAllApiEnumsQuery({pollingInterval: ONE_HOUR});

  const [
    getVehicleCatalogue,
    {
      data: vehicleCatalogue,
      isFetching: isVehicleCatalogueByMakeQueryFetching,
      isError: isVehicleCatalogueByMakeQueryErrored,
    },
  ] = useLazyGetVehicleCatalogueByMakeQuery({pollingInterval: ONE_HOUR});

  const isFetching = any(isTrue, [
    isVehicleAuditFetching,
    isAllApiEnumsFetching,
    isVehicleCatalogueByMakeQueryFetching,
  ]);

  const isErrored = any(isTrue, [
    isVehicleAuditErrored,
    isAllApiEnumsErrored,
    isVehicleCatalogueByMakeQueryErrored,
  ]);

  const isAppReady = isNotNil(vehicleAudit) && isNotNil(allApiEnums) && isNotNil(vehicleCatalogue);

  useLayoutEffect(() => {
    if (isSupportedLanguage(language) && language !== i18n?.resolvedLanguage) {
      i18n.changeLanguage(language!);
    }

    if (isErrored) {
      return;
    }

    if (!isAppReady) {
      handleInitFlagsmith(`${workspace}__${params.auditId}`);
      // TODO: add sentry capture
      getVehicleAudit({workspace, auditId: params.auditId})
        .unwrap()
        .then((data) => {
          if (!data) {
            return;
          }
          getVehicleCatalogue({
            vehicleType: data.vehicle.type,
            make: data.vehicle.make,
            lang: language ?? i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE,
          })
            .unwrap()
            .catch(noop);
          getAllApiEnums({lang: language ?? i18n?.resolvedLanguage})
            .unwrap()
            .catch(noop);
        })
        .catch(noop);
    }
  }, [
    getAllApiEnums,
    getVehicleAudit,
    getVehicleCatalogue,
    handleInitFlagsmith,
    isAppReady,
    isErrored,
    language,
    params.auditId,
  ]);

  useEffect(() => {
    if (!isAppReady || environment.ENV_TYPE !== 'prod') {
      return;
    }

    const dataLayer = {
      event: 'applicationInitializeBoundary',
      workspace,
      environment: environment.ENV_TYPE,
      auditId: vehicleAudit.audit.id,
      vehicleType: vehicleAudit.vehicle.type,
      make: vehicleAudit.vehicle.make,
      language: language ?? i18n.language,
    };

    TagManager.dataLayer({dataLayer});
  }, [isAppReady, language, vehicleAudit]);

  if (isErrored) {
    return <Error />;
  }

  if (isFetching || isFalse(isAppReady) || isFalse(isFlagSmithInitialized)) {
    return <Loading />;
  }

  return <Outlet />;
}

const isSupportedLanguage = (lng: any) =>
  isNotNilOrEmpty(lng) && keys(languageDialogItems).includes(lng);
