import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../../../api/digitalCertificateApi';
import {getAuditFieldValue} from '../../../utils/getAuditFieldValue';
import {getRelatedActionByComparisonKey} from '../../../utils/getRelatedActionByComparisonKey';

export const getWheelFeatures = (
  relatedActions: RelatedAction[] | Nullish,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (!auditCategories) {
    return null;
  }

  const safetyNutsAction = getRelatedActionByComparisonKey(
    'wheelsAndTiresSafetyNuts',
    relatedActions
  );

  const safetyNuts = getAuditFieldValue({
    key: 'wheelsAndTiresSafetyNuts',
    relatedActions,
    auditCategories,
  });

  const adapterForSafetyActions = safetyNutsAction?.values?.find((v) => v.value === safetyNuts)
    ?.content?.relatedActions;

  return {
    tireReplacement: getAuditFieldValue({
      key: 'wheelsAndTiresTyreReplacement',
      relatedActions,
      auditCategories,
    }),
    spareWheel: getAuditFieldValue({
      key: 'wheelsAndTiresSpareWheel',
      relatedActions,
      auditCategories,
    }),
    safetyNuts,
    adapterForSafety: getAuditFieldValue({
      key: 'wheelsAndTiresSafetyAdapter',
      relatedActions: adapterForSafetyActions,
      auditCategories,
    }),
  };
};
