export const removeDamagePointListeners = (
  pointEvents: Array<{
    element: HTMLElement | null;

    handleHover: () => void;
    handleClick: () => void;
  }>
) => {
  pointEvents.forEach((key) => {
    key?.element?.removeEventListener('mouseover', key.handleHover);
    key?.element?.removeEventListener('click', key.handleClick);
  });
};
