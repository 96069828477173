export const comparisonKeys = {
  documentationVin: 'paramDefinition2647b',
  documentationVinComment: 'paramDefinition5289e',
  documentationStatus: 'paramDefinitionea58e',
  documentationDateOfRegistration: 'paramDefinition872a2',
  documentationDateOfRegistrationComment: 'paramDefinitione0d48',
  documentationFirstRegistration: 'paramDefinition8263a',
  documentationFirstRegistrationComment: 'paramDefinition24e58',
  documentationServiceBookType: 'paramDefinitionb1fff',
  documentationServiceBookState: 'paramDefinitiona9395',
  documentationCurrentMileage: 'paramDefinitionbc7e4',
  documentationCurrentMileageComment: 'paramDefinitione4d4d',
  documentationCocListStatus: 'paramDefinition9224c',
  documentationTechnicalCertStatus: 'paramDefinitionea58e',
  documentationNumberOfPartsOfRegistrationCertificate: 'paramDefinition8eb5b',
  documentationNumberOfWwnersInTC: 'paramDefinitiondea07',
  documentationCountryOfFirstRegistration: 'paramDefinitionc985e',
  documentationTechnicalCertComment: 'paramDefinition8ce10',
  documentationVehicleUnderFactoryWarrantyStatus: 'paramDefinition1146b',
  documentationVehicleUnderFactoryWarrantyStarDate: 'paramDefinitiondd0d0',
  documentationVehicleUnderFactoryWarrantyEndDate: 'paramDefinition3911a',
  documentationRaidUntilEndOfWarranty: 'paramDefinition0c418',
  documentationOperatingInstructions: 'paramDefinition6b74c',
  documentationLanguageOfInstructions: 'paramDefinition10466',
  documentationMandatoryEquipmentAndAccessories: 'paramDefinitione382a',
  documentationMandatoryEquipmentAndAccessoriesComment: 'paramDefinitiona187b',

  testDriveState: 'paramDefinitiona372c',
  testDriveDriver: 'paramDefinition8e425',
  testDriveDate: 'paramDefinition648fc',
  testDriveComment: 'paramDefinitionad9fb',
  testDriveDistance: 'paramDefinition4d801',
  testDriveAvgSpeed: 'paramDefinition38301',
  testDriveMaxSpeed: 'paramDefinition17dc0',
  testDriveStart: 'paramDefinition2e7dd',
  testDriveEnd: 'paramDefinitionacb8e',
  testDriveDuration: 'paramDefinitionaafee',
  testDriveWeakGpsSignal: 'paramDefinitiond46ac',

  specificationComment: 'paramDefinition6944d',

  wheelsAndTiresIsSecondSetEnabled: 'paramDefinitionab5b9',
  wheelsAndTiresCompletedSecondSetStatus: 'paramDefinitioneec92',
  wheelsAndTiresGeneralComment: 'paramDefinition2a451',
  wheelsAndTiresIsDimensionCorresponds: 'paramDefinition1d0d4',
  wheelsAndTiresFrontLeftComment: 'paramDefinitionc4188',
  wheelsAndTiresFrontLeftPhoto: 'paramDefinitione4934',
  wheelsAndTiresFrontLeftDiameter: 'paramDefinitionca4e0',
  wheelsAndTiresFrontLeftStatusOfRim: 'paramDefinition7fa2e',
  wheelsAndTiresFrontLeftDisk: 'paramDefinition5be0a',
  wheelsAndTiresFrontLeftTire: 'paramDefinition8625d',
  wheelsAndTiresFrontLeftDepth: 'paramDefinition68643',
  wheelsAndTiresFrontLeftWidth: 'paramDefinitionc7f83',
  wheelsAndTiresFrontLeftHeight: 'paramDefinition575a6',
  wheelsAndTiresFrontRightComment: 'paramDefinition41fe5',
  wheelsAndTiresFrontRightPhoto: 'paramDefinition1b9df',
  wheelsAndTiresFrontRightDisk: 'paramDefinitionf332e',
  wheelsAndTiresFrontRightDiameter: 'paramDefinition03d76',
  wheelsAndTiresFrontRightStatusOfRim: 'paramDefinitionbd0a6',
  wheelsAndTiresFrontRightTire: 'paramDefinitionf8569',
  wheelsAndTiresFrontRightDepth: 'paramDefinitiona70fe',
  wheelsAndTiresFrontRightWidth: 'paramDefinition91aaf',
  wheelsAndTiresFrontRightHeight: 'paramDefinition5b919',
  wheelsAndTiresRearLeftComment: 'paramDefinitionb8032',
  wheelsAndTiresRearLeftPhoto: 'paramDefinitionab1f3',
  wheelsAndTiresRearLeftDisk: 'paramDefinitionaf023',
  wheelsAndTiresRearLeftStatusOfRim: 'paramDefinition40072',
  wheelsAndTiresRearLeftDiameter: 'paramDefinition45a8b',
  wheelsAndTiresRearLeftTire: 'paramDefinitionea767',
  wheelsAndTiresRearLeftDepth: 'paramDefinition5e2f6',
  wheelsAndTiresRearLeftWidth: 'paramDefinitionab57f',
  wheelsAndTiresRearLeftHeight: 'paramDefinitionbe099',
  wheelsAndTiresRearRightComment: 'paramDefinition31a35',
  wheelsAndTiresRearRightPhoto: 'paramDefinition998a6',
  wheelsAndTiresRearRightDisk: 'paramDefinition8ba75',
  wheelsAndTiresRearRightStatusOfRim: 'paramDefinition4847b',
  wheelsAndTiresRearRightDiameter: 'paramDefinitionfd6e8',
  wheelsAndTiresRearRightTire: 'paramDefinition71ccb',
  wheelsAndTiresRearRightDepth: 'paramDefinition318dc',
  wheelsAndTiresRearRightWidth: 'paramDefinitiona6dd3',
  wheelsAndTiresRearRightHeight: 'paramDefinition08b3b',
  wheelsAndTiresTyreReplacement: 'paramDefinition78935',
  wheelsAndTiresSpareWheel: 'paramDefinition06962',
  wheelsAndTiresSafetyNuts: 'paramDefinitionddb9d',
  wheelsAndTiresSafetyAdapter: 'paramDefinitionbb9c7',
  wheelsAndTiresCompletedSecondSetFrontLeftComment: 'paramDefinition331fb',
  wheelsAndTiresCompletedSecondSetFrontLeftPhoto: 'paramDefinition17cc3',
  wheelsAndTiresCompletedSecondSetFrontLeftDisk: 'paramDefinitionc13ba',
  wheelsAndTiresCompletedSecondSetFrontLeftStatusOfRim: 'paramDefinition6d075',
  wheelsAndTiresCompletedSecondSetFrontLeftDiameter: 'paramDefinition3a49e',
  wheelsAndTiresCompletedSecondSetFrontLeftTire: 'paramDefinition85af6',
  wheelsAndTiresCompletedSecondSetFrontLeftDepth: 'paramDefinitionc43d8',
  wheelsAndTiresCompletedSecondSetFrontLeftWidth: 'paramDefinition146a9',
  wheelsAndTiresCompletedSecondSetFrontLeftHeight: 'paramDefinitionf1ee7',
  wheelsAndTiresCompletedSecondSetFrontRightComment: 'paramDefinitionb9920',
  wheelsAndTiresCompletedSecondSetFrontRightPhoto: 'paramDefinition8b60d',
  wheelsAndTiresCompletedSecondSetFrontRightDisk: 'paramDefinition10af0',
  wheelsAndTiresCompletedSecondSetFrontRightStatusOfRim: 'paramDefinition58a55',
  wheelsAndTiresCompletedSecondSetFrontRightDiameter: 'paramDefinition94f4c',
  wheelsAndTiresCompletedSecondSetFrontRightTire: 'paramDefinition1fd87',
  wheelsAndTiresCompletedSecondSetFrontRightDepth: 'paramDefinition291b7',
  wheelsAndTiresCompletedSecondSetFrontRightWidth: 'paramDefinition6031c',
  wheelsAndTiresCompletedSecondSetFrontRightHeight: 'paramDefinitionfbd1e',
  wheelsAndTiresCompletedSecondSetRearLeftComment: 'paramDefinition76eda',
  wheelsAndTiresCompletedSecondSetRearLeftPhoto: 'paramDefinitiona595e',
  wheelsAndTiresCompletedSecondSetRearLeftDisk: 'paramDefinitiondf4c8',
  wheelsAndTiresCompletedSecondSetRearLeftTire: 'paramDefinition3c63a',
  wheelsAndTiresCompletedSecondSetRearLeftStatusOfRim: 'paramDefinition14930',
  wheelsAndTiresCompletedSecondSetRearLeftDiameter: 'paramDefinition80b0a',
  wheelsAndTiresCompletedSecondSetRearLeftDepth: 'paramDefinition2100c',
  wheelsAndTiresCompletedSecondSetRearLeftWidth: 'paramDefinition02958',
  wheelsAndTiresCompletedSecondSetRearLeftHeight: 'paramDefinition8e11f',
  wheelsAndTiresCompletedSecondSetRearRightComment: 'paramDefinition4a7e2',
  wheelsAndTiresCompletedSecondSetRearRightPhoto: 'paramDefinition7af66',
  wheelsAndTiresCompletedSecondSetRearRightDisk: 'paramDefinition18660',
  wheelsAndTiresCompletedSecondSetRearRightStatusOfRim: 'paramDefinition4a5bc',
  wheelsAndTiresCompletedSecondSetRearRightDiameter: 'paramDefinition3740a',
  wheelsAndTiresCompletedSecondSetRearRightTire: 'paramDefinition2dccd',
  wheelsAndTiresCompletedSecondSetRearRightDepth: 'paramDefinitionf55fc',
  wheelsAndTiresCompletedSecondSetRearRightWidth: 'paramDefinition71a9c',
  wheelsAndTiresCompletedSecondSetRearRightHeight: 'paramDefinition8d4d9',
  wheelsAndTiresRimsOnlySecondSetFrontLeftComment: 'paramDefinitiona4c65',
  wheelsAndTiresRimsOnlySecondSetFrontLeftPhoto: 'paramDefinition51a14',
  wheelsAndTiresRimsOnlySecondSetFrontLeftDisk: 'paramDefinition76d3e',
  wheelsAndTiresRimsOnlySecondSetFrontLeftStatusOfRim: 'paramDefinition96c14',
  wheelsAndTiresRimsOnlySecondSetFrontLeftDiameter: 'paramDefinition3e215',
  wheelsAndTiresRimsOnlySecondSetFrontRightComment: 'paramDefinition2b4a3',
  wheelsAndTiresRimsOnlySecondSetFrontRightPhoto: 'paramDefinition45976',
  wheelsAndTiresRimsOnlySecondSetFrontRightDisk: 'paramDefinitione21a9',
  wheelsAndTiresRimsOnlySecondSetFrontRightStatusOfRim: 'paramDefinition1b03d',
  wheelsAndTiresRimsOnlySecondSetFrontRightDiameter: 'paramDefinition57f62',
  wheelsAndTiresRimsOnlySecondSetRearLeftComment: 'paramDefinition527a3',
  wheelsAndTiresRimsOnlySecondSetRearLeftPhoto: 'paramDefinitionadaa5',
  wheelsAndTiresRimsOnlySecondSetRearLeftDisk: 'paramDefinitionda8cd',
  wheelsAndTiresRimsOnlySecondSetRearLeftStatusOfRim: 'paramDefinition87d07',
  wheelsAndTiresRimsOnlySecondSetRearLeftDiameter: 'paramDefinitiona57ba',
  wheelsAndTiresRimsOnlySecondSetRearRightComment: 'paramDefinition78e3d',
  wheelsAndTiresRimsOnlySecondSetRearRightPhoto: 'paramDefinitionbcf10',
  wheelsAndTiresRimsOnlySecondSetRearRightDisk: 'paramDefinition25cea',
  wheelsAndTiresRimsOnlySecondSetRearRightStatusOfRim: 'paramDefinition58550',
  wheelsAndTiresRimsOnlySecondSetRearRightDiameter: 'paramDefinitionc2a8f',
  wheelsAndTiresTiresOnlySecondSetFrontLeftComment: 'paramDefinitiona99ee',
  wheelsAndTiresTiresOnlySecondSetFrontLeftPhoto: 'paramDefinition91edb',
  wheelsAndTiresTiresOnlySecondSetFrontLeftTire: 'paramDefinitionc09d2',
  wheelsAndTiresTiresOnlySecondSetFrontLeftDepth: 'paramDefinition873a6',
  wheelsAndTiresTiresOnlySecondSetFrontLeftWidth: 'paramDefinitione8cd7',
  wheelsAndTiresTiresOnlySecondSetFrontLeftHeight: 'paramDefinitiond31ce',
  wheelsAndTiresTiresOnlySecondSetFrontRightComment: 'paramDefinitiona8a86',
  wheelsAndTiresTiresOnlySecondSetFrontRightPhoto: 'paramDefinitioncff36',
  wheelsAndTiresTiresOnlySecondSetFrontRightTire: 'paramDefinition43456',
  wheelsAndTiresTiresOnlySecondSetFrontRightDepth: 'paramDefinition41299',
  wheelsAndTiresTiresOnlySecondSetFrontRightWidth: 'paramDefinitionb06f1',
  wheelsAndTiresTiresOnlySecondSetFrontRightHeight: 'paramDefinition402ca',
  wheelsAndTiresTiresOnlySecondSetRearLeftComment: 'paramDefinitiona0f03',
  wheelsAndTiresTiresOnlySecondSetRearLeftPhoto: 'paramDefinition75011',
  wheelsAndTiresTiresOnlySecondSetRearLeftTire: 'paramDefinition3da4a',
  wheelsAndTiresTiresOnlySecondSetRearLeftDepth: 'paramDefinitioncb2ba',
  wheelsAndTiresTiresOnlySecondSetRearLeftWidth: 'paramDefinition6bd47',
  wheelsAndTiresTiresOnlySecondSetRearLeftHeight: 'paramDefinition37fdf',
  wheelsAndTiresTiresOnlySecondSetRearRightComment: 'paramDefinition5c22c',
  wheelsAndTiresTiresOnlySecondSetRearRightPhoto: 'paramDefinition0b47c',
  wheelsAndTiresTiresOnlySecondSetRearRightTire: 'paramDefinition85042',
  wheelsAndTiresTiresOnlySecondSetRearRightDepth: 'paramDefinitionbf86c',
  wheelsAndTiresTiresOnlySecondSetRearRightWidth: 'paramDefinitionb066d',
  wheelsAndTiresTiresOnlySecondSetRearRightHeight: 'paramDefinition0173c',

  mechanicsBrakeSystemFuctionality: 'paramDefinitionee66d',
  mechanicsBrakeSystemFuctionalityComment: 'paramDefinition5dbaf',
  mechanicsBrakeSystemFuctionalityPhotos: 'paramDefinition320db',
  mechanicsEmergencyParkingBrakeSystem: 'paramDefinitione53e5',
  mechanicsEmergencyParkingBrakeSystemComment: 'paramDefinition5e55a',
  mechanicsBrakeFluid: 'paramDefinition93ea8',
  mechanicsBrakeFluidComment: 'paramDefinitioncfaf1',
  mechanicsBrakeDiscLF: 'paramDefinition55a79',
  mechanicsBrakeDiscLFComment: 'paramDefinitionbbb09',
  mechanicsBrakeDiscLR: 'paramDefinitiondf1cd',
  mechanicsBrakeDiscLRComment: 'paramDefinitionc6bff',
  mechanicsBrakeDiscRR: 'paramDefinitiona77e2',
  mechanicsBrakeDiscRRComment: 'paramDefinition63992',
  mechanicsBrakeDiscRF: 'paramDefinition239b6',
  mechanicsBrakeDiscRFComment: 'paramDefinition7d3cf',
  mechanicsBrakePadLF: 'paramDefinitionb9e29',
  mechanicsBrakePadLFComment: 'paramDefinitionb9e29',

  mechanicsBrakePadLR: 'paramDefinition48a83',
  mechanicsBrakePadRF: 'paramDefinition932ba',
  mechanicsBrakePadRR: 'paramDefinition10ffa',
  mechanicsBrakeLiningWearLF: 'paramDefinitionb9e30',
  mechanicsBrakeLiningWearLFComment: 'paramDefinitionb9e30',
  mechanicsBrakeLiningWearLR: 'paramDefinition48a84',
  mechanicsBrakeLiningWearLRComment: 'paramDefinition48a84',
  mechanicsBrakeLiningWearRF: 'paramDefinition932b1',
  mechanicsBrakeLiningWearRR: 'paramDefinition10ffc',
  mechanicsMotor: 'paramDefinition4183e',
  mechanicsMotorComment: 'paramDefinition5b9fd',
  mechanicsEngineCondition: 'paramDefinitiondb24a',
  mechanicsEngineConditionComment: 'paramDefinitioncee7a',
  mechanicsVehicleDrivetrain: 'paramDefinitionb1ffc',
  mechanicsVehicleDrivetrainComment: 'notDefinedYet',
  mechanicsLeakageOfOperatingFluidsFromEngine: 'paramDefinition29a5a',
  mechanicsLeakageOfOperatingFluidsFromEngineComment: 'paramDefinition0b7a7',
  mechanicsSteeringAssembly: 'paramDefinition7b605',
  mechanicsSteeringAssemblyComment: 'paramDefinition2a667',
  mechanicsTransmission: 'paramDefinitionbba74',
  mechanicsTransmissionComment: 'paramDefinitionc0b72',
  mechanicsLeakageOfOperatingFluidsFromGearbox: 'paramDefinitionc228f',
  mechanicsLeakageOfOperatingFluidsFromGearboxComment: 'paramDefinitionbfb9c',
  mechanicsPlayInGearbox: 'paramDefinitionda82c',
  mechanicsPlayInGearboxComment: 'paramDefinitionbc045',
  mechanicsOtherPartsOfDriveSystem: 'paramDefinitiond2514',
  mechanicsOtherPartsOfDriveSystemComment: 'paramDefinitiond2514',
  mechanicsLeakageOfOperatingFluidsFromOtherPartsDriveSystem: 'paramDefinition56898',
  mechanicsLeakageOfOperatingFluidsFromOtherPartsDriveSystemComment: 'paramDefinitionbd653',
  mechanicsPlayInOtherParts: 'paramDefinition384ed',
  mechanicsPlayInOtherPartsComment: 'paramDefinitiondd3b1',
  mechanicsCoolingSystem: 'paramDefinition9739f',
  mechanicsCoolingSystemComment: 'paramDefinition70ef7',
  mechanicsLeakageOfOperatingFluidsFromCoolingSystem: 'paramDefinitioneaac6',
  mechanicsLeakageOfOperatingFluidsFromCoolingSystemComment: 'paramDefinition13692',
  mechanicsFuelSystem: 'paramDefinitione2a4b',
  mechanicsFuelSystemComment: 'paramDefinitionfbbc5',
  mechanicsFuelLeakFromFuelSystem: 'paramDefinition46d9c',
  mechanicsFuelLeakFromFuelSystemComment: 'paramDefinitiond1f8f',
  mechanicsExhaustSystem: 'paramDefinition42e3d',
  mechanicsExhaustSystemComment: 'paramDefinition8a607',
  mechanicsExhaustSystemLeak: 'paramDefinition63321',
  mechanicsExhaustSystemLeakComment: 'paramDefinition08adf',
  mechanicsExhaustSystemCorrosion: 'paramDefinition87d52',
  mechanicsExhaustSystemCorrosionComment: 'paramDefinitionba3d7',
  mechanicsClutch: 'paramDefinitionc92dd',
  mechanicsClutchComment: 'paramDefinitione86df',
  mechanicsBattery: 'paramDefinitionc4d61',
  mechanicsBatteryComment: 'paramDefinitione63ad',
  mechanicsDPFCondition: 'paramDefinition951da',
  mechanicsDPFConditionComment: 'paramDefinitiona9cdf',
  mechanicsChassisStatus: 'paramDefinitionce826',
  mechanicsChassisStatusComment: 'paramDefinition25760',
  mechanicsChassisPlay: 'paramDefinition528a5',
  mechanicsChassisPlayComment: 'paramDefinition79e0f',
  mechanicsShockAbsorbers: 'paramDefinition15c72',
  mechanicsShockAbsorbersComment: 'paramDefinition97f6d',
  mechanicsVehicleAxel: 'paramDefinition94f36',
  mechanicsVehicleAxelComment: 'paramDefinition21be6',
  mechanicsStandardHeating: 'paramDefinitiona2df1',
  mechanicsStandardHeatingComment: 'paramDefinitionda958',
  mechanicsVehicleLightning: 'paramDefinition3985c',
  mechanicsVehicleLightningComment: 'paramDefinition61c33',
  mechanicsWarningLightsCheck: 'paramDefinition9985c',
  mechanicsWarningLightsCheckComment: 'not defined yet',
  mechanicsDiagnostics: 'paramDefinition223a7',
  mechanicsDiagnosticsComment: 'paramDefinitionbae83',
  mechanicsBodyCondition: 'paramDefinition6d69e',
  mechanicsBodyConditionComment: 'paramDefinition8137d',
  mechanicsTracesOfAccidentRepair: 'paramDefinitiond310d',
  mechanicsTracesOfAccidentRepairComment: 'paramDefinition57c86',
  mechanicsBodyCorrosion: 'paramDefinition2a4b6',
  mechanicsBodyCorrosionComment: 'paramDefinition7a69c',

  serviceHistoryDateOfLastService: 'paramDefinition17402',
  serviceHistoryDateOfLastServiceComment: 'paramDefinition2206c',
  serviceHistoryMileageOfLastService: 'paramDefinitionc6877',
  serviceHistoryDateOfNextService: 'paramDefinitionfa03e',
  serviceHistoryMileageOfNextService: 'paramDefinitiond451a',
  serviceHistoryRegularServiceIntervalPerformed: 'paramDefinition80df4',
  serviceHistoryDateOfLastOilChange: 'paramDefinition4cb35',
  serviceHistoryDateOfLastOilChangeComment: 'paramDefinitiona749c',
  serviceHistoryAmountOfKmDrivenFromOilChange: 'paramDefinitiond35ce',
  serviceHistoryDateOfLastBrakeFluidChange: 'paramDefinition6f696',
  serviceHistoryDateOfLastBrakeFluidChangeComment: 'paramDefinitionec085',
  serviceHistoryAmountOfKmDrivenFromBrakeFluidChange: 'paramDefinition095f0',
  serviceHistoryDateOfLastOilChangeInGearbox: 'paramDefinitionec9d8',
  serviceHistoryDateOfLastOilChangeInGearboxComment: 'paramDefinitionda48b',
  serviceHistoryAmountOfKmDrivenFromOilChangeInGearbox: 'paramDefinitionb6309',
  serviceHistoryDateOfLastOilChangeInDifferential: 'paramDefinitionf7a3f',
  serviceHistoryDateOfLastOilChangeInDifferentialComment: 'paramDefinitiond7e15',
  serviceHistoryAmountOfKmDrivenFromOilChangeInDifferential: 'paramDefinitione0352',

  interiorComment: 'paramDefinitiond20f6',
  interiorNoOdorIndoor: 'paramDefinitionf53f7',
  interiorVehicleNonSmoking: 'paramDefinitiond74b3',

  exteriorComment: 'paramDefinition01fcf',

  exteriorPaintDataHood: 'paramDefinitiona0c6f',
  exteriorPaintDataLFFender: 'paramDefinition22913',
  exteriorPaintDataLFDoor: 'paramDefinition2d2d1',
  exteriorPaintDataLRDoor: 'paramDefinitione93f2',
  exteriorPaintDataLRFender: 'paramDefinitione6610',
  exteriorPaintDataBackDoor: 'paramDefinition0f179',
  exteriorPaintDataRRFender: 'paramDefinition85637',
  exteriorPaintDataRRDoor: 'paramDefinitiona5f7d',
  exteriorPaintDataRFDoor: 'paramDefinitionfea79',
  exteriorPaintDataRFFender: 'paramDefinition53082',
  exteriorPaintDataRoof: 'paramDefinition0caed',
} as const;

export type ComparisonKeyOf = keyof typeof comparisonKeys | string;
