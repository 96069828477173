import {
  Box,
  BoxProps,
  FlexboxDirection,
  Hide,
  HStack,
  Stack,
  ThemeIconKey,
  ValueByDevice,
  VStack,
} from 'platform/foundation';

import {ReactElement} from 'react';

import {isNil} from 'ramda';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {SectionId} from '../../consts/Sections';
import {CardContainer} from '../CardContainer/CardContainer';
import {SectionStateFlagProps} from '../SectionStateFlag/SectionStateFlag';

export interface SectionProps extends TestIdProps {
  id: SectionId;
  heading: string;
  header?: ReactElement | Nullish;
  sidebar?: ReactElement | Nullish;
  content?: ReactElement | Nullish;
  icon?: ThemeIconKey;
  children?: ReactElement | Nullish;
  flag?: SectionStateFlagProps | Nullish;
  directionOverride?: FlexboxDirection | ValueByDevice<FlexboxDirection>;
  sidebarWidthOverride?: BoxProps['maxWidth'];
}

export function Section(props: SectionProps) {
  return (
    <section id={props.id} data-testid={suffixTestId('section', props)}>
      <HStack justify="center">
        <Box
          width="100%"
          maxWidth={400}
          paddingVertical={[2, 3, 3, 6]}
          paddingHorizontal={[2, 10, 10, 20]}
        >
          <CardContainer
            heading={props.heading}
            flag={props.flag}
            icon={props.icon}
            data-testid={suffixTestId('section', props)}
          >
            <VStack spacing={[3, 6, 10, 10]}>
              {props.header}
              <Stack
                direction={props.directionOverride || ['column', 'row', 'row', 'row']}
                spacing={[3, 6, 10, 10]}
                align="flex-start"
              >
                <Hide when={isNil(props.sidebar)}>
                  <Box
                    flex={1}
                    maxWidth={props.sidebarWidthOverride || ['100%', 100, 100, 100]}
                    width="100%"
                  >
                    {props.sidebar}
                  </Box>
                </Hide>
                <Hide when={isNil(props.content)}>
                  <Box flex={1} width="100%">
                    {props.content}
                  </Box>
                </Hide>
              </Stack>
              {props.children}
            </VStack>
          </CardContainer>
        </Box>
      </HStack>
    </section>
  );
}
