import {Center, Icon} from 'platform/foundation';
import {css} from 'styled-components';

import {PropsWithChildren} from 'react';

import {isNotNil} from 'ramda';

export interface PreviewProps extends PropsWithChildren {
  onClick?: VoidFunction;
}

export function Preview(props: PreviewProps) {
  return (
    <div
      css={css`
        cursor: ${isNotNil(props.onClick) ? 'pointer' : 'inherit'};
        width: 100%;
        height: 100%;
        border-radius: ${({theme}) => theme.radii.small};
        overflow: hidden;
        position: relative;
        aspect-ratio: 4/3;
      `}
      onClick={props.onClick}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
          transition: opacity 150ms ease-in-out;
          z-index: ${({theme}) => theme.zIndices.PREVIEW};
          background: ${({theme}) => theme.colors.fill.overlayMedium};
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        `}
      >
        <Center height="100%" width="100%">
          <Icon value="inspect" size={10} color="fill.white" />
        </Center>
      </div>

      {props.children}
    </div>
  );
}
